import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';
import axios from "../../axios"

const initialState = {
  data: [],
  dataDetail: [],
  vendorProductId: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: '',
};

export const store = createAsyncThunk(
  'store/store',
  async (data, thunkAPI) => {
    console.log(data, "category in reducx")
    try {
      const response = await axios.get('/api/v1/getAisle');
      console.log(response, "category in reducx2")
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
// export const storeDetail = createAsyncThunk(
//   'agency/agencyDetail',
//   async (id, data, thunkAPI) => {
//     try {
//       const response = await axios.get(API_URL + `users/company/${id}`, data);
//       return response.data;
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();

//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );


export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      // store
      .addCase(store.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(store.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload;
        state.vendorProductId = action.payload
      })
      .addCase(store.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.data = [];
      })


  },
});

export const { reset } = storeSlice.actions;
export default storeSlice.reducer;
