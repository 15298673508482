import { TrySharp } from "@mui/icons-material";
const grabLocation = (userLoc, vendors) => {
  // console.log(userLoc, "userLoc")
  // console.log(vendors, "vendors")
  try {
    let userLocation = [
      parseFloat(userLoc?.lat?.toFixed(4)),
      parseFloat(userLoc?.lng?.toFixed(4)),
    ];
    userLocation = [userLoc.lat, userLoc.lng];
    var getLocation;
    getLocation = grabUserLoc(userLocation[0], userLocation[1], vendors);
    // console.log(getLocation, 'getLocation');
    return getLocation;
  } catch {
    return null;
  }
};
export default grabLocation;
//asyncLocations.ts
function grabUserLoc(lat, lon, getLocation) {
  console.log(getLocation.length, "hel");
  var tempLocation = [];

  for (let i = 0; i < getLocation.length; i++) {
    console.log(getLocation[i], "hellllllllo");
    if (
      getLocation[i].address["lat"] != "" &&
      getLocation[i].address["lng"] != ""
    ) {
      let R = 6371; // km
      let RinM = R * 0.621371;
      let Lat1 = parseFloat(lat.toFixed(5));
      let Lon1 = parseFloat(lon.toFixed(5));
      let Lat2 = parseFloat(getLocation[i].address["lat"]);
      let Lon2 = parseFloat(getLocation[i].address["lng"]);
      let dLat = toRad(Lat2 - Lat1);
      let dLon = toRad(Lon2 - Lon1);
      let RLat1 = toRad(Lat1);
      let RLat2 = toRad(Lat2);
      let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(RLat1) *
          Math.cos(RLat2);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      //let d = R * c;
      let e = RinM * c;
      if (e < 1) {
        console.log(e, "Locationnnnnn");

        getLocation[i].distance = e;
        tempLocation.push(getLocation[i]);
        console.log(tempLocation, "helellll");
      }
    }
    console.log("hellllllllo");
  }
  getLocation = tempLocation.sort((a, b) => {
    return a.distance - b.distance;
  });
  console.log(getLocation, "tempLoaction");
  return getLocation;
}

// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}
