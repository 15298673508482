import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

export default function OrderTableComponent({ buyer }) {
  console.log(buyer, "buyerbuyer");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const navigate = useNavigate();
  const navigateToDetail = (row) => {
    console.log(row, "rowrowrow");
    navigate("/UserOrderDetails", {
      state: {
        row: row,
      },
    });
  };
  function commafy(num) {
    var str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  }
  return (
    <div style={{ width: "100%" }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{"Número de Orden"}</TableCell>
              <TableCell>{"Fecha"}</TableCell>
              <TableCell>{"Logo Tienda"}</TableCell>
              <TableCell>{"Nombre de la Tienda"}</TableCell>
              <TableCell>{"Estado de la Orden"}</TableCell>
              <TableCell>{"Precio Total"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {buyer?.buyerOrderDetails
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => {
                console.log(row, "datadaytaytas");
                return (
                  <TableRow
                    onClick={() => {
                      navigateToDetail(row);
                    }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row._id}
                  >
                    <TableCell>{row._id}</TableCell>
                    <TableCell>
                      <div>{new Date(row.purchaseDate).toLocaleString()}</div>
                    </TableCell>
                    <TableCell>
                      <img
                        style={{ width: 70, height: 70, borderRadius: "50%" }}
                        src={row.vendorStoreImg}
                      />
                    </TableCell>
                    <TableCell>{row.storeName}</TableCell>
                    {row.status === "Confirmando Pedido" && (
                      <TableCell>
                        <div
                          style={{
                            width: 90,
                            height: 28,
                            borderRadius: 20,
                            backgroundColor: "#CED7DF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 11,
                            padding: 20,
                            textAlign: "center",
                          }}
                        >
                          {row?.status}
                        </div>
                      </TableCell>
                    )}
                    {row.status === "Pedido Confirmado" && (
                      <TableCell>
                        <div
                          style={{
                            width: 90,
                            height: 28,
                            borderRadius: 20,
                            backgroundColor: "#E0F7E9",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 11,
                            padding: 20,
                            textAlign: "center",
                          }}
                        >
                          {row?.status}
                        </div>
                      </TableCell>
                    )}
                    {row.status === "Procesando Pedido" && (
                      <TableCell>
                        <div
                          style={{
                            width: 90,
                            height: 28,
                            borderRadius: 20,
                            backgroundColor: "#E0F7E9",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 11,
                            color: "#65D068",
                            padding: 20,
                            textAlign: "center",
                          }}
                        >
                          {row?.status}
                        </div>
                      </TableCell>
                    )}
                    {row.status === "Pedido Entregado" && (
                      <TableCell>
                        <div
                          style={{
                            width: 90,
                            height: 28,
                            borderRadius: 20,
                            backgroundColor: "#E0F7E9",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 11,
                            color: "#65D068",
                            padding: 8,
                            textAlign: "center",
                          }}
                        >
                          {row?.status}
                        </div>
                      </TableCell>
                    )}
                    {row.status === "Cancelada" && (
                      <TableCell>
                        <div
                          style={{
                            width: 90,
                            height: 28,
                            borderRadius: 20,
                            backgroundColor: "#F9E1E6",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 11,
                            color: "#E74460",
                            padding: 8,
                            textAlign: "center",
                          }}
                        >
                          {row?.status}
                        </div>
                      </TableCell>
                    )}
                    <TableCell>₡{commafy(row.totalPrice)}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={buyer?.buyerOrderDetails?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}
