import { React, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useStateValue } from "../ContextApi/StateProvider";
import RemoveIcon from "@mui/icons-material/Remove";
import Colors from "./Colors";
export default function ModalCounter({
  currentItem,
  storeId,
  counterStyle,
  handleClose,
}) {
  const [counter, setCounter] = useState(1);
  const [{ cart }, dispatch] = useStateValue();
  const existItem = cart.find((x) => x.id === currentItem._id?.concat(storeId));
  const [qty, setQty] = useState(existItem?.qty ? existItem?.qty : 1);
  console.log(qty, "qtyqty");

  const addToCart = () => {
    if (qty <= currentItem.stock && qty > 0) {
      dispatch({
        type: "ADD_TO_CART_AGAIN",
        item: {
          _id: currentItem._id,
          pId: currentItem.pId,
          id: currentItem._id.concat(storeId),
          name: currentItem.name,
          images: currentItem.images,
          date: currentItem.date,
          price: currentItem.price * Number(qty),
          stock: currentItem.stock,
          size: currentItem.size,
          description: currentItem.description,
          basePrice: currentItem.price,
          qty: Number(qty),
          vendorId: storeId,
        },
      });
    }
  };

  const removeFromBasket = () => {
    //remove
    console.log("remove");
    // handleClose();
    dispatch({
      type: "REMOVE_FROM_CART",
      id: currentItem._id.concat(storeId),
    });
  };
  const IncreaseQty = (id) => {
    if (qty < currentItem.stock) {
      setQty(qty + 1);
    }
  };
  const DecreaseQty = (id) => {
    console.log(id, "myDecreasId");

    if (existItem ? qty >= 1 : qty >= 2) {
      setQty(qty - 1);
    }
  };

  const handleChange = (id) => {
    if (counter >= 2) {
      setCounter((count) => count - 1);
    } else {
      removeFromBasket(id);
    }
  };

  return (
    <>
      <div style={counterStyle}>
        <div style={{ color: "black" }} onClick={() => DecreaseQty()}>
          <RemoveIcon />
        </div>
        <span style={{ color: "black" }}>{qty}</span>
        <div style={{ color: "black" }} onClick={() => IncreaseQty()}>
          <AddIcon />
        </div>
      </div>
      {qty == existItem?.qty && (
        <div
          onClick={addToCart}
          style={{
            display: "flex",
            backgroundColor: "#F6F7F8",
            borderRadius: 10,
            padding: 8,
            color: "#C7C8CD",
            width: "49%",
            justifyContent: "center",
          }}
        >
          agregado
        </div>
      )}
      {qty !== existItem?.qty && qty > 0 && existItem && (
        <div
          onClick={addToCart}
          style={{
            display: "flex",
            backgroundColor: "#499B0F",
            borderRadius: 10,
            padding: 8,
            color: "white",
            width: "49%",
            justifyContent: "center",
            fontWeight: "600",
            fontSize: 14,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Actualizar Cantidad
        </div>
      )}
      {!existItem && qty !== 0 && (
        <div
          onClick={addToCart}
          style={{
            display: "flex",
            backgroundColor: "#499B0F",
            borderRadius: 10,
            padding: 8,
            color: "white",
            width: "49%",
            justifyContent: "center",
            fontWeight: "700",
            fontSize: 14,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Agregar al carrito
        </div>
      )}
      {qty == 0 && (
        <div
          onClick={removeFromBasket}
          style={{
            display: "flex",
            backgroundColor: "#499B0F",
            borderRadius: 10,
            padding: 8,
            color: "white",
            width: "49%",
            fontSize: 14,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Quitar del carrito
        </div>
      )}
    </>
  );
}
