import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import paginatedProductsSlice from "../slices/paginatedProductsSlice";
import storeSlice from "../slices/storeSlice";
import storeProductSlice from "../slices/VendorProducts";

export const store = configureStore({
  reducer: {
    store: storeSlice,
    storeProduct: storeProductSlice,
    paginatedProducts: paginatedProductsSlice

  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});