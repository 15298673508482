import { Grid } from "@material-ui/core";
import React from "react";

function TermNavbar() {
  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "#4C9F6F",
            height: 60,
            position: "fixed",
            top: 0,
          }}
        >
          <div>
            <img
              style={{ width: 220, height: 100, margin: -20 }}
              src={require("../assets/logo1.png")}
            />
          </div>
        </div>
      </Grid>
    </div>
  );
}

export default TermNavbar;
