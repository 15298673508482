import * as React from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Divider } from "@mui/material";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
import Colors from "../../../components/Colors";
import axios from "../../../axios";
import DatePickerComponent from "../../../components/DatePickerComponent";
import { auth } from "../../../firebase";
import PhoneInput from "react-phone-input-2";
import toast, { Toaster } from "react-hot-toast";

import "react-phone-input-2/lib/style.css";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  sendEmailVerification,
  getAuth,
} from "firebase/auth";
import { useState } from "react";
import { useContext } from "react";
import AuthContext from "../../../components/context";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Alert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import MuiPhoneNumber from "mui-phone-number";
const loginSchema = Yup.object().shape({
  name: Yup.string().required("Tu nombre completo es requerido"),
  email: Yup.string().required("Correo Electrónico es requerido"),

  password: Yup.string()
    .required("Contraseña es requerido")
    .min(6, "Al menos 6 caracteres"),
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
};
export default function SignUp() {
  const navigation = useNavigate();
  const [OTP, setOTP] = React.useState(false);
  const [dateValue, setDateValue] = React.useState(null);
  const [expandForm, setExpandForm] = React.useState(false);
  const authContext = useContext(AuthContext);
  const [user, setUser] = React.useState({});
  const [registerUser, setRegisterUser] = useState({});
  const [registerError, setRegisterError] = React.useState("");
  const [firebaseError, setFirebaseError] = React.useState("");
  const [emailModal, setEmailModal] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [otpConfirm, setOTPConfirm] = useState(false);
  const [phoneValue, setPhoneValue] = useState();

  console.log(firebaseError, "firebaseError");
  console.log(registerError, "loginError");
  console.log(registerUser, "signupscreen");
  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });
  const notify = (e) => toast(e);

  // console.log(auth, "auth");
  const userAuth = getAuth();
  const register = async (values, signValues) => {
    await createUserWithEmailAndPassword(
      auth,
      values.email,
      values.password
    ).then(() => {
      sendEmailVerification(userAuth.currentUser)
        .then(() => {
          authContext.setUser(JSON.stringify(signValues, null, 4));
          localStorage.setItem("user", JSON.stringify(signValues, null, 4));
          navigation(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
      },
      auth
    );
  };
  const handleSubmit = async (values) => {
    console.log(phoneValue);
    let phn = `+${phoneValue}`;
    console.log(phn);
    axios
      .post("/api/v1/user/userPhoneChecking", {
        phoneNumber: phoneValue,
        email: values.email,
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.success) {
          if (!otpConfirm) {
            if (phoneValue >= 12) {
              setExpandForm(true);
              generateRecaptcha();
              let appVerifier = window.recaptchaVerifier;
              signInWithPhoneNumber(auth, phn, appVerifier)
                .then((res) => {
                  window.confirmationResult = res;
                })
                .catch((err) => {
                  console.log(err, "firebaseError");
                });
            }
          }
          if (otpConfirm) {
            axios
              .post("/api/v1/user/new", {
                email: values.email,
                userName: values.name,
                password: values.password,
                phoneNumber: phoneValue,
              })
              .then(async (res) => {
                register(values, res.data.user);
              })
              .catch((err) => {
                setFirebaseError("");
                setRegisterError("");
                setRegisterError(err.response.data.message);
              });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setRegisterError("");
        setFirebaseError("");
        setRegisterError(err.response.data.message);
      });
  };

  const verifyOTP = (e) => {
    let otp = e.target.value;
    console.log(otp);
    setOTP(otp);
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((res) => {
          console.log(res);
          setOTPConfirm(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Container style={{ width: "100%" }}>
      <Toaster />
      <Box marginTop={8} sx={{ boxShadow: 3 }} style={Styles.Box}>
        <Typography component="h2" variant="h1" style={Styles.SignUpText}>
          Regístrate
        </Typography>
        <Formik
          initialValues={{
            name: "",
            phone: "",
            password: "",
            email: "",
          }}
          validationSchema={loginSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ handleChange, values }) => (
            <Form>
              <FormField>
                <div
                  style={{
                    display: "flex",
                    height: 50,
                    marginBottom: 25,
                  }}
                >
                  <Field
                    component={TextField}
                    type="text"
                    id="name"
                    name="name"
                    values={values.name}
                    disabled={false}
                    // defaultValue="values"
                    onChange={handleChange}
                    style={{
                      borderWidth: 1,
                      color: "green",
                      borderColor: "black",
                      width: "90%",
                      marginBottom: 20,
                    }}
                    label="Tu nombre completo"
                    // variant="filled"
                  />
                  <DatePickerComponent
                    value={dateValue}
                    setValue={setDateValue}
                  />
                </div>
              </FormField>

              <PhoneInput
                containerStyle={{ marginBottom: 20 }}
                placeholder="Número de Teléfono "
                value={phoneValue}
                onChange={setPhoneValue}
                inputStyle={{
                  width: "96%",
                  padding: 25,
                  marginLeft: 20,
                }}
                buttonStyle={{}}
                country={"cr"}
              />

              <Field
                component={TextField}
                type="email"
                id="email"
                name="email"
                disabled={false}
                // defaultValue="email"
                values={values.email}
                onChange={handleChange}
                style={{
                  color: "green",
                  borderColor: "black",
                  width: "100%",
                  marginBottom: 20,
                }}
                label="Correo Electrónico"
                // variant="filled"
              />
              <Field
                component={TextField}
                type="password"
                id="password"
                name="password"
                disabled={false}
                // defaultValue="password"
                values={values.password}
                onChange={handleChange}
                style={{
                  color: "green",
                  borderColor: "black",
                  width: "100%",
                  marginBottom: 10,
                }}
                label="Contraseña"
                // variant="filled"
              />
              {expandForm === true && (
                <div style={{ height: "100%" }}>
                  <input
                    value={OTP}
                    placeholder={"Ingresar OTP"}
                    style={{
                      color: "black",
                      width: "100%",
                      height: "200%",
                      border: `1px solid ${Colors.skyBlue}`,
                      borderRadius: 10,
                      padding: 15,
                    }}
                    min={1}
                    type="number"
                    onChange={verifyOTP}
                  />
                </div>
              )}
              {OTP < 0 && (
                <div style={{ color: "red" }}>
                  OTP debe ser un número positivo
                </div>
              )}
              <div id="sign-in-button"></div>
              {registerError && (
                <div style={{ color: "red" }}>{registerError}</div>
              )}
              {firebaseError && (
                <div style={{ color: "red" }}>
                  {" "}
                  {"la contraseña o el correo electrónico son incorrectos"}{" "}
                </div>
              )}
              <Button
                disabled={OTP < 0 ? true : false}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                style={{
                  backgroundColor: OTP < 0 ? "lightgray" : "#499b0f",
                  textTransform: "none",
                  color: "white",
                }}
              >
                {expandForm ? "Confirmar" : "Registrarme"}
              </Button>
              <Grid container>
                <Grid item>
                  <a
                    href={"http://localhost:3001/Terms"}
                    target="_blank"
                    style={{ color: "blue", cursor: "pointer" }}
                    // onClick={() => navigation("/Terms")}
                  >
                    <u>Términos y condiciones</u>
                  </a>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Divider orientation="horizontal" flexItem style={Styles.Divider}>
          <Typography style={Styles.OrStyling}>ó</Typography>
        </Divider>
        <Typography component="h1" variant="h5">
          ¿Ya tienes una cuenta?
        </Typography>
        <Button
          onClick={() => navigation("/Login")}
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 1 }}
          style={Styles.SignInButton}
        >
          Ingresar
        </Button>
      </Box>
    </Container>
  );
}

const Styles = {
  SignInButton: {
    backgroundColor: "#499b0f",
    borderRadius: 7,
    width: "50%",
    fontSize: 13,
    paddingTop: 8,
    paddingBottom: 8,
    textTransform: "none",
    color: "white",
  },
  OrStyling: {
    backgroundColor: "#4C9F6F",
    borderRadius: 20,
    color: Colors.white,
    padding: 5,
    textAlign: "center",
  },
  Divider: {
    width: "50%",
    justifyContent: "center",
    alignSelf: "center",
  },
  Box: {
    padding: 30,
    borderRadius: 20,
    backgroundColor: Colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  SignUpButton: {
    backgroundColor: "#499b0f",
    textTransform: "none",
    color: "white",
  },
  SignUpText: {
    fontSize: 20,
    width: "100%",
    padding: 10,
  },
};
const FormField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
