import React, { useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import Colors from "./Colors";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useNavigate } from "react-router-dom";
import ProductDetailModal from "./ProductDetailModal";

const BrandStoreComponent = ({ searchData }) => {
  const navigation = useNavigate();
  const [modalItem, setModalItem] = useState();
  console.log(modalItem, "modalItem");
  const [open, setOpen] = useState(false);
  console.log(searchData);
  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 200;
  };

  return (
    <div style={{ paddingTop: 30 }}>
      <div>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "80%",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <Link
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              to={`/tiendas/${searchData.store.storeName}`}
            >
              <div>
                <img
                  style={{ width: 50, height: 50, borderRadius: "50%" }}
                  src={searchData.store?.image}
                />
              </div>
              <div>
                <div
                  style={{
                    fontSize: 15,
                    fontWeight: "600",
                    color: "black",
                    marginLeft: 10,
                  }}
                >
                  {searchData.store.storeName}
                </div>
              </div>
            </Link>
            <div
              style={{
                fontSize: 15,
                fontWeight: "600",
                color: "rgb(10, 173, 10)",
              }}
            ></div>
          </div>
        </div>
        <div style={Styles.mainContainer}>
          <div
            style={Styles.childContainer}
            className="relative flex items-center"
          >
            <MdChevronLeft
              className="opacity-50 cursor-pointer hover:opacity-100"
              onClick={slideLeft}
              size={40}
            />
            <div>
              {setModalItem && (
                <ProductDetailModal
                  // key={currentItem._id}
                  storeId={searchData?.store?._id}
                  currentItem={modalItem}
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                />
              )}
            </div>
            <div
              className="scrollbar-hide"
              id="slider"
              style={Styles.scrollStyles}
            >
              {searchData.products?.map((item) => {
                return (
                  <div
                    style={Styles.scrollChild}
                    onClick={() => {
                      setModalItem(item);
                      setOpen(true);
                    }}
                  >
                    <div style={Styles.imgContainer}>
                      <img style={Styles.image} src={item.images} />
                    </div>
                    <div style={Styles.titleContainer}>
                      <div style={Styles.title}>{item.name}</div>
                      {/* <div style={Styles.description}>{item.price}</div> */}
                      {/* <div style={Styles.date}>{item.date}</div> */}
                    </div>
                  </div>
                );
              })}
            </div>

            <MdChevronRight
              className="opacity-50 cursor-pointer hover:opacity-100"
              onClick={slideRight}
              size={40}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BrandStoreComponent);
const Styles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
  },
  childContainer: {
    width: "90%",
    display: "flex",
  },
  horizantalContainer: { display: "flex", flexDirection: "column" },

  titleContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    lineHeight: "1.5em",
  },
  dateContainer: {
    fontSize: 14,
    fontWeight: "600",
    color: Colors.lightGreen,
  },
  scrollStyles: {
    width: "100%",
    display: "flex",
    overflowX: "scroll",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
    height: 250,
  },
  scrollChild: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    height: 250,
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 210,
    height: 150,
  },
  title: {
    fontSize: 12,
    fontWeight: "normal",
    color: "rgb(52, 53, 56)",
    width: 150,
    whiteSpace: "pre-line",
    marginLeft: 10,
    marginRight: 10,
  },
  description: {
    fontSize: 12,
    fontWeight: "700",
    color: Colors.lightGreen,
  },
  date: { fontSize: 12, fontWeight: "600", color: "green" },
};
