import React, { useContext, useEffect } from "react";
import { useTheme } from "@mui/material/styles";

// import BrandStoreComponent from "../../components/BrandStoreComponent";
import NavbarComponent from "../../components/NavbarComponent";

import CategoriesComponent from "../../components/CategoriesComponent";
// import { useLocation } from "react-router-dom";
import locContext from "../../components/Locationcontext";
import { Box } from "@material-ui/core";

const HomeScreen = () => {
  // const [w, setW] = React.useState(window.innerWidth);
  // useEffect(() => {
  //   const handleResize = () => {
  //     setW(window.innerWidth);
  //   };
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.addEventListener("resize", handleResize);
  //     window.orientation(90);
  //   };
  // }, []);

  // const location = useLocation();
  // const data = location;
  const userLocation = useContext(locContext);
  console.log(userLocation.loc, "registerUserregisterUser");
  function success(pos) {
    var crd = pos.coords;
    // console.log(loc)
    let l = localStorage.getItem("location");
    console.log(l, "l hy ye ");
    if (!l) {
      console.log("Your current position is:");
      console.log(`Latitude : ${crd.latitude}`);
      console.log(`Longitude: ${crd.longitude}`);
      console.log(`More or less ${crd.accuracy} meters.`);
      let data = { lng: crd.longitude, lat: crd.latitude };

      localStorage.setItem("location", JSON.stringify(data));
      userLocation.setLoc(JSON.stringify(data, null, 4));
    }
    // window.location.reload()
  }
  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const getLocation = () => {
    // navigator.geolocation.getCurrentPosition((loc) => {

    // })


    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "granted") {
          console.log(result.state);
          //If granted then you can directly call your function here
          navigator.geolocation.getCurrentPosition(success);
        } else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === "denied") {
          //If denied then you have to show instructions to enable location
        }
        result.onchange = function () {
          console.log(result.state);
        };
      }).catch(err => {
        console.log(err)
      })

  };
  useEffect(async () => {

    navigator.permissions
      .query({ name: "geolocation" })
      .then(result => {
        console.log(result, "permission")
        if (result.state === "granted") {
          getLocation();

        }
      })
  }, []);
  return (
    <div >
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <NavbarComponent />
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <NavbarComponent onMobileUi />
      </Box>
      <CategoriesComponent />
    </div>
  );
};

export default HomeScreen;
