import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextField from "@mui/material/TextField";
import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from "@mui/icons-material/Schedule";

import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import "react-phone-number-input/style.css";
import { Checkbox } from "@mui/material";
import { CreditCard } from "@material-ui/icons";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./User.css";

import axios from "../axios";
import { getCartTotal } from "../ContextApi/reducer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateValue } from "../ContextApi/StateProvider";
import { useLocation } from "react-router-dom";
import UserDeliveryTab from "./UserDeliveryTab";
import UserPaymentTab from "./UserPaymentTab";
import toast, { Toaster } from "react-hot-toast";

import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import Colors from "../components/Colors";
import AuthContext from "../components/context";
import { useContext } from "react";

const orderConfirmStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  backgroundColor: "white",
};

function UserCheckOut() {
  let formData = new FormData();

  const navigation = useNavigate();
  const location = useLocation();
  // console.log(location, "locationlocation");

  const notify = (e) => toast(e);

  const vendorId = location?.state?.storeData?._id;
  const vendorData = location?.state?.storeData;
  const storeName = location?.state?.storeData?.storeName;
  const cellPhone = location?.state?.storeData?.cellPhone;
  const image = location?.state?.storeData?.image;
  const hiddenFileInput = React.useRef(null);
  // console.log(vendorData, "vendorId");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [form, setForm] = useState();
  const [formStepper, setFormStepper] = useState(0);
  const [{ cart }, dispatch] = useStateValue();
  const TotalValue = getCartTotal(cart);
  const totalCartPrice = parseFloat(TotalValue.toFixed(2));

  const [address, setAddress] = useState();
  console.log(address, "addressaddressaddress");

  const auth = useContext(AuthContext);
  const [uploadImageDisable, setUploadImageDisable] = useState(false);
  // console.log(auth.user, "buyerId");

  const initialValues = {
    address: "",
    addressOptional: "",
    zipCode: "",
    businessName: "",
    instruction: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [storeImage, setStoreImage] = useState(null);
  const [img, setImg] = useState(null);
  // console.log(cart.name, "reChecking again");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const increaseStepper = () => {
    setFormErrors(validate(formValues));
    // console.log(formValues);

    setIsSubmit(true);
  };
  const saveUserAddress = async () => {
    setFormErrors(validate(formValues));
    console.log(formValues, "save user");
    console.log("working");

    axios
      .post(`/api/v1/user/saveUserAddress/${auth?.user?._id}`, {
        address: formValues,
      })
      .then((res) => {
        // console.log(res.data, "save user");
      });

    setIsSubmit(true);
  };

  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      formData.append("image", event.target.files[0]);
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        setImg(event.target.files[0]);
        setStoreImage(e.target.result);
        setUploadImageDisable(true);
        notify("Image uploaded successfully");
      };
    }
  };
  useEffect(() => {
    // console.log(formErrors, "formErrors");
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // console.log(formValues, "formValues");
      setFormStepper(formStepper + 1);
    }
  }, [formErrors]);

  useEffect(() => {
    async function getAddress() {
      axios.get(`/api/v1/user/${auth?.user?._id}`).then((res) => {
        setAddress(res.data);
        // console.log(res.data.userId);
        if (res.data.userId.hasOwnProperty("address")) {
          let a = res.data.userId?.address;
          // console.log(a, "addressPrevious");
          setFormValues({
            address: a?.address,
            zipCode: a?.zipCode,
            businessName: a?.businessName,
            addressOptional: a?.addressOptional,
          });
        }
      });
    }
    if (auth?.user) {
      getAddress();
    }
  }, [auth]);

  const validate = (values) => {
    // console.log(values, "checking");
    const errors = {};
    if (!values.address) {
      errors.address = "address is required";
    }
    if (!values.zipCode) {
      errors.zipCode = "zipCode is required";
    }
    return errors;
  };

  // console.log(form, "values");
  const completeFormStep = () => {
    setFormStepper(formStepper + 1);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const [activeTabData, setActiveTabData] = useState({});
  const [activeTab, setActiveTabs] = useState(activeTabData);
  // console.log(activeTab, "activeTab");
  // console.log(activeTabData, "activeTabData");
  const [activePaymentData, setActivePaymentData] = useState({});
  const [activePayment, setActivePayment] = useState(activePaymentData);
  // console.log(activePayment, "activePayment");
  // console.log(activePaymentData.text, "activePaymentData");
  const [getFee, setGetFee] = useState();
  // console.log(getFee, "getFee");

  useEffect(() => {
    let id = "63592dc1a86d529610ff4b67";
    axios.get(`/api/v1/admin/serviceFee/${id}`).then((res) => {
      console.log(res.data);
      setGetFee(res.data.getFee.serviceFee);
    });
  }, []);
  const deliveryFee = Number(activeTabData?.price);
  const serviceFee = Number(getFee ? getFee : 0);
  const SumTotal = totalCartPrice + deliveryFee + serviceFee;
  const finalValue = parseFloat(SumTotal.toFixed(1));
  // console.log(finalValue, "finalValue");
  const [myResponce, setMyResponce] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [order, setOrder] = React.useState();
  const [check, setCheck] = React.useState(false);
  const [deliverDisable, setDeliverDisable] = useState(true);
  const [paymentDisable, setPaymentDisable] = useState(true);
  const [paymentInstruction, setPaymentInstruction] = useState();
  const [cashOnDelivery, setCashOnDelivery] = useState(true);

  const paymentTypeData = (e) => {
    // console.log(e, "hello");
    setActivePaymentData(e);
    if (e.text === "Transferencia bancaria") {
      setCashOnDelivery(true);
      setPaymentInstruction("");
      setPaymentInstruction(
        "Envía una transferencia del monto de la compra y luego adjunta el comprobante en el campo abajo.Cuentas de Banco:Cuenta de Banco A XXXXXXXXXXXXXCuenta de Banco B XXXXXXXXXXXXXCuenta de Banco C XXXXXXXXXXXXX"
      );
    } else if (e.text === "SINPE Móvil") {
      setCashOnDelivery(true);
      setPaymentInstruction("");
      setPaymentInstruction(
        "Envía el monto de la compra al número ####-#### y luego adjunta una imagen con el comprobante de pago en el campo abajo"
      );
    } else {
      setCashOnDelivery(false);
    }
  };
  // console.log(check);
  const handleToggle = () => {
    setCheck(!check);
  };
  const [submitDisable, setSubmitDisable] = useState(false);
  const checkOutSubmit = () => {
    setSubmitDisable(!submitDisable);
    setPaymentDisable(true);

    if (img) {
      axios
        .post(
          "/api/v1/allOrdersDetail/new",
          {
            buyerId: auth.user._id,
            vendorId: vendorId, //remove
            productDetails: JSON.stringify(cart),
            totalPrice: finalValue,
            storeName: storeName,
            cellPhone: cellPhone,
            vendorStoreImg: image,
            deliveryTime: JSON.stringify({
              price: activeTabData.price,
              deliveryTime: activeTabData.deliverTime,
              type: activeTabData.activeTab,
            }),
            pop: img,
            paymentType: JSON.stringify({
              type: activePaymentData.text,
            }),
            shipBy: "null",
            deliveryAddress: JSON.stringify(formValues),
            shipTo: JSON.stringify(formValues.address),
            buyerName: auth.user.userName,
            leaveAtDoor: check,
            status: "Confirmando Pedido",
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setMyResponce(res.data.success);
          setOrder(res.data.allOrdersDetail);
          setOpen(true);
          // console.log(res.data, "myResoponce");
        })
        .catch((err) => {
          // console.log(err, "error");
        });
    } else {
      axios
        .post("/api/v1/allOrdersDetail/new", {
          buyerId: auth.user._id,
          vendorId: vendorId, //remove
          productDetails: JSON.stringify(cart),
          totalPrice: finalValue,
          storeName: storeName,
          cellPhone: cellPhone,
          vendorStoreImg: image,
          deliveryTime: JSON.stringify({
            price: activeTabData.price,
            deliveryTime: activeTabData.deliverTime,
            type: activeTabData.activeTab,
          }),
          //  pop: img,
          paymentType: JSON.stringify({
            type: activePaymentData.text,
          }),
          shipBy: "null",
          deliveryAddress: JSON.stringify(formValues),
          shipTo: JSON.stringify(formValues.address),
          buyerName: auth.user.userName,
          leaveAtDoor: check,
          status: "Confirmando Pedido",
        })
        .then((res) => {
          setMyResponce(res.data.success);
          setOrder(res.data.allOrdersDetail);
          setOpen(true);
          // console.log(res.data, "myResoponce");
        })
        .catch((err) => {
          // console.log(err, "error");
        });
    }
  };
  const handleClose = () => {
    setOpen(false);
    navigation(-1);
    dispatch({
      type: "RESET_CART",
    });
  };
  function commafy(num) {
    var str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  }
  return (
    <Grid
      container
      md={12}
      xs={12}
      style={{ backgroundColor: "#F7F7F7", minHeight: "100vh" }}
    >
      <Toaster />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={orderConfirmStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              alt="basimart"
              style={{ margin: -10, width: 200, height: 100 }}
              src={require("../assets/basi.png")}
            />
            <div
              style={{ width: "100%", height: 1, backgroundColor: "lightgray" }}
            />{" "}
            <div
              style={{
                fontSize: 16,
                fontWeight: "700",
                color: "#424242",

                paddingTop: 40,
              }}
            >
              Recibimos tu pedido de
            </div>
            <div
              style={{
                fontSize: 16,
                fontWeight: "700",
                color: "#424242",
                paddingBottom: 40,
              }}
            >
              {cart?.length > 1
                ? `${cart.length} artículo `
                : `${cart.length} artículos `}
            </div>
            {/* <div style={{ fontWeight: "600", fontSize: 14 }}>Delivery time</div>
            <div style={{ fontSize: 16, fontWeight: "700", color: "#424242" }}>
              Sep 12 3:00-4:00 pm
            </div> */}
            <div
              onClick={() => {
                dispatch({
                  type: "RESET_CART",
                });
                navigation(-1, {
                  state: {
                    row: order,
                  },
                });
              }}
              style={{
                backgroundColor: "#3E844D",
                cursor: "pointer",
                fontSize: 13,
                fontWeight: "700",
                color: "white",
                borderRadius: 5,
                padding: 8,
                paddingLeft: 12,
                paddingRight: 12,
                marginTop: 20,
              }}
            >
              {"Seguir comprando"}
              <ArrowForwardIosIcon style={{ fontSize: 12 }} />
            </div>
            <div
              onClick={() => {
                dispatch({
                  type: "RESET_CART",
                });
                navigation("/UserOrderDetails", {
                  state: {
                    row: order,
                  },
                });
              }}
              style={{
                backgroundColor: "#EFA71C",
                cursor: "pointer",
                fontSize: 14,
                fontWeight: "700",
                color: "white",
                marginBottom: 40,
                borderRadius: 5,
                padding: 8,
                paddingLeft: 12,
                paddingRight: 12,
                marginTop: 20,
              }}
            >
              {"Ver estado del pedido"}
              <ArrowForwardIosIcon style={{ fontSize: 13 }} />
            </div>
          </div>
        </Box>
      </Modal>
      <Grid
        item
        style={{
          backgroundColor: "white",
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          width: "100%",
          zIndex: 1,
        }}
        xs={12}
        md={12}
      >
        <img
          onClick={() => navigation("/")}
          style={{ width: 220, height: 100, margin: -20 }}
          src={require("../assets/basicheckout.png")}
        />
      </Grid>

      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          width: "100%",
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 80,
          }}
          container
          xs={12}
          md={12}
          lg={12}
        >
          <Grid
            md={6}
            xs={10}
            style={{ backgroundColor: "white", margin: 2 }}
            item
          >
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <div>Delivery</div>
            <div>Free by 2:25pm</div> */}
            </div>

            <div>
              <div>
                {formStepper === 0 ? (
                  <>
                    {address && (
                      <div style={{ padding: 20, borderBottomWidth: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                            alignSelf: "center",
                          }}
                        >
                          <PlaceIcon
                            style={{
                              color: "green",
                              fontSize: 30,
                              marginRight: 10,
                            }}
                          />
                          <div style={{ fontSize: 15, fontWeight: "600" }}>
                            Añadir dirección de entrega
                          </div>
                        </div>
                        <TextField
                          type="text"
                          id="address"
                          name="address"
                          value={formValues?.address}
                          disabled={false}
                          defaultValue={address?.address?.address}
                          onChange={handleChange}
                          style={{
                            width: "80%",
                            marginBottom: 10,
                            // backgroundColor: formErrors.address ? "red" : "white",
                            borderColor: formErrors.address ? "red" : "white",
                          }}
                          label="Dirección Exacta"
                        />
                        <div
                          style={{
                            color: "red",
                            fontWeight: "600",
                            fontSize: 14,
                          }}
                        >
                          {formErrors.address}
                        </div>
                        <TextField
                          type="text"
                          id="addressOptional"
                          name="addressOptional"
                          disabled={false}
                          // defaultValue="addressOptional"
                          value={formValues.addressOptional}
                          defaultValue={address?.address?.addressOptional}
                          onChange={handleChange}
                          style={{
                            borderWidth: 1,
                            width: "80%",
                            marginBottom: 10,
                          }}
                          label="Provincia"
                          // variant="filled"
                        />
                        <TextField
                          type="text"
                          id="businessName"
                          disabled={false}
                          name="businessName"
                          defaultValue={address?.address?.businessName}
                          onChange={handleChange}
                          value={formValues.businessName}
                          style={{
                            borderWidth: 1,
                            color: "green",
                            borderColor: "black",
                            width: "80%",

                            marginBottom: 10,
                          }}
                          label="Cantón"
                          // variant="filled"
                        />
                        <TextField
                          type="number"
                          onChange={handleChange}
                          disabled={false}
                          id="zipCode"
                          name="zipCode"
                          defaultValue={address?.address?.zipCode}
                          value={formValues.zipCode}
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          onKeyPress={(event) => {
                            if (event?.key === "-" || event?.key === "+") {
                              event.preventDefault();
                            }
                          }}
                          style={{
                            borderWidth: 1,
                            borderColor: "black",
                            width: "27%",
                            width: "27%",
                            marginBottom: 10,
                          }}
                          label="Código Posta"
                          // variant="filled"
                        />
                        <div
                          style={{
                            color: "red",
                            fontWeight: "600",
                            fontSize: 14,
                          }}
                        >
                          {formErrors.zipCode}
                        </div>

                        <button
                          onClick={saveUserAddress}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#52AE11",
                            width: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 10,
                            padding: 10,
                            color: "white",
                          }}
                        >
                          Continuar
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottomWidth: 1,
                      padding: 20,
                      cursor: "pointer",
                    }}
                    onClick={() => setFormStepper(0)}
                  >
                    <PlaceIcon
                      style={{
                        color: "#72767E",
                        fontSize: 23,
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        color: "#72767E",
                      }}
                    >
                      Añadir dirección de entrega
                    </div>
                  </div>
                )}
              </div>
              <div>
                {formStepper === 1 ? (
                  <>
                    <div style={{ padding: 20, borderBottomWidth: 1 }}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ScheduleIcon
                            style={{
                              color: "green",
                              fontSize: 26,
                              marginRight: 10,
                            }}
                          />
                          <div
                            style={{
                              fontSize: 15,
                              fontWeight: "600",
                            }}
                          >
                            Elige el tiempo de entrega
                          </div>
                        </div>
                        <UserDeliveryTab
                          setDeliverDisable={setDeliverDisable}
                          data={vendorData}
                          activeTab={activeTab}
                          setActiveTabs={setActiveTabs}
                          setActiveTabData={setActiveTabData}
                          MobileUi={false}
                        />
                      </div>
                      <div style={{ marginBottom: 15 }}>
                        <button
                          disabled={deliverDisable}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "82.5%",
                            backgroundColor: deliverDisable
                              ? "lightgray"
                              : "#52AE11",
                            alignItems: "center",
                            padding: 8,
                            borderRadius: 5,
                            borderWidth: 1,
                            cursor: "pointer",
                            fontWeight: "600",
                            color: deliverDisable ? "black" : "white",
                          }}
                          onClick={completeFormStep}
                        >
                          Continuar
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      borderBottomWidth: 1,
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setFormStepper(1)}
                  >
                    <ScheduleIcon
                      style={{
                        color: "#72767E",
                        fontSize: 23,
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        color: "#72767E",
                      }}
                    >
                      Tiempos de entrega
                    </div>
                  </div>
                )}
              </div>
              <div>
                {formStepper === 2 ? (
                  <div style={{ padding: 20, borderBottomWidth: 1 }}>
                    <div
                      style={{
                        display: "flex",

                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <AirportShuttleIcon
                        style={{
                          color: "green",
                          fontSize: 30,
                          marginRight: 10,
                        }}
                      />
                      <div style={{ fontSize: 15, fontWeight: "600" }}>
                        Instrucciones de entrega
                      </div>
                    </div>
                    <div>
                      <TextField
                        type="text"
                        id="instruction"
                        disabled={false}
                        name="instruction"
                        onChange={handleChange}
                        defaultValue={formValues.instruction}
                        values={formValues.instruction}
                        style={{
                          borderWidth: 1,
                          color: "green",
                          borderColor: "black",
                          width: "80%",

                          marginBottom: 10,
                        }}
                        label="Alguna indicación especial para la entrega"
                        // variant="filled"
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        {...label}
                        defaultChecked={check}
                        onClick={handleToggle}
                      />
                      <div>La entrega es en condominio o comunidad cerrada</div>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: 100,
                          backgroundColor: "#52AE11",
                          alignItems: "center",
                          padding: 8,
                          borderRadius: 10,
                          borderWidth: 1,
                          cursor: "pointer",
                          color: "white",
                        }}
                        onClick={increaseStepper}
                      >
                        Continuar
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      borderBottomWidth: 1,

                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setFormStepper(2)}
                  >
                    <AirportShuttleIcon
                      style={{
                        color: "#72767E",
                        fontSize: 23,
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        color: "#72767E",
                        fontSize: 15,
                        fontWeight: "600",
                      }}
                    >
                      Instrucciones de entrega
                    </div>
                  </div>
                )}
              </div>

              <div>
                {formStepper === 3 ? (
                  <div style={{ padding: 20, borderBottomWidth: 1 }}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CreditCard
                          style={{
                            color: "green",
                            fontSize: 26,
                            marginRight: 10,
                          }}
                        />
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                          }}
                        >
                          Selecciona el método de pago
                        </div>
                      </div>

                      <UserPaymentTab
                        data={vendorData}
                        setDeliverDisable={setDeliverDisable}
                        setPaymentDisable={setPaymentDisable}
                        activeTab={activePayment}
                        setActiveTabs={setActivePayment}
                        setActiveTabData={paymentTypeData}
                        MobileUi={false}
                      />
                    </div>

                    <div style={{ marginBottom: 15 }}>
                      <button
                        disabled={paymentDisable}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "82.5%",
                          backgroundColor: paymentDisable
                            ? "lightgray"
                            : "#52AE11",
                          alignItems: "center",
                          padding: 8,
                          borderRadius: 5,
                          borderWidth: 1,
                          cursor: "pointer",
                          color: paymentDisable ? "black" : "white",
                        }}
                        onClick={
                          cashOnDelivery ? increaseStepper : checkOutSubmit
                        }
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      borderBottomWidth: 1,

                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setFormStepper(3)}
                  >
                    <CreditCard
                      style={{
                        color: "#72767E",
                        fontSize: 23,
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        color: "#72767E",
                      }}
                    >
                      Pago
                    </div>
                  </div>
                )}
              </div>
              <div>
                {formStepper === 4 && cashOnDelivery ? (
                  <div style={{ padding: 20, borderBottomWidth: 1 }}>
                    <div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <CreditCard
                          style={{
                            color: "green",
                            fontSize: 26,
                            marginRight: 10,
                          }}
                        />
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                          }}
                        >
                          Adjunta el comprobante de pago
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          container
                          md={12}
                          lg={12}
                          xs={12}
                          sm={12}
                          xl={12}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid
                            item
                            md={12}
                            lg={12}
                            xs={12}
                            sm={12}
                            xl={12}
                            style={{}}
                          >
                            <div
                              style={{
                                width: "82.5%",
                                height: "100%",
                                border: "1px solid #ccc",
                                borderRadius: "6px",
                                padding: 15,
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "sans-serif",
                                  fontWeight: "bold",
                                }}
                              >
                                Instrucciones
                              </Typography>
                              {paymentInstruction && (
                                <p style={{ fontSize: 14 }}>
                                  {paymentInstruction}
                                </p>
                              )}
                            </div>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            lg={12}
                            xs={12}
                            sm={12}
                            xl={12}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            <Grid container md={4} lg={4} xs={4} sm={4} xl={4}>
                              {storeImage && (
                                <img
                                  src={storeImage}
                                  style={{
                                    display: "flex",
                                    width: 250,
                                    height: 100,
                                    alignSelf: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifySelf: "center",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid container md={4} lg={4} xs={4} sm={4} xl={4}>
                              <input
                                type="file"
                                ref={hiddenFileInput}
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={handleChangeImage}
                                id="contained-button-file"
                              />
                              <label
                                style={{ alignSelf: "center" }}
                                htmlFor="contained-button-file"
                              >
                                <Button
                                  style={{
                                    border: `1px solid ${Colors.buttonGreen}`,
                                    color: "black",
                                    textTransform: "none",
                                  }}
                                  onClick={handleClick}
                                >
                                  Upload Receipt
                                </Button>
                              </label>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>

                    <div style={{ marginBottom: 15 }}>
                      {!submitDisable && (
                        <button
                          onClick={checkOutSubmit}
                          disabled={submitDisable}
                          style={{
                            backgroundColor:
                              !cashOnDelivery || uploadImageDisable
                                ? "#52AE11"
                                : "#BDBDBD",
                            width: "82.5%",
                            paddingTop: 8,
                            paddingBottom: 8,
                            alignContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            color:
                              !cashOnDelivery || uploadImageDisable
                                ? "white"
                                : "black",
                          }}
                        >
                          Continuar
                        </button>
                      )}
                      {submitDisable && (
                        <button
                          onClick={checkOutSubmit}
                          disabled={submitDisable}
                          style={{
                            backgroundColor: "#BDBDBD",
                            width: "82.5%",
                            paddingTop: 8,
                            paddingBottom: 8,
                            alignContent: "center",
                            alignItems: "center",
                            borderRadius: 5,
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            color: uploadImageDisable ? "black" : "white",
                          }}
                        >
                          Continuar
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {cashOnDelivery && (
                      <div
                        style={{
                          display: "flex",
                          padding: 20,
                          borderBottomWidth: 1,

                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setFormStepper(4)}
                      >
                        <CreditCard
                          style={{
                            color: "#72767E",
                            fontSize: 23,
                            marginRight: 10,
                          }}
                        />
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                            color: "#72767E",
                          }}
                        >
                          Comprobante de pago
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Grid>
          {/* guarantee area */}
          <Grid
            md={3}
            xs={10}
            style={{ backgroundColor: "white", margin: 2 }}
            item
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: 50, height: 50 }}
                  src={require("../assets/guaranteeIcon.png")}
                />
                <div>
                  <div
                    style={{
                      fontSize: 14,
                      color: "#3C78C6",
                      fontWeight: "500",
                    }}
                  >
                    100% garantía de satisfacción
                  </div>
                  <div
                    style={{
                      width: 220,
                      fontSize: 14,
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    Haz tu pedido con tranquilidad
                    <div class="tooltip">
                      <HelpOutlineIcon
                        style={{ fontSize: 15, marginLeft: 3 }}
                      />
                      <span
                        style={{ left: -20, top: 30, position: "absolute" }}
                        class="tooltiptext"
                      >
                        Haz tu pedido con tranquilidad
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                <div
                  style={{ fontSize: 14, color: "#393939", fontWeight: "700" }}
                >
                  Subtotal del pedido{" "}
                </div>
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{commafy(totalCartPrice)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: 14,
                      color: "#393939",
                      fontWeight: "700",
                    }}
                  >
                    Cargo por servicio{" "}
                  </div>
                  <ArrowForwardIosIcon
                    style={{ fontSize: 11, marginLeft: 3 }}
                  />
                </div>
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{serviceFee}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              {deliveryFee ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        color: "#393939",
                        fontWeight: "700",
                      }}
                    >
                      Costo de envío
                    </div>
                    <ArrowForwardIosIcon
                      style={{ fontSize: 11, marginLeft: 3 }}
                    />
                  </div>
                  <div style={{ color: "#393939", fontSize: 14 }}>
                    ₡{deliveryFee}
                  </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
            <div
              style={{ width: "100%", backgroundColor: "lightgray", height: 1 }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ fontSize: 14, color: "#393939", fontWeight: "700" }}
                >
                  Total
                </div>
              </div>
              {deliveryFee ? (
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{`${commafy(finalValue)}`}
                </div>
              ) : (
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{`${commafy(totalCartPrice + serviceFee)}`}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          marginTop: 5,
          marginBottom: 15,
        }}
      >
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 80,
          }}
          container
          xs={12}
          md={12}
          lg={12}
        >
          <Grid
            md={6}
            xs={10}
            style={{ backgroundColor: "white", margin: 2 }}
            item
          >
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            ></div>

            <div>
              <div>
                {formStepper === 0 ? (
                  <>
                    {address && (
                      <div style={{ padding: 20, borderBottomWidth: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            alignContent: "center",
                            alignSelf: "center",
                          }}
                        >
                          <PlaceIcon
                            style={{
                              color: "green",
                              fontSize: 30,
                              marginRight: 10,
                            }}
                          />
                          <div style={{ fontSize: 15, fontWeight: "600" }}>
                            Añadir dirección de entrega
                          </div>
                        </div>
                        <TextField
                          type="text"
                          id="address"
                          name="address"
                          value={formValues?.address}
                          disabled={false}
                          defaultValue={address?.address?.address}
                          onChange={handleChange}
                          style={{
                            width: "100%",
                            marginBottom: 10,
                            // backgroundColor: formErrors.address ? "red" : "white",
                            borderColor: formErrors.address ? "red" : "white",
                          }}
                          label="Address line 1"
                        />
                        <div
                          style={{
                            color: "red",
                            fontWeight: "600",
                            fontSize: 14,
                          }}
                        >
                          {formErrors.address}
                        </div>
                        <TextField
                          type="text"
                          id="addressOptional"
                          name="addressOptional"
                          disabled={false}
                          // defaultValue="addressOptional"
                          value={formValues.addressOptional}
                          defaultValue={address?.address?.addressOptional}
                          onChange={handleChange}
                          style={{
                            borderWidth: 1,
                            width: "100%",
                            marginBottom: 10,
                          }}
                          label="Address line 2 (optional)"
                          // variant="filled"
                        />
                        <TextField
                          type="text"
                          id="businessName"
                          disabled={false}
                          name="businessName"
                          defaultValue={address?.address?.businessName}
                          onChange={handleChange}
                          value={formValues.businessName}
                          style={{
                            borderWidth: 1,
                            color: "green",
                            borderColor: "black",
                            width: "100%",

                            marginBottom: 10,
                          }}
                          label="Alguna indicación especial para la entrega"
                          // variant="filled"
                        />
                        <TextField
                          type="number"
                          onChange={handleChange}
                          disabled={false}
                          id="zipCode"
                          name="zipCode"
                          defaultValue={address?.address?.zipCode}
                          value={formValues.zipCode}
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                          onKeyPress={(event) => {
                            if (event?.key === "-" || event?.key === "+") {
                              event.preventDefault();
                            }
                          }}
                          style={{
                            borderWidth: 1,
                            borderColor: "black",
                            width: "27%",
                            width: "50%",

                            marginBottom: 10,
                          }}
                          label="Zip code"
                          // variant="filled"
                        />
                        <div
                          style={{
                            color: "red",
                            fontWeight: "600",
                            fontSize: 14,
                          }}
                        >
                          {formErrors.zipCode}
                        </div>

                        <button
                          onClick={saveUserAddress}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#52AE11",
                            width: 100,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 10,
                            padding: 10,
                            color: "white",
                          }}
                        >
                          Continuar
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderBottomWidth: 1,
                      padding: 20,
                      cursor: "pointer",
                    }}
                    onClick={() => setFormStepper(0)}
                  >
                    <PlaceIcon
                      style={{
                        color: "#72767E",
                        fontSize: 23,
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        color: "#72767E",
                      }}
                    >
                      Añadir dirección de entrega
                    </div>
                  </div>
                )}
              </div>
              <div>
                {formStepper === 1 ? (
                  <>
                    <div style={{ padding: 20, borderBottomWidth: 1 }}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <ScheduleIcon
                            style={{
                              color: "green",
                              fontSize: 26,
                              marginRight: 10,
                            }}
                          />
                          <div
                            style={{
                              fontSize: 15,
                              fontWeight: "600",
                            }}
                          >
                            Elige el tiempo de entrega
                          </div>
                        </div>
                        <UserDeliveryTab
                          setDeliverDisable={setDeliverDisable}
                          data={vendorData}
                          activeTab={activeTab}
                          setActiveTabs={setActiveTabs}
                          setActiveTabData={setActiveTabData}
                          MobileUi={true}
                        />
                      </div>
                      <div style={{ marginBottom: 15 }}>
                        <button
                          disabled={deliverDisable}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            backgroundColor: deliverDisable
                              ? "lightgray"
                              : "#52AE11",
                            alignItems: "center",
                            padding: 8,
                            borderRadius: 5,
                            borderWidth: 1,
                            cursor: "pointer",
                            fontWeight: "600",
                            color: deliverDisable ? "black" : "white",
                          }}
                          onClick={completeFormStep}
                        >
                          Continuar
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      borderBottomWidth: 1,
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setFormStepper(1)}
                  >
                    <ScheduleIcon
                      style={{
                        color: "#72767E",
                        fontSize: 23,
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        color: "#72767E",
                      }}
                    >
                      Delivery time
                    </div>
                  </div>
                )}
              </div>
              <div>
                {formStepper === 2 ? (
                  <div style={{ padding: 20, borderBottomWidth: 1 }}>
                    <div
                      style={{
                        display: "flex",

                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <AirportShuttleIcon
                        style={{
                          color: "green",
                          fontSize: 30,
                          marginRight: 10,
                        }}
                      />
                      <div style={{ fontSize: 15, fontWeight: "600" }}>
                        Instrucciones de entrega
                      </div>
                    </div>
                    <div>
                      <TextField
                        type="text"
                        id="instruction"
                        disabled={false}
                        name="instruction"
                        onChange={handleChange}
                        defaultValue={formValues.instruction}
                        values={formValues.instruction}
                        style={{
                          borderWidth: 1,
                          color: "green",
                          borderColor: "black",
                          width: "100%",

                          marginBottom: 10,
                        }}
                        label="Alguna indicación especial para la entrega"
                        // variant="filled"
                      />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        {...label}
                        defaultChecked={check}
                        onClick={handleToggle}
                      />
                      <div>La entrega es en condominio o comunidad cerrada</div>
                    </div>
                    <div style={{ marginBottom: 15 }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: 100,
                          backgroundColor: "#52AE11",
                          alignItems: "center",
                          padding: 8,
                          borderRadius: 10,
                          borderWidth: 1,
                          cursor: "pointer",
                          color: "white",
                        }}
                        onClick={increaseStepper}
                      >
                        Continuar
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      borderBottomWidth: 1,

                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setFormStepper(2)}
                  >
                    <AirportShuttleIcon
                      style={{
                        color: "#72767E",
                        fontSize: 23,
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        color: "#72767E",
                        fontSize: 15,
                        fontWeight: "600",
                      }}
                    >
                      Instrucciones de entrega
                    </div>
                  </div>
                )}
              </div>

              <div>
                {formStepper === 3 ? (
                  <div style={{ padding: 20, borderBottomWidth: 1 }}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CreditCard
                          style={{
                            color: "green",
                            fontSize: 26,
                            marginRight: 10,
                          }}
                        />
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                          }}
                        >
                          Selecciona el método de pago
                        </div>
                      </div>

                      <UserPaymentTab
                        data={vendorData}
                        setDeliverDisable={setDeliverDisable}
                        setPaymentDisable={setPaymentDisable}
                        activeTab={activePayment}
                        setActiveTabs={setActivePayment}
                        setActiveTabData={paymentTypeData}
                        MobileUi={true}
                      />
                    </div>
                    {cashOnDelivery && (
                      <div style={{ marginBottom: 15 }}>
                        <button
                          disabled={paymentDisable}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            backgroundColor: paymentDisable
                              ? "lightgray"
                              : "#52AE11",
                            alignItems: "center",
                            padding: 8,
                            borderRadius: 5,
                            borderWidth: 1,
                            cursor: "pointer",
                            color: paymentDisable ? "black" : "white",
                          }}
                          onClick={
                            cashOnDelivery ? increaseStepper : checkOutSubmit
                          }
                        >
                          Continuar
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      borderBottomWidth: 1,

                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setFormStepper(3)}
                  >
                    <CreditCard
                      style={{
                        color: "#72767E",
                        fontSize: 23,
                        marginRight: 10,
                      }}
                    />
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "600",
                        color: "#72767E",
                      }}
                    >
                      Payment
                    </div>
                  </div>
                )}
              </div>
              <div>
                {formStepper === 4 && cashOnDelivery ? (
                  <div style={{ padding: 20, borderBottomWidth: 1 }}>
                    <div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <CreditCard
                          style={{
                            color: "green",
                            fontSize: 26,
                            marginRight: 10,
                          }}
                        />
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                          }}
                        >
                          Adjunta el comprobante de pago
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Grid
                          container
                          md={12}
                          lg={12}
                          xs={12}
                          sm={12}
                          xl={12}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid
                            item
                            md={12}
                            lg={12}
                            xs={12}
                            sm={12}
                            xl={12}
                            style={{}}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                border: "1px solid #ccc",
                                borderRadius: "6px",
                                padding: 15,
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "sans-serif",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                Inscructions
                              </Typography>
                              {paymentInstruction && (
                                <p style={{ fontSize: 14 }}>
                                  {paymentInstruction}
                                </p>
                              )}
                            </div>
                          </Grid>
                          <Grid
                            item
                            md={12}
                            lg={12}
                            xs={12}
                            sm={12}
                            xl={12}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            <Grid container md={4} lg={4} xs={4} sm={4} xl={4}>
                              {storeImage && (
                                <img
                                  src={storeImage}
                                  style={{
                                    display: "flex",
                                    width: 250,
                                    height: 100,
                                    alignSelf: "center",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifySelf: "center",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid container md={4} lg={4} xs={4} sm={4} xl={4}>
                              <input
                                type="file"
                                ref={hiddenFileInput}
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={handleChangeImage}
                                id="contained-button-file"
                              />
                              <label
                                style={{ alignSelf: "center" }}
                                htmlFor="contained-button-file"
                              >
                                <Button
                                  style={{
                                    border: `1px solid ${Colors.buttonGreen}`,
                                    color: "black",
                                    textTransform: "none",
                                  }}
                                  onClick={handleClick}
                                >
                                  Upload Receipt
                                </Button>
                              </label>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {cashOnDelivery && (
                      <div
                        style={{
                          display: "flex",
                          padding: 20,
                          borderBottomWidth: 1,

                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setFormStepper(4)}
                      >
                        <CreditCard
                          style={{
                            color: "#72767E",
                            fontSize: 23,
                            marginRight: 10,
                          }}
                        />
                        <div
                          style={{
                            fontSize: 15,
                            fontWeight: "600",
                            color: "#72767E",
                          }}
                        >
                          Payment Receipt
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Grid>
          {/* guarantee area */}
          <Grid
            md={2.5}
            xs={10}
            style={{ backgroundColor: "white", margin: 2 }}
            item
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: 50, height: 50 }}
                  src={require("../assets/guaranteeIcon.png")}
                />
                <div>
                  <div
                    style={{
                      fontSize: 14,
                      color: "#3C78C6",
                      fontWeight: "500",
                    }}
                  >
                    100% garantía de satisfacción
                  </div>
                  <div
                    style={{
                      width: 220,
                      fontSize: 14,
                      color: "black",
                      fontWeight: "500",
                    }}
                  >
                    Haz tu pedido con tranquilidad
                    <div class="tooltip">
                      <HelpOutlineIcon
                        style={{ fontSize: 15, marginLeft: 3 }}
                      />
                      <span
                        style={{ left: -20, top: 30, position: "absolute" }}
                        class="tooltiptext"
                      >
                        Haz tu pedido con tranquilidad
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                <div
                  style={{ fontSize: 14, color: "#393939", fontWeight: "700" }}
                >
                  Item Subtotal
                </div>
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{commafy(totalCartPrice)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: 14,
                      color: "#393939",
                      fontWeight: "700",
                    }}
                  >
                    Service Fee
                  </div>
                  <ArrowForwardIosIcon
                    style={{ fontSize: 11, marginLeft: 3 }}
                  />
                </div>
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{serviceFee}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              {deliveryFee ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 14,
                        color: "#393939",
                        fontWeight: "700",
                      }}
                    >
                      Costo de envío
                    </div>
                    <ArrowForwardIosIcon
                      style={{ fontSize: 11, marginLeft: 3 }}
                    />
                  </div>
                  <div style={{ color: "#393939", fontSize: 14 }}>
                    ₡{deliveryFee}
                  </div>
                </>
              ) : (
                <div></div>
              )}
            </div>
            <div
              style={{ width: "100%", backgroundColor: "lightgray", height: 1 }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ fontSize: 14, color: "#393939", fontWeight: "700" }}
                >
                  Total
                </div>
              </div>
              {deliveryFee ? (
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{`${commafy(finalValue)}`}
                </div>
              ) : (
                <div style={{ color: "#393939", fontSize: 14 }}>
                  ₡{`${commafy(totalCartPrice + serviceFee)}`}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            backgroundColor: "white",
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            width: "100%",
            bottom: 0,
          }}
          xs={12}
          md={12}
        >
          {!submitDisable && (
            <button
              onClick={checkOutSubmit}
              disabled={submitDisable}
              style={{
                backgroundColor:
                  !cashOnDelivery || uploadImageDisable ? "#52AE11" : "#BDBDBD",
                width: "80%",
                paddingTop: 12,
                paddingBottom: 12,
                alignContent: "center",
                alignItems: "center",
                borderRadius: 8,
                display: "flex",
                justifyContent: "center",
                color:
                  !cashOnDelivery || uploadImageDisable ? "white" : "black",
                cursor: "pointer",
              }}
            >
              Continuar
            </button>
          )}
          {submitDisable && (
            <button
              onClick={checkOutSubmit}
              disabled={submitDisable}
              style={{
                backgroundColor: "#BDBDBD",
                width: "80%",
                paddingTop: 12,
                paddingBottom: 12,
                alignContent: "center",
                alignItems: "center",
                borderRadius: 8,
                display: "flex",
                justifyContent: "center",
                color: uploadImageDisable ? "black" : "white",
                cursor: "pointer",
              }}
            >
              Continuar
            </button>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default UserCheckOut;
