import React, { useEffect, useState } from "react";

import Image from "./ImageComponent";

import ProductDetailModal from "./ProductDetailModal";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import CircularProgress from "@mui/material/CircularProgress";
import { PaginatedProducts } from "../features/slices/paginatedProductsSlice";
import "./Navbar.css";
import { Grid } from "@mui/material";
import { useRef } from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import axios from "../axios";
import Skeleton from "@mui/material/Skeleton";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "90vh",
  bgcolor: "background.paper",
  border: "1px solid white",
  p: 4,
};
const Products = (props) => {
  let { data, storeData, MobileUi } = props;
  console.log(data, "productData");
  // let productData = JSON.parse(JSON.stringify(data))
  // let productData = data
  const [productData, setProductData] = useState(data.products);
  // Object.defineProperties(productData, {
  //   products: {
  //     value: data.products,
  //     writable: true
  //   },
  //   aisle: {
  //     value: data.aisle,
  //     writable: true
  //   }
  // })

  // const d = useRef(data)
  // console.log(data, "product data")
  // const navigation = useNavigate();

  // const handleOpen = (event) => {
  //   console.log(event)
  //   event.preventDefault()
  //   setOpen(true);
  //   event.stopPropagation()
  // }
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  // const [productData, setProuctData] = useState(data)
  const handleClose = () => setOpen(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [modalItem, setModalItem] = useState();
  // const ref = useRef()
  // console.log(currentItems, "currentItemscurrentItemscurrentItems");
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(2);
  const [count, setCount] = useState(0);
  const listInnerRef = useRef();
  const [loading, setLoading] = useState(true);

  let skip = 0;
  let itemsPerPage = 5;
  let limit = 8;

  useEffect(() => {
    // Fetch items from another resources.
    // if (data.length < 5) {
    //   itemsPerPage = data.length
    //   console.log("if")

    // }
    console.log(data, "dataProduct");

    // console.log(itemsPerPage, "item per page")

    // console.log(data, data.length, "product data")
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    // console.log(d, "sliced")
    if (productData?.length >= 1) {
      setCurrentItems(productData?.slice(itemOffset, endOffset));
      setPageCount(Math?.ceil(productData.length / itemsPerPage));
      setShow(true);
    }

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
  }, [itemOffset, itemsPerPage, productData?.length, productData]);
  function sentenceCase(str) {
    if (str === null || str === "") return false;
    else str = str?.toString();

    return str?.replace(/\w\S*/g, function (txt) {
      return txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase();
    });
  }

  const handlePageClick = async (event) => {
    console.log(event);
    console.log(pageCount);

    if (event.selected > count) {
      const response = await axios.post(
        "/api/v1/products/getPaginatedProducts",
        {
          data: {
            vendorProductId: storeData.item.storeName,
            aisle: data.aisle,
            skip: pageNumber * limit,
            limit: limit,
          },
        }
      );
      setCount(count + 1);

      // pageNumber = pageNumber + 1
      setPageNumber(pageNumber + 1);

      console.log(response);
      if (response.data.newProduct.length !== 0) {
        let newOne = [].concat(productData, response.data.newProduct);
        console.log(newOne, "ye hain");
        // productData.products.concat(response.data.newProduct)
        setProductData(newOne);
        console.log(response.data.newProduct, "ye hain");

        const newOffset = (event.selected * itemsPerPage) % newOne.length;

        setItemOffset(newOffset);
      } else {
        const newOffset = (event.selected * itemsPerPage) % productData.length;

        setItemOffset(newOffset);
      }
    } else {
      const newOffset = (event.selected * itemsPerPage) % productData.length;

      setItemOffset(newOffset);
    }
  };
  const setModalOpen = (value) => {
    // console.log(value)
    setModalItem(value);
    setOpen(true);
  };
  function commafy(num) {
    var str = num?.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0]?.replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 4) {
      str[1] = str[1]?.replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  }

  const onScroll = async () => {
    console.log(listInnerRef);
    if (listInnerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = listInnerRef.current;
      if (scrollLeft + clientWidth === scrollWidth) {
        setLoading(false);
        const response = await axios.post(
          "/api/v1/products/getPaginatedProducts",
          {
            data: {
              vendorProductId: storeData.item.storeName,
              aisle: data.aisle,
              skip: pageNumber * limit,
              limit: limit,
            },
          }
        );
        setLoading(true);
        setCount(count + 1);

        // pageNumber = pageNumber + 1
        setPageNumber(pageNumber + 1);
        if (response.data.newProduct.length !== 0) {
          let newOne = [].concat(productData, response.data.newProduct);
          console.log(newOne, "ye hain");
          // productData.products.concat(response.data.newProduct)
          setProductData(newOne);
          console.log(response.data.newProduct, "ye hain");
        }
        console.log("Reached bottom");
      }
    }
  };
  return (
    <Grid container xs={12} md={12} lg={12}>
      <Grid md={11.5} xs={11.5} item style={Styles.GridContainer}>
        {currentItems && (
          <div style={Styles.freshProduct}>{sentenceCase(data.aisle)}</div>
        )}
        <div style={{ display: "flex" }}>
          {MobileUi ? (
            <></>
          ) : (
            <ReactPaginate
              nextLabel={<KeyboardArrowRightIcon />}
              previousLabel={<KeyboardArrowLeftIcon />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              breakLabel=""
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageLinkClassName="pagenumDisplay"
              previousLinkClassName="prepage"
              nextLinkClassName="nextpage"
              activeLinkClassName="active"
            />
          )}
        </div>
      </Grid>
      <Grid
        style={{
          display: "flex",
        }}
        container
      >
        {productData ? (
          <>
            <>
              {MobileUi ? (
                <div
                  className="scrollbar-hide"
                  id="slider"
                  style={Styles.scrollStyles}
                  onScroll={() => onScroll()}
                  ref={listInnerRef}
                  // onScroll={(event) => { console.log(event.bubbles, "ye hy ref") }}
                >
                  {loading ? (
                    <>
                      {productData?.map((currentItem) => {
                        console.log(currentItem, "newcurrentItem");
                        return (
                          <div
                            style={Styles.scrollChild}
                            key={currentItem.products._id}
                          >
                            <div>
                              <Image
                                MobileUi={MobileUi}
                                handleOpen={() =>
                                  setModalOpen(currentItem.products)
                                }
                                currentItem={currentItem.products}
                                storeId={storeData?.item?._id}
                              />
                              <div style={{ height: 120 }}>
                                <div
                                  style={{
                                    fontWeight: "700",
                                    color: "rgb(52, 53, 56)",
                                    fontSize: 20,
                                  }}
                                >
                                  ₡{commafy(currentItem.products.price)}
                                </div>
                                <div
                                  style={{
                                    width: 150,
                                    fontSize: 14,
                                    color: "rgb(52, 53, 56)",
                                    whiteSpace: "pre-line",
                                  }}
                                >
                                  {currentItem.products.name}
                                </div>
                                <div
                                  style={{
                                    width: 150,
                                    fontSize: 13,
                                    color: "#606163",
                                  }}
                                >
                                  {currentItem.products.size}
                                </div>
                              </div>
                            </div>
                            {/* <div ref={ref} /> */}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {" "}
                      {[1, 2, 3]?.map((id) => {
                        // console.log(currentItem, "newcurrentItem");
                        return (
                          <div style={Styles.scrollChildSkelton} key={id}>
                            <Skeleton
                              variant="rectangular"
                              width={150}
                              height={118}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={80}
                              height={10}
                              style={{ marginTop: 10 }}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={100}
                              height={20}
                              style={{ marginTop: 10 }}
                            />
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              ) : (
                <>
                  {currentItems?.map((currentItem) => {
                    // console.log(currentItem, "newcurrentItem");
                    return (
                      <>
                        <Grid
                          key={currentItem.products._id}
                          style={{
                            marginLeft: 30,
                            height: 300,
                            width: "80vw",
                          }}
                          md={2}
                          xs={4.5}
                          item
                        >
                          <div>
                            <Image
                              handleOpen={() =>
                                setModalOpen(currentItem.products)
                              }
                              currentItem={currentItem.products}
                              storeId={storeData?.item?._id}
                            />

                            <div
                              style={{
                                fontWeight: "700",
                                color: "rgb(52, 53, 56)",
                                fontSize: 20,
                              }}
                            >
                              ₡{commafy(currentItem.products.price)}
                            </div>
                            <div style={Styles.title}>
                              {currentItem.products.name}
                            </div>
                            <div style={Styles.size}>
                              {currentItem.products.size}
                            </div>
                          </div>
                        </Grid>
                      </>
                    );
                  })}
                </>
              )}
            </>
            <div>
              {setModalItem && (
                <>
                  <ProductDetailModal
                    // key={currentItem._id}
                    storeId={storeData?.item?._id}
                    currentItem={modalItem}
                    open={open}
                    onClose={handleClose}
                  />
                </>
              )}
            </div>
          </>
        ) : (
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          ></Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default Products;

const Styles = {
  GridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  freshProduct: {
    marginLeft: 30,
    fontSize: 20,
    fontWeight: "600",
  },
  paginationContainer: { display: "flex", alignItems: "center" },
  title: {
    width: 190,
    fontSize: 15,
    color: "rgb(52, 53, 56)",
  },
  size: {
    width: 190,
    fontSize: 13,
    color: "#606163",
  },
  scrollStyles: {
    width: "100vw",
    display: "flex",
    overflowX: "scroll",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
    height: 300,
  },
  scrollChild: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    cursor: "pointer",
    padding: 3,
    paddingLeft: 5,
  },
  scrollChildSkelton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignSelf: "flex-start",
    alignItems: "flex-start",
    cursor: "pointer",
    padding: 3,
    paddingLeft: 5,
    paddingTop: 20,
  },
};

{
  /* <Grid
key={currentItem._id}
style={{ marginLeft: 30, height: 300 }}
md={2}
xs={3}
item
>
<div>
  <Image
    handleOpen={() => setModalOpen(currentItem)}
    currentItem={currentItem}
    storeId={storeData?.item?._id}
  />

  <div style={{ fontWeight: "600" }}>
    ₡{currentItem.price}
  </div>
  <div style={Styles.title}>{currentItem.name}</div>
  <div style={Styles.size}>{currentItem.size}</div>
</div>
</Grid> */
}
