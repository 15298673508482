import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';
import axios from "../../axios"

const initialState = {
    data1: [],
    dataDetail: [],
    vendorProductId: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: '',
};

export const PaginatedProducts = createAsyncThunk(
    'PaginatedProducts/PaginatedProducts',
    async (data, thunkAPI) => {
        console.log(data, "vendorproductparamsId in redux")
        try {
            const response = await axios.post('/api/v1/product/getPaginatedProducts', { ...data });
            console.log(response, "redux")
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const paginatedProductsSlice = createSlice({
    name: 'PaginatedProducts',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder
            // store
            .addCase(PaginatedProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(PaginatedProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.data1 = action.payload;
                state.vendorProductId = action.payload
            })
            .addCase(PaginatedProducts.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.data1 = [];
            })


    },
});

export const { reset } = paginatedProductsSlice.actions;
export default paginatedProductsSlice.reducer;
