import * as React from "react";
import Grid from "@mui/material/Grid";
import Colors from "./Colors";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../axios";
import { useState } from "react";
import { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import grabLocation from "./config/GetLocation";
import LocContext from "./Locationcontext";
import Avatar from "@mui/material/Avatar";
import { useStateValue } from "../ContextApi/StateProvider";
import loadingIcon from "../assets/Basimart_Loader.gif";
function useIsMounted() {
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  return isMounted;
}

export default function CategoriesComponent() {
  const navigation = useNavigate();
  const userLocation = React.useContext(LocContext);
  const [show, setShow] = useState(true);
  const [{ vendorId }, dispatch] = useStateValue();
  const isMountedRef = useIsMounted();

  const [vendor, setVendor] = useState([]);
  // console.log(vendor, "vendorvendorvendor");
  useEffect(() => {
    async function fetchData() {
      console.log(userLocation);
      setShow(true);
      if (userLocation.loc) {
        if (isMountedRef.current) {
          const req = await axios.post("/api/v1/vendor/getVendorsForDoc");
          // console.log(userLocation.loc);
          let g = await grabLocation(userLocation?.loc, req.data.vendor);
          console.log(req.data.vendor, "gggggggg");

          if (g) {
            setVendor(g);
            setShow(false);
          }
        }
      }
    }
    fetchData();
  }, [userLocation, isMountedRef]);
  return (
    <Box display={"flex"} justifyContent="center">
      <Grid
        container
        spacing={0}
        md={11}
        xs={12}
        sm={8}
        sx={{
          marginTop: 5,
          // marginLeft: 50
        }}
      >
        {show ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              alignSelf: "center",
              alignContent: "center",
            }}
          >
            <img src={loadingIcon} size={10} height={500} width={350} />
          </div>
        ) : (
          <>
            {vendor.length !== 0 && !show ? (
              vendor.map((item, index) => (
                <>
                  <Grid
                    key={item._id}
                    item
                    style={{
                      cursor: "pointer",
                      width: "100%",
                    }}
                    lg={3}
                    md={4}
                  >
                    <Link
                      to={`/tiendas/${item.storeName}`}
                      style={{
                        display: "flex",
                        marginBottom: 20,
                        margin: 10,
                        boxShadow: "3px 3px 10px 3px rgba(0, 0, 0, 0.06)",
                        padding: 10,
                        borderRadius: 10,
                      }}
                    >
                      <div>
                        {item?.image ? (
                          <img style={Styles.imgContainer} src={item?.image} />
                        ) : (
                          <Avatar style={Styles.imgContainer} />
                        )}
                      </div>
                      <div style={{ marginLeft: 3 }}>
                        <div style={Styles.titleContainer}>
                          {item.storeName}
                        </div>
                        <div style={Styles.distanceContainer}>
                          {(item?.distance * 1000).toFixed(0)}m de tu ubicación
                        </div>
                      </div>
                    </Link>
                  </Grid>
                </>
              ))
            ) : (
              <>
                {vendor.length === 0 && !show && (
                  <p
                    style={{
                      fontSize: 20,
                      color: Colors.lightGreen,
                      fontWeight: "bold",
                    }}
                  >
                    No hay tiendas cerca de tu ubicación actual{" "}
                  </p>
                )}
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}
const Styles = {
  GridContainer: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    alignContent: "center",
    justifyItems: "center",
    border: "1px solid black",
    // width: "50%"
  },
  mainContainer: {
    boxShadow: "3px 3px 10px 3px rgba(0, 0, 0, 0.06)",
  },
  distanceContainer: {
    fontSize: 13,
    fontWeight: "600",
    maxWidth: 170,
    minWidth: 100,
    height: 20,
    marginLeft: 20,

    color: Colors.lightGreen,
  },
  textImgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "20%",
    // backgroundColor: Colors.seeGreen,
    // marginLeft: 10,
    // marginRight: 10,
    // marginBottom: 10,
    // borderRadius: "50%",
    borderWidth: 1,
    // height: 230
  },
  titleContainer: {
    fontSize: 15,
    fontWeight: "600",
    maxWidth: 170,
    minWidth: 100,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
    textAlign: "center",
  },
  dateContainer: { color: "green", fontWeight: "600" },
  desContainer: { fontSize: 12, fontWeight: "600" },
  imgContainer: {
    width: 70,
    height: 70,
    marginBottom: 5,
    borderRadius: "50%",
  },
};
