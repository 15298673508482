import React, { useEffect, useRef, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";

import Collapse from "@mui/material/Collapse";
import useCategoty from "../hooks/useCategory";
function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  return isMounted;
}
function sentenceCase(str) {
  if ((str === null) || (str === ''))
    return false;
  else
    str = str.toString();

  return str.replace(/\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() +
        txt.substr(1).toLowerCase();
    });
}
function SubCategoriesComponent({
  item,
  getAisle,
  getCategory,
  setSelectedAisle,
  selectedAisle,
  selectedCategory,
  setSelectedCategory,
}) {
  const [openList, setOpenList] = useState(false);
  const isMountedRef = useIsMounted();
  const cat = useCategoty();
  let { catgry } = cat;
  console.log(item, "itemitem");

  const handleChange = (val) => {
    setOpenList(!openList);
    console.log(selectedAisle, "selectedAisle");
    console.log(val, "valSlected");
    console.log(val, "valSelected");
    // console.log(val)
    getAisle(val);
    setSelectedCategory("");
    setSelectedAisle(val);
  };
  return (
    <>
      {item && (
        <div style={{ paddingBlock: 0 }} key={item.id}>
          <div onClick={() => handleChange(item?.aisle)}>
            <div
              style={{
                marginTop: 5,
                marginLeft: 5,
                marginRight: 5,
                padding: 10,
                borderRadius: 10,
                cursor: "pointer",
                color: selectedAisle === item?.aisle ? "white" : "#343538",
                backgroundColor:
                  selectedAisle === item?.aisle ? "#343538" : "white",
                fontSize: 15,
                fontWeight: "600",
              }}
            >
              {sentenceCase(item.aisle)}
            </div>
          </div>
          <Collapse key={item.id} in={openList} timeout="auto" unmountOnExit>
            {item?.category?.map((i) => (
              <List
                // className="myList"
                className="myList"
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  borderRadius: 10,
                  cursor: "pointer",

                  backgroundColor:
                    selectedCategory === i?.label ? "#343538" : "white",
                }}
                disablePadding
                key={i._id}
              >
                <div
                  style={{
                    marginLeft: 25,

                    padding: 10,
                  }}
                  onClick={() => {
                    getCategory(i);
                    setSelectedAisle("");
                    setSelectedCategory(i?.label);
                  }}
                >
                  <div
                    style={{
                      color:
                        selectedCategory === i?.label ? "white" : "#343538",
                      fontSize: 15,
                      fontWeight: "normal",
                    }}
                  >
                    {sentenceCase(i?.label)}
                  </div>
                </div>
              </List>
            ))}
          </Collapse>
        </div>
      )}
    </>
  );
}

export default SubCategoriesComponent;
