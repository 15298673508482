import { Grid } from "@mui/material";
import React from "react";
import Image from "./ImageComponent";
import "./Navbar.css";
import ProductDetailModal from "./ProductDetailModal";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "../axios";

const AllCategoryComponent = ({
  productData1,
  storeData,
  selectedAisle,
  selectedCategory,
  handleSubCategory,
  productSerach,
  setSelectSub,
  selectSub,
  // fetchMore
}) => {
  let skip = 0;
  const [productData, setProductData] = useState(productData1);
  const [pageNumber, setPageNumber] = useState(1);
  console.log(selectedCategory, "selectedAisle1");
  const [modalItem, setModalItem] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [isMoreAvailable, setIsMoreAvailable] = useState(true);
  let limit = 100;
  const setModalOpen = (value) => {
    // console.log(value)
    setModalItem(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleClick = (i) => {
    setSelectSub(i);
    handleSubCategory(i);
    console.log(i, "hellllll");
  };

  const fetchMore = async () => {
    console.log(selectedAisle, selectedCategory, selectSub, "ye hain bhai");
    const response = await axios.post("/api/v1/products/getPaginatedProducts", {
      data: {
        vendorProductId: storeData.item.storeName,
        aisle: selectedAisle,
        category: selectSub ? "" : selectedCategory.label,
        subCategory: selectSub,

        skip: pageNumber * limit,
        limit: limit,
      },
    });
    if (response.data.newProduct !== 0) {
      let newOne = [].concat(productData, response.data.newProduct);
      console.log(newOne, "ye hain");
      setProductData(newOne);
      setPageNumber(pageNumber + 1);
    } else {
      setIsMoreAvailable(false);
    }
  };

  return (
    <>
      {selectedAisle && (
        <div
          style={{
            marginLeft: 30,
            fontSize: 20,
            fontWeight: "600",
            marginTop: 40,
            marginBottom: 10,
          }}
        >
          {selectedAisle}
        </div>
      )}
      {selectedCategory && (
        <div
          style={{
            marginLeft: 30,
            fontSize: 20,
            fontWeight: "600",
            marginTop: 40,
            marginBottom: 10,
          }}
        >
          {selectedCategory.label}
        </div>
      )}
      {selectedCategory?.hasOwnProperty("sub") && (
        <div
          style={{
            marginLeft: 20,
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          {selectedCategory.sub.map((lab) => (
            <div
              onClick={() => handleClick(lab.label)}
              style={{
                marginLeft: 10,
                backgroundColor: "#EFF0F1",
                fontWeight: "600",
                borderColor: selectSub == lab?.label ? "black" : "white",
                borderWidth: selectSub == lab?.label ? 2 : 0,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 15,
                paddingRight: 15,
                borderRadius: 15,
                color: "#434343",
                cursor: "pointer",
              }}
            >
              {lab.label}
            </div>
          ))}
        </div>
      )}
      {productData && (
        <InfiniteScroll
          dataLength={productData.length} //This is important field to render the next data
          next={fetchMore}
          hasMore={isMoreAvailable}
          // loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          // below props only if you need pull down functionality
          // refreshFunction={this.refresh}
          // pullDownToRefresh
          // pullDownToRefreshThreshold={50}
          // pullDownToRefreshContent={
          //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
          // }
          // releaseToRefreshContent={
          //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          // }
        >
          <Grid
            style={{
              display: "flex",
            }}
            container
          >
            {productData &&
              productData?.map((product, index) => {
                console.log(product, "productDataItem");

                return (
                  <Grid
                    item
                    style={{ marginLeft: 30, width: "80vw" }}
                    md={2}
                    xs={4.5}
                  >
                    <Image
                      currentItem={product.products}
                      storeId={storeData?.item?._id}
                      handleOpen={() => setModalOpen(product.products)}
                    />
                    {product?.products.price && (
                      <div style={{ fontWeight: "600" }}>
                        ₡{product.products.price}
                      </div>
                    )}
                    <div style={Styles.title}>{product?.products.name}</div>
                    <div style={Styles.size}>{product?.products.size}</div>
                  </Grid>
                );
              })}
            <div>
              {setModalItem && (
                <ProductDetailModal
                  // key={currentItem._id}
                  storeId={storeData?.item?._id}
                  currentItem={modalItem}
                  open={open}
                  onClose={handleClose}
                />
              )}
            </div>
          </Grid>
        </InfiniteScroll>
      )}
    </>
  );
};

export default AllCategoryComponent;
const Styles = {
  GridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  freshProduct: {
    marginLeft: 30,
    fontSize: 20,
    fontWeight: "600",
    marginTop: 40,
  },
  paginationContainer: { display: "flex", alignItems: "center" },
  title: {
    width: 190,
    fontSize: 15,
    color: " rgb(52, 53, 56",
  },
  size: {
    width: 190,
    fontSize: 14,
    color: "#606163",
  },
};
