export const data3 = [
  {
    id: 1,
    Category: {
      cateName: "Produce",
      subCategories: [
        { id: 1, subCateName: "Fresh Fruits" },
        { id: 2, subCateName: "Fresh Vegetables" },
      ],
    },
  },
  {
    id: 2,
    Category: {
      cateName: "Dairy & Eggs",
      subCategories: [
        { id: 1, subCateName: "Cheese" },
        { id: 2, subCateName: "Eggs" },
        { id: 3, subCateName: "Yogurt" },
        { id: 4, subCateName: "Butter" },
        { id: 5, subCateName: "Milk" },
        { id: 6, subCateName: "Plant-Based Milk" },
      ],
    },
  },
  {
    id: 3,
    Category: {
      cateName: "Beverages",
      subCategories: [
        { id: 1, subCateName: "Soda & Soft Drinks" },
        { id: 2, subCateName: "Sports Drink" },
        { id: 3, subCateName: "Coffee" },
        { id: 4, subCateName: "Water & Sparkling Water" },
        { id: 5, subCateName: "Milk" },
        { id: 6, subCateName: "juice" },
      ],
    },
  },
  {
    id: 4,
    Category: {
      cateName: "Pantry",
      subCategories: [
        { id: 1, subCateName: "Baking & Cooking" },
        { id: 2, subCateName: "Canned Goods & Soups" },
        { id: 3, subCateName: "Snacks & Candy" },
        { id: 4, subCateName: "Sauces & Condiments" },
        { id: 5, subCateName: "Nut Butters & Spreads" },
        { id: 6, subCateName: "BreakFast" },
      ],
    },
  },
  {
    id: 5,
    Category: {
      cateName: "Deli & Prepared Foods",
      subCategories: [
        { id: 1, subCateName: "Soups & Salads" },
        { id: 2, subCateName: "Party Platters & Snack Packs" },
        { id: 3, subCateName: "Cheese" },
        { id: 4, subCateName: "Prepared Seafood" },
        { id: 5, subCateName: "Prepared Meals & Sides" },
        { id: 6, subCateName: "Prepared Chicken & Meats" },
        { id: 7, subCateName: "Olives,Dips & Spreads" },
      ],
    },
  },
  {
    id: 6,
    Category: {
      cateName: "Frozen",
      subCategories: [
        { id: 1, subCateName: "Frozen Pizzas & Meals" },
        { id: 2, subCateName: "Ice Cream & Popsicle" },
        { id: 3, subCateName: "Frozen Snacks" },
        { id: 4, subCateName: "Frozen Fruits " },
        { id: 5, subCateName: "Cheese" },
        { id: 6, subCateName: "Cheese" },
        { id: 7, subCateName: "Cheese" },
        { id: 8, subCateName: "Cheese" },
        { id: 9, subCateName: "Cheese" },
      ],
    },
  },
  {
    id: 7,
    Category: {
      cateName: "Frozen",
      subCategories: [
        { id: 1, subCateName: "Frozen Pizzas & Meals" },
        { id: 2, subCateName: "Ice Cream & Popsicle" },
        { id: 3, subCateName: "Frozen Snacks" },
        { id: 4, subCateName: "Frozen Fruits " },
        { id: 5, subCateName: "Frozen Vegetables" },
        { id: 6, subCateName: "Frozen Breakfast" },
        { id: 7, subCateName: "Frozen Meat & Seafood" },
        { id: 8, subCateName: "Frozen Desserts" },
      ],
    },
  },
  {
    id: 8,
    Category: {
      cateName: "HouseHold",
      subCategories: [
        { id: 1, subCateName: "Paper Goods" },
        { id: 2, subCateName: "Cleaning Solutions" },
        { id: 3, subCateName: "Laundry" },
        { id: 4, subCateName: "Trash Bins & Bags" },
        { id: 5, subCateName: "Candles & Air Fresheners" },
        { id: 6, subCateName: "Cleaning Tools" },
        { id: 7, subCateName: "Housewares" },
      ],
    },
  },
  {
    id: 9,
    Category: {
      cateName: "Bakery",
      subCategories: [
        { id: 1, subCateName: "Bread" },
        { id: 2, subCateName: "Buns & Rolls" },
        { id: 3, subCateName: "Cookies & Pies" },
        { id: 4, subCateName: "Breakfast Pastries " },
        { id: 5, subCateName: "More Desserts" },
        { id: 6, subCateName: "Tortillas & Flatbreads" },
        { id: 7, subCateName: "Bagels & English Muffins" },
      ],
    },
  },
];
