import { Add } from "@material-ui/icons";
import { useStateValue } from "../ContextApi/StateProvider";
import Counter from "./Counter";
import "./Navbar.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Image = ({ currentItem, handleOpen, storeId, MobileUi }) => {
  const {
    images,
    name,
    date,
    _id,
    pId,
    price,
    stock,
    size,
    description,
    qty = 1,
  } = currentItem;

  // console.log(currentItem, "currentItem");
  const timeout = 800;
  const [{ cart, vId, err }, dispatch] = useStateValue();
  // console.log(vId, "vendorId");
  // console.log(err, "err");
  const existItem = cart.find((x) => x.id === _id?.concat(storeId));
  console.log(existItem, "add to cart");

  const addToCart = () => {
    dispatch({
      type: "ADD_TO_CART",
      item: {
        _id,
        id: _id.concat(storeId),
        pId,
        name,
        images,
        date,
        price,
        stock,
        size,
        description,
        qty: 1,
        basePrice: price,
        vendorId: storeId,
      },
    });
    localStorage.setItem("cart", JSON.stringify(currentItem, null, 4));
  };
  // String.prototype.toCamelCase = function (str) {
  //   return str
  //     .replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
  //     .replace(/\s/g, '')
  //     .replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
  // }
  return (
    <div>
      {existItem ? (
        <>
          <Counter
            counterStyle={{
              display: "flex",
              backgroundColor: "#52AE11",
              justifyContent: "space-between",
              borderRadius: 18,
              padding: 5,
              color: "white",
              width: MobileUi ? 120 : 130,
              position: "relative",
              top: 10,
              left: MobileUi ? 10 : 40,
            }}
            id={_id.concat(storeId)}
            price={price}
            date={date}
            img={images}
            title={name}
            qty={existItem.qty}
            stock={stock}
            basePrice={price}
          />
          {images && (
            <img
              onClick={handleOpen}
              style={{
                width: MobileUi ? 130 : 160,
                height: MobileUi ? 120 : 150,
                borderRadius: 10,
                cursor: "pointer",
                marginTop: -16,
              }}
              src={images}
            />
            // <LazyLoadImage
            //   // alt={image.alt}

            //   style={{
            //     width: MobileUi ? 130 : 160,
            //     height: MobileUi ? 120 : 150,
            //     borderRadius: 10,
            //     cursor: "pointer",
            //     marginTop: -16,
            //   }}
            //   src={images} // use normal <img> attributes as props
            // />
          )}
        </>
      ) : (
        <>
          {MobileUi ? (
            <div id="outerMob" onClick={addToCart}>
              {images && (
                <div id="innerMob" style={Styles.addStyle}>
                  <Add />
                </div>
              )}
            </div>
          ) : (
            <div id="outer" onClick={addToCart}>
              {images && (
                <div id="inner" style={Styles.addStyle}>
                  <Add style={{ fontSize: 17 }} />
                  Agregar
                </div>
              )}
            </div>
          )}
          {images && (
            <img
              onClick={handleOpen}
              style={{
                width: MobileUi ? 130 : 160,
                height: MobileUi ? 120 : 150,
                borderRadius: 10,
                cursor: "pointer",
                marginTop: MobileUi ? -5 : "0px",
              }}
              src={images}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Image;
const Styles = {
  addStyle: {
    color: "white",
    cursor: "pointer",
    fontSize: 14,
    backgroundColor: "#52AE11",
  },
};
