import * as React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import "./Navbar.css";
import Dropdown from "./Dropdown";
import { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useStateValue } from "../ContextApi/StateProvider";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Counter from "./Counter";
import ModalCounter from "./ModalCounter";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "90vh",
  bgcolor: "background.paper",
  border: "1px solid white",
  p: 4,
};
const ProductDetailModal = ({
  open,
  onClose,
  currentItem,
  storeId,
  mobileProductUi,
}) => {
  // const { images, name, date, _id, price, stock, size, description, qty = 1 } = currentItem;

  console.log(currentItem, "imagesimages");
  const [{ cart, err }, dispatch] = useStateValue();
  const existItem = cart.find(
    (item) => item?.id === currentItem?._id.concat(storeId)
  );
  console.log(existItem?.qty, "existItem");

  const [qty, setQty] = React.useState(1);
  const [disabled, setDisabled] = useState();
  const [selected, setSelected] = useState(1);
  const [dropDownHide, setDropDownHide] = useState(true);
  const [hide, setHide] = useState(false);
  // const [what, setOpen] = React.useState(false);
  const handleClose = () => {
    onClose();
    setDropDownHide(true);
    setSelected(1);
  };
  const [qtyError, setQtyError] = React.useState("");
  const handleChange = (e) => {
    setQty(e.target.value);
    if (e.target.value <= stock && e.target.value > 0) {
      setDisabled(false);
    } else if (e.target.value < 0 || e.target.value == 0) {
      setDisabled(true);
    } else {
      setDisabled(true);
    }
  };
  const handleChangeDropDown = (e) => {
    console.log(e);
    setSelected(e);
    let num = Number(e);
    if (num <= stock && num > 0) {
      setDisabled(false);
    } else if (num < 0 || num == 0 || num > stock) {
      setDisabled(true);
    }
    setQty(num);
  };
  // const IncreaseQty = (id) => {
  //   // setCounter((count) => count + 1);
  //   if (qty < stock) {
  //     dispatch({
  //       type: "INCREMENT_QTY",
  //       payload: id,
  //       basePrice: basePrice,
  //     });
  //   }
  // };
  const stock = Number(currentItem?.stock);
  console.log(stock, "currentItemstock");
  //   console.log((currentItem.price * Number(qty)), "qtyqty")
  const addToCart = () => {
    if (qty <= stock && qty > 0) {
      dispatch({
        type: "ADD_TO_CART_AGAIN",
        item: {
          _id: currentItem._id,
          pId: currentItem.pId,
          id: currentItem._id.concat(storeId),
          name: currentItem.name,
          images: currentItem.images,
          date: currentItem.date,
          price: currentItem.price * Number(qty),
          stock: currentItem.stock,
          size: currentItem.size,
          description: currentItem.description,
          basePrice: currentItem.price,
          qty: Number(qty),
          vendorId: storeId,
        },
      });
    }
  };
  function commafy(num) {
    var str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        center
        styles={{ overlay: Styles.customOverlay, modal: Styles.customModal }}
      >
        {currentItem && (
          <Grid style={{ display: "flex" }} container md={12} xs={12}>
            <Grid xs={12} md={3.5}>
              <div className="img-wrapper">
                <img
                  style={Styles.image}
                  src={currentItem?.images}
                  alt=""
                  className="hover-zoom"
                />
              </div>
            </Grid>
            <Grid
              container
              md={8}
              xs={12}
              style={Styles.productDetailContainer}
            >
              <Grid md={6} xs={12}>
                <div style={Styles.textHeading}>{currentItem?.name}</div>
                <div style={Styles.quantity}>{currentItem?.size}</div>
                <div style={Styles.pricePerOz}>{currentItem?.description}</div>
              </Grid>
              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <Grid>
                  <div style={Styles.price}>₡{commafy(currentItem.price)}</div>
                  <div style={Styles.pricePerOz}>
                    {currentItem?.stock > 1 && "Disponible"}
                  </div>
                </Grid>

                <Grid
                  style={{ display: "flex", justifyContent: "space-between" }}
                  md={6}
                  xs={12}
                >
                  <ModalCounter
                    mobileProductUi={mobileProductUi}
                    counterStyle={{
                      display: "flex",
                      backgroundColor: "#F6F7F8",
                      justifyContent: "space-between",
                      borderRadius: 10,
                      padding: 8,
                      color: "white",
                      width: "49%",
                    }}
                    currentItem={currentItem}
                    handleClose={handleClose}
                    storeId={storeId}
                  />
                </Grid>
              </Box>
              <Box sx={{ display: { xs: "none", md: "flex" }, width: "50%" }}>
                <Grid md={12} xs={12}>
                  <div
                    style={{
                      borderWidth: 1,
                      padding: 20,
                      paddingTop: 30,
                      paddingBlock: 30,
                      marginTop: 35,
                      borderRadius: 9,
                    }}
                  >
                    <div style={Styles.price}>
                      ₡{commafy(currentItem.price)}
                    </div>
                    {selected === "Custom Amount" ? (
                      <div>
                        <input
                          min="1"
                          onChange={handleChange}
                          placeholder="Enter an amount"
                          type="number"
                          style={Styles.inputField}
                        />
                        <button
                          disabled={disabled}
                          onClick={addToCart}
                          style={{
                            backgroundColor: disabled ? "#F6F7F8" : "#52AE11",
                            display: "flex",
                            width: "100%",
                            fontSize: 20,
                            justifyContent: "center",
                            color: disabled ? "lightblack" : "white",
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderRadius: 13,
                            cursor: "pointer",
                            marginTop: 10,
                          }}
                        >
                          Agregar al carrito
                        </button>
                      </div>
                    ) : (
                      <div className="App">
                        {/* custom dropdown menu */}
                        {dropDownHide ? (
                          <Dropdown
                            selected={selected}
                            setSelected={handleChangeDropDown}
                            setDropDownHide={setDropDownHide}
                            setHide={setHide}
                            hide={hide}
                            currentItem={currentItem}
                            storeId={storeId}
                            qty={qty}
                            existItem={existItem}
                          />
                        ) : (
                          <div>
                            <input
                              min="1"
                              onChange={handleChange}
                              placeholder="Enter an amount"
                              type="number"
                              style={Styles.inputField}
                            />
                            <button
                              disabled={disabled}
                              onClick={addToCart}
                              style={{
                                backgroundColor: disabled
                                  ? "#F6F7F8"
                                  : "#52AE11",
                                display: "flex",
                                width: "100%",
                                fontSize: 20,
                                justifyContent: "center",
                                color: disabled ? "lightblack" : "white",
                                paddingTop: 10,
                                paddingBottom: 10,
                                borderRadius: 13,
                                cursor: "pointer",
                              }}
                            >
                              Agregar al carrito
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    <div style={Styles.pricePerOz}>
                      {currentItem?.stock > 1 && "Disponible"}
                    </div>
                    {!existItem && selected !== "Custom Amount" && (
                      <button
                        disabled={disabled}
                        onClick={addToCart}
                        style={{
                          backgroundColor: disabled ? "#F6F7F8" : "#52AE11",
                          display: "flex",
                          width: "100%",
                          fontSize: 20,
                          justifyContent: "center",
                          color: disabled ? "lightblack" : "white",
                          paddingTop: 10,
                          paddingBottom: 10,
                          borderRadius: 13,
                          cursor: "pointer",
                        }}
                      >
                        Agregar al carrito
                      </button>
                    )}
                    <div style={{ marginTop: 30 }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div style={Styles.storeGuarantee}>
                          100% garantía de satisfacción
                        </div>
                        <div className="tooltipModal">
                          <HelpOutlineIcon
                            style={{
                              fontSize: 13,
                              marginLeft: 3,
                              display: "flex",
                            }}
                          />
                          <span className="tooltiptextModal">
                            100% Satisfaction Guarantee If you experience any of
                            the following issues, you are eligible for a refund
                            or credit: damaged/missing items, poor quality item
                            replacements, late arrival, or unprofessional
                            service. Haz tu pedido con tranquilidad. Si
                            experimentas alguno de los siguientes problemas,
                            eres elegible para un reembolso o crédito: Daño o
                            producto faltante Reemplazos deficientes de
                            artículos Llegada tardía Servicio no profesional
                          </span>
                        </div>
                      </div>
                      <div style={Styles.againGuarantee}>
                        Haz tu pedido con tranquilidad.
                      </div>
                    </div>
                  </div>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        )}
      </Modal>
    </div>
  );
};
export default React.memo(ProductDetailModal);

const Styles = {
  image: { minWidth: 300, maxHeight: 350, paddingTop: 40, paddingBottom: 40 },
  productDetailContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  textHeading: { fontSize: 20, fontWeight: "700" },
  productCategory: { fontSize: 15, fontWeight: "600" },
  quantity: { fontSize: 17, fontWeight: "600", color: "gray" },
  price: { fontSize: 20, fontWeight: "700", marginBottom: 30 },
  pricePerOz: {
    fontSize: 15,
    fontWeight: "600",
    marginBottom: 10,
  },
  inputField: {
    width: "100%",
    borderRadius: 13,
    marginRight: 10,
    borderWidth: 1,
    fontSize: 15,
    padding: 5,
    paddingTop: 12,
    paddingBottom: 12,
  },
  addToCartBtn: {
    display: "flex",
    backgroundColor: "green",
    width: "100%",
    fontSize: 20,
    justifyContent: "center",
    color: "white",
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 13,
    cursor: "pointer",
  },
  storeGuarantee: {
    fontWeight: "600",

    cursor: "pointer",

    fontSize: 13,
    cursor: "pointer",
  },
  againGuarantee: {
    fontWeight: "normal",
    color: "rgb(114, 118, 126)",
    fontSize: 15,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
  },
  customOverlay: {
    // background: "rgba(36, 123, 160, 0.7)"
    borderRadius: 10,
  },
  customModal: {
    borderRadius: 10,

    minWidth: "90%",
    maxWidth: "120%",
    maxHeight: "100%",
  },
};