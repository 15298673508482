import { useState } from "react";
import { useStateValue } from "../ContextApi/StateProvider";

function Dropdown({
  selected,
  setSelected,
  currentItem,
  qty,
  existItem,
  setDropDownHide,
  setHide,
  hide,
  storeId,
}) {
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState();
  const [{ cart }, dispatch] = useStateValue();
  console.log(cart, "cart");

  const stock = Number(currentItem?.stock);
  console.log(stock, "stock");
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, "Custom Amount"];
  const handleDropdown = (option) => {
    setIsActive(false);
    if (!existItem) {
      setSelected(option);
    }
    if (option === "Custom Amount") {
      setDropDownHide(false);
    }

    if (option <= stock && qty > 0 && existItem && option <= 9) {
      setSelected(option);

      dispatch({
        type: "ADD_TO_CART_AGAIN",
        item: {
          _id: currentItem._id,
          pId: currentItem.pId,
          id: currentItem._id.concat(storeId),
          name: currentItem.name,
          images: currentItem.images,
          date: currentItem.date,
          price: currentItem.price * option,
          stock: currentItem.stock,
          size: currentItem.size,
          description: currentItem.description,
          basePrice: currentItem.price,
          qty: option,
          vendorId: storeId,
        },
      });
    }
  };
  return (
    <div className="dropdown">
      {existItem ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: existItem ? "#52AE11" : "#f6f7f8",
            color: existItem ? "white" : "black",
          }}
          className="dropdown-btn"
          onClick={() => {
            setIsActive(!isActive);
            setHide(!hide);
          }}
        >
          {existItem ? existItem?.qty : selected} agregado
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#f6f7f8",
            color: existItem ? "white" : "black",
          }}
          className="dropdown-btn"
          onClick={(e) => {
            setHide(!hide);
            setIsActive(!isActive);
          }}
        >
          {existItem ? existItem?.qty : selected}
        </div>
      )}

      {isActive && (
        <div style={{ zIndex: 1 }} className="dropdown-content">
          {options.map((option) => (
            <div
              style={{
                color: "#7A7E86",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => handleDropdown(option)}
              className="dropdown-item"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
