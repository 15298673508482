import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import AllRoute from "./components/AllRoute";
import { useState } from "react";
import reducer, { initialState } from "./ContextApi/reducer";
import { useEffect } from "react";
import AuthContext from "./components/context";
import { Provider } from "react-redux";
import { store } from "./features/store/store";
import { StateProvider } from "./ContextApi/StateProvider";
import { getAuth } from 'firebase/auth'

function App() {
  let auth = getAuth()
  const [user, setUser] = useState();
  const [cartValues, setCartValues] = useState();
  function getUser() {
    const u = localStorage.getItem("user");

    if (u == null || u == "" || u == "undefined") {
      console.log("no user");
      return;
    }
    const us = JSON.parse(u);
    setUser(us);
  }
  useEffect(() => {
    getUser();
  }, [auth.currentUser]);
  function getCart() {
    const u = localStorage.getItem("cartItem");
    console.log(u, "catiItem");

    if (u == null || u == "" || u == "undefined") {
      console.log("no cart");
      setCartValues(initialState);
      return;
    }
    const us = JSON.parse(u);
    setCartValues({ ...us });
    console.log(us, "newcart");
  }
  useEffect(() => {
    getCart();
  }, []);

  const theme = createTheme({
    typography: {
      fontFamily: [
        "Nunito",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
    },
    button: {
      fontFamily: [
        "Nunito",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
      ].join(","),
    },
  });
  return (
    <>
      {cartValues && (
        <StateProvider initialState={cartValues} reducer={reducer}>
          <Provider store={store}>
            <AuthContext.Provider value={{ user, setUser }}>
              <ThemeProvider theme={theme}>
                <AllRoute />
              </ThemeProvider>
            </AuthContext.Provider>
          </Provider>
        </StateProvider>
      )}
    </>
  );
}

export default App;

// import React from "react";

// import { Input } from "@material-ui/core";
// import Autocomplete from "react-google-autocomplete";

// function App() {

//   return (
// <div className="App">
//   <header className="App-header">
//     <div style={{ width: "250px" }}>
//       <span style={{ color: "black" }}>Material UI</span>
//       <Input
//         fullWidth
//         color="secondary"
//         inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
//           <Autocomplete
//             apiKey="AIzaSyCj9n0oIYjF6WrdDxG7UkSUKgqJoBsBzj0"
//             {...props}
//             onPlaceSelected={(selected) => console.log(selected)}
//           />
//         )}
//       />
//     </div>
//   </header>
// </div>
//   );
// }

// export default App;
// import React from "react";
// import ReactDOM from "react-dom";
// import Modal from "react-modal";
// import { Input } from "@material-ui/core";
// import Autocomplete from "react-google-autocomplete";
// const customStyles = {
//   content: {
//     top: "50%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
// };

// // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

// function App() {
//   let subtitle;
//   const [modalIsOpen, setIsOpen] = React.useState(false);

//   function openModal() {
//     setIsOpen(true);
//   }

//   function afterOpenModal() {
//     // references are now sync'd and can be accessed.
//     subtitle.style.color = "#f00";
//   }

//   function closeModal() {
//     setIsOpen(false);
//   }

//   return (
//     <div>
//       <button onClick={openModal}>Open Modal</button>
//       <Modal
//         isOpen={modalIsOpen}
//         onAfterOpen={afterOpenModal}
//         onRequestClose={closeModal}
//         style={customStyles}
//         contentLabel="Example Modal"
//       >
//         <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
//         <button onClick={closeModal}>close</button>
//         <div>I am a modal</div>
//         <div className="App">
//           <header className="App-header">
//             <div style={{ width: "250px" }}>
//               <span style={{ color: "black" }}>Material UI</span>
//               <Input
//                 fullWidth
//                 color="secondary"
//                 inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
//                   <Autocomplete
//                     apiKey="AIzaSyCj9n0oIYjF6WrdDxG7UkSUKgqJoBsBzj0"
//                     {...props}
//                     onPlaceSelected={(selected) => console.log(selected)}
//                   />
//                 )}
//               />
//             </div>
//           </header>
//         </div>
//       </Modal>
//     </div>
//   );
// }

// export default App;
