import React, { useEffect, useState } from "react";

import StoreFrontNavbar from "../../components/StoreFrontNavbar";
import BrandStoreComponent from "../../components/BrandStoreComponent";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocContext from "../../components/Locationcontext";
import grabLocation from "../../components/config/GetLocation";
import axios from "../../axios";
import NavbarComponent from "../../components/NavbarComponent";
import { useLocation } from "react-router-dom";
import Colors from "../../components/Colors";
import { Box } from "@material-ui/core";

const SearchScreen = () => {
  const userLocation = React.useContext(LocContext);
  const [show, setShow] = useState(false);
  const location = useLocation();
  let productData = location.state.productData;
  let searchString = location.state.searchString;
  console.log(productData, "productData");

  return (
    <div style={{ backgroundColor: "white", minHeight: "94vh" }}>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <NavbarComponent />
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <NavbarComponent onMobileUi />
      </Box>{" "}
      {productData && searchString ? (
        <div style={{ paddingTop: 100 }}>
          <div
            style={{
              marginLeft: 100,
              fontSize: 30,
              fontWeight: "700",
              marginBottom: 20,
              color: "#343538",
            }}
          >
            Results for "{searchString}"
          </div>

          {productData.map((doc) =>
            doc.products.length != 0 ? (
              <BrandStoreComponent searchData={doc} />
            ) : (
              <></>
            )
          )}
        </div>
      ) : (
        <p
          style={{ color: Colors.lightGreen, fontSize: 15, fontWeight: "bold" }}
        >
          No se encontraron resultados
        </p>
      )}
    </div>
  );
};

export default SearchScreen;
