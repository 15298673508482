import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "require-ensure";
import { Provider } from "react-redux";
import { store } from "./features/store/store";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
if (!window.Intl) {
  require.call([], () => {
    require("intl");
    ReactDOM.render(
      <>
        <App />
      </>,
      document.getElementById("root")
    );
  });
} else {
  ReactDOM.render(
    <>
      <App />
    </>,
    document.getElementById("root")
  );
}
