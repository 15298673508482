import React, { useState } from "react";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import BoltIcon from "@mui/icons-material/Bolt";

const UserPaymentTab = (props) => {
  //   console.log(props);

  return (
    <div>
      {props?.data?.adminPaymentType?.snipeChecked && (
        <HeaderButton
          text="SINPE Móvil"
          btnColor="black"
          textColor="white"
          activeTab={props.activeTab}
          setActiveTabs={props.setActiveTabs}
          setActiveTabData={props.setActiveTabData}
          setDeliverDisable={props.setDeliverDisable}
          setPaymentDisable={props.setPaymentDisable}
          MobileUi={props.MobileUi}
          icon={<BoltIcon />}
        />
      )}
      {props?.data?.adminPaymentType?.transferChecked && (
        <HeaderButton
          text="Transferencia bancaria"
          btnColor="white"
          textColor="black"
          activeTab={props.activeTab}
          setActiveTabs={props.setActiveTabs}
          setActiveTabData={props.setActiveTabData}
          setDeliverDisable={props.setDeliverDisable}
          setPaymentDisable={props.setPaymentDisable}
          MobileUi={props.MobileUi}
          icon={<BoltIcon />}
        />
      )}
      {props?.data?.adminPaymentType?.onDeliveryChecked && (
        <HeaderButton
          text="Pagar en efectivo"
          btnColor="white"
          textColor="black"
          activeTab={props.activeTab}
          setActiveTabs={props.setActiveTabs}
          setActiveTabData={props.setActiveTabData}
          setDeliverDisable={props.setDeliverDisable}
          setPaymentDisable={props.setPaymentDisable}
          MobileUi={props.MobileUi}
          icon={<BoltIcon />}
        />
      )}
    </div>
  );
};

export default UserPaymentTab;

const HeaderButton = (props) => {
  const handleChange = (props) => {
    console.log(props, "propsprops");
    props.setActiveTabs(props.text);
    props.setActiveTabData(props);
    props.setDeliverDisable(false);
    props.setPaymentDisable(false);
  };
  return (
    <>
      <div
        onClick={() => handleChange(props)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: props.MobileUi ? "100%" : "82.5%",
          backgroundColor:
            props.activeTab === props.text ? "lightgray" : "white",
          alignItems: "center",
          padding: 8,
          borderRadius: 5,
          borderWidth: 1,
          cursor: "pointer",
          marginBottom: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 10,
          }}
        >
          {props.icon}
          <div style={{ fontWeight: "600" }}>{props.text}</div>
        </div>
        <ArrowForwardIosIcon style={{ fontSize: 15 }} />
      </div>
    </>
  );
};
