import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';
import axios from "../../axios"

const initialState = {
    data: [],
    dataDetail: [],
    vendorProductId: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: '',
};

export const storeProduct = createAsyncThunk(
    'storeProduct/storeProduct',
    async (data, thunkAPI) => {
        console.log(data, "vendorproductparamsId in redux")
        try {
            const response = await axios.post('/api/v1/product/getVendorProduct', { ...data });
            console.log(response, "redux")
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const storeProductSlice = createSlice({
    name: 'storeProduct',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
        },
    },
    extraReducers: (builder) => {
        builder
            // store
            .addCase(storeProduct.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(storeProduct.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.data = action.payload;
                state.vendorProductId = action.payload
            })
            .addCase(storeProduct.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.data = [];
            })


    },
});

export const { reset } = storeProductSlice.actions;
export default storeProductSlice.reducer;
