import * as React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Field, Formik, Form } from "formik";
import { TextField } from "formik-material-ui";
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";
import Colors from "../../../components/Colors";
import styled from "styled-components";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";
import axios from "../../../axios";
import { useStateValue } from "../../../ContextApi/StateProvider";
import { useContext } from "react";
import AuthContext from "../../../components/context";

const loginSchema = Yup.object().shape({
  email: Yup.string().required("Correo Electrónico es requerido"),
  password: Yup.string()
    .required("Contraseña es requerido")
    .min(6, "Al menos 6 caracteres"),
});

export default function SignIn() {
  const navigation = useNavigate();
  const [user, setUser] = React.useState({});

  const authContext = useContext(AuthContext);

  // const addUserToStore = (loginUser) => {
  //   dispatch({
  //     type: "USER_ADDED",
  //     item: loginUser,
  //   });
  // };
  const [logInUser, setLogInUser] = React.useState("");
  const [loginError, setLoginError] = React.useState("");
  const [firebaseError, setFirebaseError] = React.useState("");

  console.log(firebaseError, "firebaseError");
  console.log(loginError, "loginError");

  const [email, setEmail] = React.useState("");
  // console.log(email, "emailemailemail");
  const login = async (values, loginValues) => {
    console.log(values);
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      console.log(user, "user");
      if (user) {
        authContext.setUser(JSON.stringify(loginValues, null, 4));
        localStorage.setItem("user", JSON.stringify(loginValues, null, 4));
        navigation("/");
        window.location.reload();
      }
    } catch (error) {
      setLoginError("");
      setFirebaseError(error.message, "eeror");
    }
  };
  const handleSubmit = async (values) => {
    console.log(values, "myValues");
    axios
      .post("/api/v1/user/login", {
        email: values.email.toLowerCase(),
      })
      .then(async (res) => {
        login(values, res.data.user);
      })
      .catch((err) => {
        setFirebaseError("");
        setLoginError(err.response.data.message);
      });
  };
  return (
    <>
      <Container style={{ width: "100%" }}>
        <Box marginTop={8} sx={{ boxShadow: 3 }} style={Styles.Box}>
          <Typography component="h3" variant="h6" style={Styles.LogInText}>
            Iniciar sesión
          </Typography>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={loginSchema}
            onSubmit={(values) => {
              handleSubmit(values);
              // setEmail(values.email);
            }}
          >
            {({ values, handleChange }) => (
              <Form>
                <div>
                  <Field
                    component={TextField}
                    disabled={false}
                    type="email"
                    id="email"
                    name="email"
                    // defaultValue="email"
                    values={values.email}
                    onChange={handleChange}
                    style={{
                      color: "green",
                      borderColor: "black",
                      width: "100%",
                      marginBottom: 10,
                    }}
                    label="Correo Electrónico"
                    // variant="filled"
                  />
                  <Field
                    component={TextField}
                    type="password"
                    id="password"
                    disabled={false}
                    name="password"
                    // defaultValue="password"
                    values={values.password}
                    onChange={handleChange}
                    style={{
                      color: "green",
                      borderColor: "black",
                      width: "100%",
                      marginBottom: 10,
                    }}
                    label="Contraseña"
                    // variant="filled"
                  />

                  <ForgetContainer>
                    <div style={{ color: Colors.lightBlack }}>
                      ¿Olvidaste tu contraseña?
                    </div>
                    <ResetButton onClick={() => navigation("/ForgetPassword")}>
                      Restablecer
                    </ResetButton>
                  </ForgetContainer>
                  <div style={{ color: "red" }}>{loginError}</div>
                  {firebaseError && (
                    <div style={{ color: "red" }}>
                      {"La contraseña o el correo electrónico son incorrectos"}
                    </div>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    // sx={{ mt: 3, mb: 2 }}
                    style={Styles.SignInButton}
                  >
                    <div>Iniciar sesión</div>
                  </Button>

                  <Grid container>
                    <Grid item xs>
                      <div
                        style={{ color: "blue" }}
                        onClick={() => navigation("/Terms")}
                      >
                        <u>Términos y condiciones</u>
                      </div>
                    </Grid>
                  </Grid>
                  <CssBaseline />
                  <SignUpContainer>
                    <div style={Styles.NoAccountText}>
                      ¿No tienes una cuenta?
                    </div>
                    <Button
                      onClick={() => navigation("/CreateAccount")}
                      variant="contained"
                      sx={{ mt: 2, mb: 1 }}
                      style={Styles.SignUpButton}
                    >
                      Regístrate
                    </Button>
                  </SignUpContainer>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </>
  );
}

const Styles = {
  OrStyling: {
    backgroundColor: Colors.darkBlue,
    borderRadius: 20,
    color: "white",
    padding: 5,
    textAlign: "center",
  },
  Divider: {
    width: "50%",
    justifyContent: "center",
    alignSelf: "center",
  },
  Box: {
    padding: 30,
    borderRadius: 20,
    backgroundColor: Colors.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  LogInButton: {
    backgroundColor: Colors.darkBlue,
    padding: 15,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    color: "white",
    cursor: "pointer",
  },

  SignInButton: {
    backgroundColor: "#499b0f",
    borderRadius: 7,
    width: "100%",
    textTransform: "none",
    color: "white",
    fontSize: 13,
    paddingTop: 9,
    paddingBottom: 9,
  },
  SignUpButton: {
    backgroundColor: "#499b0f",
    borderRadius: 7,
    width: "50%",
    textTransform: "none",
    color: "white",
    fontSize: 13,
    paddingTop: 8,
    paddingBottom: 8,
  },
  LogInText: {
    width: "100%",
    fontSize: 25,
    fontWeight: "600",
    marginBottom: 10,
  },
  NoAccountText: { color: Colors.lightBlack, paddingTop: 10 },
};
const ForgetContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 10;
  padding-bottom: 15;
`;
const ResetButton = styled.div`
  color: ${Colors.lightGreen};
  font-weight: 700;
  cursor: pointer;
`;
const SignUpContainer = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
