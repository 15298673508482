import React from "react";
import TermNavbar from "./TermNavbar";

function Terms() {
  return (
    <div>
      <TermNavbar />
      <div
        style={{
          marginTop: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontWeight: "700",
            fontSize: "1.8rem",
            fontFamily: "open sans",
            textAlign: "center",
            color: "#424242",
            marginBottom: 30,
          }}
        >
          Term of Service
        </div>
        <div
          style={{
            fontWeight: "700",
            fontSize: "1.8rem",
            fontFamily: "open sans",
            textAlign: "center",
            color: "#424242",
          }}
        >
          Last updated:June 14,2022
        </div>
        <div
          style={{
            width: "60%",
            color: "#727272",
            fontSize: "1rem",
            fontFamily: "open sans",
            marginTop: 30,
          }}
        >
          Thank you for using Instacart! These Terms of Service (“Terms”) govern
          your use of the Instacart services, including any websites, mobile
          applications, devices, or APIs that are operated by Instacart or its
          subsidiaries and affiliates (collectively, the “Services”), and are
          entered into by you and Maplebear Inc. (d/b/a Instacart), a Delaware
          corporation, and its subsidiaries and affiliates (“Instacart”). This
          Agreement applies to all visitors, users, and other parties who access
          the Services (each, hereafter, a “user”). By using the Services, you
          agree to be bound by these Terms and acknowledge and agree to the
          collection, use, and disclosure of your personal information in
          accordance with Instacart’s Privacy Policy. SECTION 15 (“DISPUTES &
          ARBITRATION”) OF THESE TERMS (THE “ARBITRATION AGREEMENT”) PROVIDES
          THAT ANY CLAIMS THAT YOU AND INSTACART HAVE AGAINST EACH OTHER,
          INCLUDING, WITHOUT LIMITATION, ANY CLAIMS THAT AROSE OR WERE ASSERTED
          BEFORE THE EFFECTIVE DATE OF THESE TERMS, WILL, WITH LIMITED
          EXCEPTIONS, BE SUBMITTED TO BINDING AND FINAL ARBITRATION. UNLESS YOU
          OPT OUT OF THE ARBITRATION AGREEMENT, YOU WILL ONLY BE PERMITTED TO
          PURSUE CLAIMS AND SEEK RELIEF AGAINST INSTACART ON AN INDIVIDUAL
          BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
          REPRESENTATIVE ACTION OR PROCEEDING. TO THE FULLEST EXTENT PERMITTED
          BY LAW, YOU ALSO WAIVE YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND
          TO HAVE A JURY TRIAL ON YOUR CLAIMS. PLEASE SEE SECTION 15 FOR MORE
          INFORMATION REGARDING THIS ARBITRATION AGREEMENT, THE POSSIBLE EFFECTS
          OF THIS ARBITRATION AGREEMENT, AND HOW TO OPT OUT OF THE ARBITRATION
          AGREEMENT. The Services include a technology platform that presents
          you with a set of one or more retailer (each a “Retailer”) virtual
          storefronts from which you can select goods for picking and packing by
          one or more personal shoppers and delivery to your location or, if
          available, for you to pick up in-store. Depending on the Retailer from
          whom you purchase goods through the Services, picking and packing, and
          delivery services may be performed by third parties, which may include
          Retailer personnel, independent contractors, and third-party logistics
          providers (collectively, “Third Party Providers”). You acknowledge
          that Instacart does not supervise, direct, or control the performance
          of services provided by Third Party Providers, and that Third Party
          Providers are neither employed by, nor in any partnership or joint
          venture or agency relationship with, Instacart. When you use the
          Services to place an order for goods, you authorize the purchase of
          those goods from the Retailers you select and, if you have selected
          delivery services, the delivery of those goods by Third Party
          Providers. Unless otherwise specified, you acknowledge and agree that
          Instacart and the Third Party Provider are collectively acting as your
          agents in the ordering, picking, packing, and/or delivery of goods
          purchased by you and that the Retailer—not the Third Party Provider
          and not Instacart—is the seller of the goods to you. You agree that
          your purchase is being made from the Retailer you have selected, that
          Retailer is the merchant of record, and that title to any goods passes
          to you when they are purchased at the applicable Retailer’s store. You
          agree that Instacart or the applicable Retailer will obtain an
          authorization for your credit card, debit card, or other payment
          method on file with Instacart to cover the cost of the goods you have
          purchased from the Retailer and any separate Instacart fees and
          optional tips, and your payment method will be charged for the goods
          purchased by you and any applicable fees, taxes and/or tips. Instacart
          may change the fees it charges for the Services, including but not
          limited to delivery fees, priority fees, service fees (including
          additional service fees and/or surcharges to help offset specific
          costs), alcohol service fees, heavy order fees, direct-to-consumer
          shipping fees, long distance fees (for deliveries outside the delivery
          area for a Retailer), and special handling fees. Instacart may vary
          certain fees based on demand, order attributes, and/or other factors.
          Your payment instrument will be temporarily authorized for an amount
          greater than the total amount of the purchase appearing in the
          original check out. This higher authorized amount will be disclosed
          during the purchase process and is a temporary authorization charge on
          your order, to deal with situations where your total purchase amount
          turns out to be higher than the original amount due to special
          requests, added items, replacement items, weight adjustments, or tips
          that you may elect to add after delivery. Retailers set the prices of
          the goods on the Services, and some Retailers may set prices for goods
          on the Services that differ from in-store prices, differ between
          storefronts, or differ from the prices available on other online
          platforms or services. The prices displayed on the Services may not be
          the lowest prices at which the same goods or items are sold.
          Individual Retailers may operate multiple storefronts with different
          pricing, selection, and order fulfillment. You can view each
          Retailer’s pricing policies (which may change from time to time) on
          their storefront(s) on both the website and in the Instacart app. You
          also acknowledge and agree that, except as expressly provided for
          otherwise in these Terms or a separate agreement between you and
          Instacart, Instacart does not form any employment or agency
          relationship with you and does not hold title to any goods that you
          order through the Services. Unless otherwise indicated, all prices and
          other amounts are in the currency of the jurisdiction where the
          delivery takes place. Occasionally there may be information on the
          Services that contains typographical errors, inaccuracies, or
          omissions that may relate to pricing, product descriptions,
          promotional offers, and product availability. Instacart reserves the
          right to correct any errors, inaccuracies or omissions and to change
          or update information or refuse or cancel orders if any information on
          the Services is inaccurate at any time without prior notice (including
          after you have submitted your order and/or your payment method has
          been charged). 1. Your Use of the Services Instacart grants you a
          limited, non-exclusive, non-transferable, and revocable license to use
          the Services for their intended purposes subject to your compliance
          with these Terms, Instacart Community Guidelines for Customers, and
          any other Instacart policies. You may not copy, modify, distribute,
          sell, or lease any part of the Services. Unless such restriction is
          prohibited by law or you have Instacart’s written permission, you may
          not reverse engineer or attempt to extract the source code of the
          Services. You may only access the Services through the interfaces that
          Instacart provides for that purpose (for example, you may not “scrape”
          the Services through automated means or “frame” any part of the
          Services), and you may not interfere or attempt to disrupt the
          Services. Some parts of the Services may allow you to upload or submit
          content (such as text, images, video, recipes, lists, links, and other
          materials). As between you and Instacart, you retain all rights in any
          content that you upload or submit, and are solely responsible for that
          content. By uploading content, you represent and warrant that you
          either own it or are authorized to grant Instacart the rights
          described in these Terms. You are responsible and liable if any of
          your content violates or infringes the intellectual property or
          privacy rights of any third party. You grant Instacart a
          non-exclusive, royalty-free, worldwide, transferable, sub licenseable,
          perpetual license to use, store, publicly display, publicly perform,
          reproduce, modify, create derivative works from, and distribute any
          such content for the purposes of operating, providing, and improving
          the Services. Instacart may, in its sole discretion, remove or take
          down any content that you upload or submit to the Services for any
          reason, including violation of these Terms, the Instacart Community
          Guidelines for Customers, or any other policies. You may have the
          option of accessing the Services through downloadable software and
          this software may update itself automatically on your device. Some
          software, or portions of software, in the Services may be governed by
          open source licenses. In that case, Instacart will make such licenses
          available to you and, in the case of conflict between such a license
          and these Terms, the open source license will control but only with
          respect to the software, or portion of the software, to which it
          applies. By using the Services, you represent and warrant that you are
          of legal age in the jurisdiction in which you reside to form a binding
          contract with Instacart. If you are using Instacart Services for its
          intended purposes on behalf of a business or other entity, you
          represent and warrant that you have the necessary authority to bind
          that business or entity to these Terms and that you are agreeing to
          these Terms on behalf of that business or entity. In order to use the
          Services, you may need to create a user account. You agree that you
          are responsible for all conduct and transactions that take place on or
          using your account and that you will take precautions to keep your
          password and other account information secure. You also agree that you
          will comply with all applicable laws when accessing or using the
          Services, you will adhere to the Instacart Community Guidelines for
          Customers (which may be updated from time to time) and any other
          applicable Instacart policies, and you will respect those who you
          encounter in your use of the Services, including Third Party
          Providers, Instacart personnel, and individuals who support
          Instacart’s Help Center. Instacart reserves the right to decline
          orders, refuse partial or full delivery, terminate or restrict access
          to accounts or Services, and/or cancel orders at any time in its sole
          discretion. Instacart may periodically offer you certain incentives or
          benefits (e.g. trial Instacart+ membership, credit, coupon, promotion
          code, and/or discount code). If Instacart suspects or determines, in
          its sole discretion, any fraud, tampering, abuse, or violation of our
          Terms of Service in connection with any such offer, Instacart reserves
          the right to withhold, revoke, cancel, and/or terminate the incentive
          or benefit and to take other remedial measures. We’re constantly
          modifying and improving the Services. Instacart may introduce new
          features, change existing features, or remove features from the
          Services at any time and without notice. If you provide Instacart with
          any feedback on or comments regarding the Services, you grant
          Instacart the right to use such feedback or comments for any purpose
          without restriction or payment to you. If you have any requests for
          order cancellations, refunds, or returns, please visit your account to
          initiate such requests or review our Help Center articles for our
          policies regarding the same. Please note that once a shopper has begun
          shopping your order and/or delivery has begun, you may no longer have
          the option to reschedule or cancel the order. If we are able to
          reschedule or cancel your order, you may be charged a fee and/or may
          not be refunded for items that have been purchased on your behalf. 2.
          Instacart Communications By creating an Instacart user account, you
          agree to accept and receive communications from Instacart or Third
          Party Providers, including via email, text message, calls, and push
          notifications to the cellular telephone number you provided to
          Instacart. You understand and agree that you may receive
          communications generated by automatic telephone dialing systems and/or
          which will deliver prerecorded messages sent by or on behalf of
          Instacart, its affiliated companies, and/or Third Party Providers,
          including but not limited to communications concerning orders placed
          through your account on the Services. Message and data rates may
          apply. If you do not wish to receive promotional emails, text
          messages, or other communications, you may opt out of such
          communications at any time in Your Account Settings or by using the
          unsubscribe mechanism included in the message, where applicable. You
          may also opt-out of receiving promotional text messages from Instacart
          by replying “STOP” from the mobile device receiving the messages. If
          you use the Services to order a prescription drug product (where
          available), you understand and agree that user inquiries must be
          conducted via telephone. You also understand and agree that a third
          party pharmacy and/or Instacart may send you unencrypted SMS messages,
          push notifications or other electronic notifications relating to
          prescription drug products that you order through the Services. There
          is some level of risk that information in such communications could be
          read by an unintended third party. By using the Services to order a
          prescription drug product, you explicitly disclaim any liability, and
          waive and release any claim (to the maximum extent permitted by law),
          against Instacart, its affiliated companies, and Third Party Providers
          for any harm or damage arising out of or in connection with any SMS
          messages, push notifications and/or other electronic notifications.
        </div>
      </div>
    </div>
  );
}

export default Terms;
