import React, { useState } from "react";

import NavbarComponent from "./NavbarComponent";
import { Grid, List } from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import UserMainDashboard from "../user/UserMainDashboard";
import { useLocation } from "react-router-dom";
import AllProducts from "./AllProducts";
import SubCategoriesComponent from "./SubCategoriesComponent";
import { data3 } from "../mockDataCategories";

function StoreCategoriesProduct() {
  const [activeTab, setActiveTabs] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const params = useLocation();
  const productData = params.state.ProductData;
  const storeData = params.state.StoreData;

  console.log(params, "myItemData");

  return (
    <div>
      <NavbarComponent storeData={storeData} />
      <Grid container spacing={0} marginTop={2} style={{ marginTop: 90 }}>
        <Grid
          item
          md={2}
          lg={2}
          sm={2}
          sx={12}
          style={{ borderRight: "1px solid grey" }}
        >
          <div style={Styles.sideBar}>
            <img style={Styles.image} src={storeData.image} />
            <spam style={{ fontWeight: "600" }}>{storeData.title}</spam>

            <div>
              <spam onClick={handleOpen} style={Styles.modalText}>
                Everyday store prices
                <ArrowForwardIosIcon style={{ fontSize: 12 }} />
              </spam>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Text in a modal
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor
                    ligula.
                  </Typography>
                </Box>
              </Modal>
            </div>
            <div>
              <spam
                onClick={handleOpen}
                style={{
                  fontWeight: "700",
                  color: "blue",
                  fontSize: 14,
                  cursor: "pointer",
                }}
              >
                100% satisfaction guarantee
                <ArrowForwardIosIcon style={{ fontSize: 12 }} />
              </spam>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Text in a modal
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor
                    ligula.
                  </Typography>
                </Box>
              </Modal>
            </div>
          </div>
          <div style={Styles.divider} />
          <div style={{ marginTop: 10 }}>
            {/* <UserMainDashboard
              activeTab={activeTab}
              setActiveTabs={setActiveTabs}
            /> */}
          </div>
          <div
            // className="scrollbar-hide"
            id="slider"
            style={Styles.scrollbar}
          >
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {data3.map((item) => {
                return <SubCategoriesComponent item={item} />;
              })}
            </List>
          </div>
        </Grid>
        <Grid item md={10} lg={10} sm={12} xs={12}>
          <Grid item>
            <AllProducts productData={productData} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default StoreCategoriesProduct;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Styles = {
  sideBar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 20,
    cursor: "pointer",
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  modalText: {
    fontWeight: "600",
    color: "gray",
    fontSize: 12,
    cursor: "pointer",
  },
  scrollbar: {
    width: "100%",
    display: "flex",
    overflowY: "scroll",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
    flexDirection: "column",
  },
  divider: { width: "100%", height: 1, backgroundColor: "lightgray" },
};
