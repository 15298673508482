import { useEffect, useState } from "react";
import axios from "../axios";
import {useDispatch, useSelector} from 'react-redux'
import { store } from "../features/slices/storeSlice";


const useCategoty = () => {
  const [catgry, setCatgry] = useState();
  
  
  // const dispatch = useDispatch();
  // const { data, isLoading, isSuccess } = useSelector((state) => state.store.data);
  

  //   console.log({data},"mydata")


  let aisle = [];
  let dt;

  // const ReduxData = async () => {
  //   await dispatch(store(data));
  // };

  // useEffect(() => {
  //   ReduxData()
  // }, [])
  

  const loadCategory = async () => {
    // let ctgry = [];
    // let dt = await axios.get("/api/v1/aisle")
    // // console.log(dt.data.aisle, "aisle")
    // let dta = dt.data.aisle
    // console.log(dta.length)
    // dta.forEach((doc) => {
    //   console.log(doc, "hello")
    // })

    dt = await axios.get("/api/v1/aisle");
    // console.log({dt});
    // dt?.data?.aisle.forEach(async (doc) => {
    dt?.data?.aisle?.map(async (doc) => {
      axios
        .get(`/api/v1/category/${doc?.label.toUpperCase()}`)
        .then((res) => {
        //   console.log(res.data.cat, "hello");
          // ctgry.push({ label: res.data.cat.label, value: res.data.cat.value, id: res.data.cat._id })
          aisle.push({ aisle: doc.label, value: res.data.cat, id: doc._id });
        //   console.log({aisle});
        })
        .catch((err) => {
          console.log(err);
        });
    });

    // console.log(aisle, "aisle");
    setCatgry(aisle);
  };

  // useEffect(() => {
  //   loadCategory();
  // }, [dt?.data?.aisle?.length]);

  return { catgry };
};
export default useCategoty;
