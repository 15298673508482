export const initialState = {
  cart: [],
  user: {},
  vendorId: {},
  err: false,
};

export const getCartTotal = (cart) =>
  cart
    ?.map((item) => Number(item?.price))
    ?.reduce((pre, curr) => pre + curr, 0);
//

const reducer = (state, action) => {
  // console.log(action, "myactionperform");
  switch (action.type) {
    case "ADD_TO_CART": {
      const item = action.item;
      const updateQty = state.qty;
      console.log(action.item);
      // console.log(updateQty, "updateQty 1");
      const differentVendor = state.cart.find(
        (x) => x.vendorId !== item.vendorId
      );
      if (differentVendor) {
        return {
          ...state,
          err: true,
        };
      }

      const existItem = state.cart.find((x) => x.id === item.id);
      if (existItem) {
        return { ...state, err: false };
      } else {
        localStorage.setItem(
          "cartItem",
          JSON.stringify({ cart: [...state.cart, action.item] }, null, 4)
        );

        return {
          ...state,
          cart: [...state.cart, action.item],
          err: false,
        };
      }
    }
    case "ERROR_FALSE": {
      return { ...state, err: false };
    }
    case "ADD_TO_CART_AGAIN": {
      const item = action.item;
      const updateQty = state.qty;
      console.log(action.item);

      const differentVendor = state.cart.find(
        (x) => x.vendorId !== item.vendorId
      );
      if (differentVendor) {
        return {
          ...state,
          err: true,
        };
      }
      const filterItem = state.cart.filter((x) => x.id !== item.id);

      localStorage.setItem(
        "cartItem",
        JSON.stringify({ cart: [...state.cart, action.item] }, null, 4)
      );

      return {
        ...state,
        cart: [...filterItem, action.item],
      };
    }
    case "REMOVE_FROM_CART": {
      localStorage.removeItem("cartItem");
      console.log(action.id, "action");
      localStorage.setItem(
        "cartItem",
        JSON.stringify(
          { cart: state.cart.filter((item) => item.id !== action.id) },
          null,
          4
        )
      );
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.id),
      };
    }
    case "INCREMENT_QTY": {
      const itemId = action.payload;
      const basePrice = Number(action.basePrice);
      console.log(basePrice, "basePrice");
      localStorage.removeItem("cartItem");
      localStorage.setItem(
        "cartItem",
        JSON.stringify(
          {
            cart: state?.cart?.map((curElem) => {
              const price = Number(curElem.price);
              console.log(price, "priceprice++");
              if (curElem?.id === itemId) {
                return {
                  ...curElem,
                  qty: Number(curElem.qty) + 1,
                  price: price + basePrice,
                };
              } else {
                return { ...curElem };
              }
            }),
          },
          null,
          4
        )
      );
      return {
        ...state,
        cart: state?.cart?.map((curElem) => {
          const price = Number(curElem.price);
          console.log(price, "priceprice++");
          if (curElem?.id === itemId) {
            return {
              ...curElem,
              qty: Number(curElem.qty) + 1,
              price: price + basePrice,
            };
          } else {
            return { ...curElem };
          }
        }),
      };
    }
    case "RESET_CART": {
      localStorage.removeItem("cartItem");
      return {
        cart: [],
      };
    }
    case "DECREMENT_QTY": {
      const itemId = action.payload;
      const basePrice = Number(action.basePrice);
      console.log(basePrice, "basePrice");
      localStorage.removeItem("cartItem");
      localStorage.setItem(
        "cartItem",
        JSON.stringify(
          {
            cart: state.cart.map((curElem) => {
              const price = Number(curElem.price);
              console.log(price, "priceprice--");
              if (curElem?.id === itemId) {
                return {
                  ...curElem,
                  qty: Number(curElem.qty - 1),
                  price: price - basePrice,
                };
              } else {
                return { ...curElem };
              }
            }),
          },
          null,
          4
        )
      );
      return {
        ...state,
        cart: state.cart.map((curElem) => {
          const price = Number(curElem.price);
          console.log(price, "priceprice--");
          if (curElem?.id === itemId) {
            return {
              ...curElem,
              qty: Number(curElem.qty - 1),
              price: price - basePrice,
            };
          } else {
            return { ...curElem };
          }
        }),
      };
    }
    case "OVER_WRITE": {
      let currentCart = action.item;
      console.log(currentCart, "currentCart");
      state.cart.filter((item) => item.id !== currentCart.id);
      return {
        ...state,
        cart: [...state.cart, currentCart],
      };
    }

    default:
      return state;
  }
};
export default reducer;

// case "increament":
//   return {
//     ...state,
//     cart: [(state.cart.price += state.cart.price)],
//   };
// case "decrement":
//   return { count: state.count - 1 };
// default:
//   return state;
// }
