import * as React from "react";
import Box from "@mui/material/Box";
// import Modal from "react-modal";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Colors from "./Colors";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { getCartTotal } from "../ContextApi/reducer";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Counter from "./Counter";
import "./Navbar.css";
import { useStateValue } from "../ContextApi/StateProvider";
import { useNavigate } from "react-router-dom";

import { IconButton, Input } from "@material-ui/core";
import Autocomplete from "react-google-autocomplete";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import SubCategoriesComponent from "./SubCategoriesComponent";
import Typography from "@mui/material/Typography";
import AuthContext from "./context";
import SignIn from "../screens/Authentication/SignIn/SignIn";
import SignInModal from "../screens/Authentication/SignIn/SignInModal";
import Location from "../components/Location";
import LocContext from "./Locationcontext";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import LogoutIcon from "@mui/icons-material/Logout";
import DashBoard from "./DashBoard";
import grabSingleLocation from "./config/GetSingleLocation";
import axios from "../axios";
import UserDeliveryTab from "../user/UserDeliveryTab";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function StoreFrontNavbar({
  storeData,
  anchorEl,
  mobileMoreAnchorEl,
  handleMenuClose,
  handleMobileMenuOpen,
  handleMobileMenuClose,
  product,
  handleSearch,
  onSubmit,
  textValue,
  handleDrawerToggle,
  onMobileUi,
}) {
  // console.log(storeData, "vendor")
  function commafy(num) {
    var str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }

    return str.join(".");
  }
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [{ cart, err }, dispatch] = useStateValue();
  const [vendorGet, setVendorGet] = useState();
  console.log(vendorGet, "vendorGet");
  const VendorCartId = cart[0]?.vendorId;
  console.log(VendorCartId, "VendorCartId");
  React.useEffect(() => {
    const fetchVendor = () => {
      axios
        .get(`/api/v1/vendor/${VendorCartId}`)
        .then((res) => setVendorGet(res.data.vendorId));
    };
    fetchVendor();
  }, [VendorCartId]);

  const [differentCartModal, setDifferentCartModal] = useState(err);
  // const different = cart.find((x) => x.id === _id.concat(storeId));
  const [openList, setOpenList] = React.useState(false);
  const userLocation = React.useContext(LocContext);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [dialogue, setDialogue] = useState(false);
  const [mapLocation, setMapLocation] = useState();
  const [currentLocation, setCurrentLocation] = useState();
  const authUser = React.useContext(AuthContext);
  const user = authUser?.user?.userName;
  console.log(err, "ye hy error");
  const userCreated = authUser?.user?.createdAt;
  const dateObj = new Date(userCreated);
  const monthName = dateObj.toLocaleString("default", { month: "long" });

  const year = dateObj.getUTCFullYear();
  // const monthName = toLocaleString(month);
  console.log(monthName, "month");
  console.log(year, "year");
  function openModal() {
    setIsOpen(true);
  }
  const [openM, setOpenM] = React.useState(false);
  const [openGuar, setOpenGuar] = React.useState(false);

  const handleOpenM = () => setOpenM(true);
  const handleCloseM = () => setOpenM(false);

  const modalOpen = () => setOpenGuar(true);
  const modalClose = () => setOpenGuar(false);
  function closeModal() {
    setIsOpen(false);
  }
  function handleClose() {
    setOpen(false);
  }
  function handleOpen() {
    setOpen(true);
  }
  const navigation = useNavigate();
  const TotalValue = getCartTotal(cart);

  const roundOfValue = parseFloat(TotalValue.toFixed(2));
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const removeFromBasket = (id) => {
    //remove
    dispatch({
      type: "REMOVE_FROM_CART",
      id: id,
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleLogin = ({ anchor }) => {
    if (user) {
      navigation("/UserCheckOut", {
        state: { storeData: vendorGet, totalPrice: roundOfValue },
      });
    } else {
      setOpen(true);
      setState(false);
    }
  };

  const logout = async () => {
    await signOut(auth);
    window.localStorage.removeItem("user");
    window.location.reload();
  };
  function success(pos) {
    console.log(pos);
    fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${pos.lat}&longitude=${pos.lng}&localityLanguage=en`
    )
      .then((res1) => res1.json())
      .then((res) => {
        console.log(res);

        // console.log(loc)
        let l = localStorage.getItem("location");
        console.log(l, "l hy ye ");
        if (l) {
          // console.log("Your current position is:");
          // console.log(`Latitude : ${crd.latitude}`);
          // console.log(`Longitude: ${crd.longitude}`);
          // console.log(`More or less ${crd.accuracy} meters.`);

          localStorage.removeItem("location");
          userLocation.setLoc("");
        }
        let data = {
          lng: res.longitude,
          lat: res.latitude,
          area: res.locality,
        };
        localStorage.setItem("location", JSON.stringify(data));
        userLocation.setLoc(JSON.stringify(data, null, 4));
        let k = localStorage.getItem("location");
        console.log(k);
        window.location.reload();
      });
  }
  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const getLocation = () => {
    // navigator.geolocation.getCurrentPosition((loc) => {

    // })
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            console.log(result.state);
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(checkVendorCurrent);
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            console.log(result.state);
          };
        });
    }
  };
  const handleLocation = (data) => {
    let l = localStorage.getItem("loaction");
    if (l) {
      localStorage.removeItem("location");
    }

    localStorage.setItem("location", JSON.stringify(data));
    userLocation.setLoc(JSON.stringify(data, null, 4));
    // console.log(data)
    window.location.reload(false);
    // closeModal();
  };
  const checkVendorCurrent = (crd) => {
    // userLocation?.loc, req.data.vendor
    // setMapLocation(data)
    let data = { lng: crd.coords.longitude, lat: crd.coords.latitude };
    let a = [];
    a.push(storeData.item);
    console.log(a);
    const isVendorAvailable = grabSingleLocation(data, a);
    console.log(isVendorAvailable);
    if (isVendorAvailable.length === 0) {
      setDialogue(true);
    } else {
      setDialogue(false);
      success(data);
    }
  };
  const checkVendor = (data) => {
    // userLocation?.loc, req.data.vendor
    setMapLocation(data);
    let a = [];
    a.push(storeData.item);
    console.log(a);
    const isVendorAvailable = grabSingleLocation(data, a);
    console.log(isVendorAvailable);
    if (isVendorAvailable.length === 0) {
      setDialogue(true);
    } else {
      setDialogue(false);
      handleLocation(data);
    }
  };
  const [activeTabData, setActiveTabData] = useState({});
  console.log(activeTabData.text, "activeTabData");
  const [activeTab, setActiveTabs] = useState(activeTabData.text);
  const listMainMenu = (anchor) => (
    <>
      <Box
        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 330 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {user && (
            <>
              <div style={{ marginRight: 30 }}>
                <div
                  style={{ marginBottom: 20, fontWeight: "700", fontSize: 19 }}
                >
                  {user}.
                </div>
                <div
                  style={{ fontSize: 13, fontWeight: "600", color: "#606163" }}
                >
                  {`Basimart customer since ${monthName} ${year}`}
                </div>
              </div>
            </>
          )}

          {/* <Avatar style={{ marginTop: -8 }} /> */}
        </div>
        <Divider style={{ marginBottom: 20 }} />
        <DashBoard
          activeTab={activeTab}
          setActiveTabs={setActiveTabs}
          setActiveTabData={setActiveTabData}
        />
        <Divider style={{ marginBottom: 10, marginTop: 20 }} />
        {user && (
          <>
            <div
              className="logOut"
              style={{
                cursor: "pointer",
                marginLeft: 10,
                display: "flex",
                alignItems: "center",
                width: "90%",
                borderRadius: 6,
                padding: 8,
                fontWeight: "600",
              }}
              onClick={logout}
            >
              <LogoutIcon style={{ marginRight: 10 }} /> Salir
            </div>
            <Divider style={{ marginTop: 10 }} />
          </>
        )}
      </Box>
    </>
  );
  const listCart = (anchor) => (
    <Box
      sx={{ width: onMobileUi ? 380 : 500 }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {cart?.length <= 0 ? (
        <div style={{ height: onMobileUi ? "80vh" : "100vh" }}>
          <div style={Styles.emptyPersonalCartHeader}>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer(anchor, false)}
            />
            <div style={Styles.personalTextContainer}>
              {/* <div style={Styles.storeName}>Personal Rainbow Grocery Cart</div> */}
              <div style={Styles.storeAddress}>
                Estas comprando en +{userLocation?.loc?.area}
              </div>
            </div>
            <div />
          </div>
          <div style={Styles.emptyPersonalCartBody}>
            <div style={Styles.shopNowContainer}>
              <img
                alt="emptyCart"
                style={{ width: 230, height: 150 }}
                src={require("../assets/emptycart.png")}
              />
              <div style={Styles.personalText1}>
                Su carrito personal está vacío{" "}
              </div>
              <div
                onClick={toggleDrawer(anchor, false)}
                style={Styles.personalText2}
              >
                Compra ahora
              </div>
            </div>
          </div>
          <div style={Styles.emptyCheckOut}>
            <div />
            <div>
              <div>Finalizar compra</div>
            </div>
            <div style={Styles.emptyPrice}>
              <div>₡0.00</div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: onMobileUi ? "80vh" : "100vh" }}>
          <div
            style={{
              height: "9vh",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
              width: "100%",
              position: "sticky",
              borderBottomWidth: 1,
            }}
          >
            <CloseIcon
              style={{ cursor: "pointer", marginLeft: 10 }}
              onClick={toggleDrawer(anchor, false)}
            />
            <div style={Styles.personalTextContainer}>
              <div style={Styles.storeName}>
                personal {vendorGet?.storeName} Carrito
              </div>
              <div style={Styles.storeAddress}>
                Estas comprando en +{userLocation?.loc?.area}
              </div>
            </div>
            <div />
          </div>
          <div
            style={{
              backgroundColor: "#F6F7F8",
              height: onMobileUi ? "70vh" : "77vh",
              overflowY: "scroll",
              scrollBehavior: "smooth",
              whiteSpace: "nowrap",
            }}
          >
            <div style={Styles.storeInfoContainer}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  {vendorGet?.image ? (
                    <img
                      style={{
                        width: 55,
                        height: 55,
                        marginTop: 5,
                        marginBottom: 5,
                        borderRadius: "50%",
                        borderWidth: 1,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                      src={vendorGet?.image}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: 55,
                        height: 55,
                        marginTop: 5,
                        marginBottom: 5,
                        borderWidth: 1,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    />
                  )}
                </div>
                <div style={{ marginLeft: 5 }}>
                  <div style={Styles.storeTitle}>{vendorGet?.storeName}</div>
                  <div style={Styles.storeDeliveryDate}>{storeData?.date}</div>
                  <div style={Styles.storeGuarantee}>
                    100% garantía de satisfacción{" "}
                  </div>
                </div>
              </div>
              <div>
                <div style={{ fontWeight: "700", fontSize: 14 }}>
                  {/* ₡{roundOfValue} */}₡{commafy(roundOfValue)}
                </div>
              </div>
            </div>
            {/* <div style={Styles.firstDeliveryContainer}>
              <div style={Styles.firstDeliveryText}>
                Your first delivery is free!
              </div>
            </div> */}
            {cart.map((item) => {
              return (
                <div style={Styles.product}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div>
                      <img
                        alt="productImg"
                        style={Styles.productImg}
                        src={item?.images}
                      />
                    </div>
                    <div
                      style={{
                        marginLeft: 5,
                      }}
                    >
                      <div style={Styles.productTitle}>{item?.name}</div>
                      <div
                        style={{
                          fontSize: 12,
                          // fontWeight: "600",
                          color: "#AAACB1",
                        }}
                      >
                        {item?.size}
                      </div>

                      <div style={Styles.productDeliverDate}>
                        {item?.createdAt}
                      </div>

                      <div
                        onClick={() => removeFromBasket(item?.id)}
                        style={Styles.productRemove}
                      >
                        <DeleteIcon style={{ fontSize: 17, paddingTop: 1 }} />
                        <div
                          style={{
                            color: "rgb(114, 118, 126",
                            fontWeight: "600",
                            fontSize: 12,
                          }}
                        >
                          Eliminar
                        </div>
                      </div>
                      <Counter
                        counterStyle={{
                          display: "flex",
                          backgroundColor: "#52AE11",
                          justifyContent: "space-between",
                          borderRadius: 18,
                          padding: 4,
                          color: "white",
                          width: 120,
                        }}
                        id={item?.id}
                        price={item?.price}
                        date={item?.date}
                        img={item?.images}
                        title={item?.name}
                        qty={item?.qty}
                        stock={item?.stock}
                        basePrice={item?.basePrice}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: 70,
                        display: "flex",
                        flexDirection: "row-reverse",
                        fontSize: 12,
                        marginRight: 3,
                      }}
                    >
                      {/* ₡{commafy(item?.price)} */}
                      {commafy(parseFloat(item?.price).toFixed(2))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            onClick={() => handleLogin(anchor, roundOfValue)}
            style={Styles.personalCartFooter}
          >
            <div />
            <div>
              <div
                style={{
                  fontSize: 18,
                  color: "white",
                  fontWeight: "600",
                  marginRight: -30,
                }}
              >
                Finalizar compra{" "}
              </div>
            </div>
            <div style={Styles.cartTotalPrice}>
              {/* ₡{roundOfValue} */}₡{commafy(roundOfValue)}
            </div>
          </div>
        </div>
      )}
    </Box>
  );

  //
  const customStyles = {
    content: {
      top: "55%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
      height: "80%",
    },
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    ></Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {!user && (
        <MenuItem>
          <div style={Styles.mobileLoginText}>Entrar</div>
        </MenuItem>
      )}
      <MenuItem>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* icon place  */}
            <div onClick={toggleDrawer(anchor, true)}>
              <div
                style={{
                  backgroundColor:
                    cart?.length > 0 ? "lightgreen" : "lightgray",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: 24,
                  paddingTop: 8,
                  paddingBottom: 8,
                  paddingLeft: 10,
                  paddingRight: 10,
                  cursor: "pointer",
                }}
              >
                <ShoppingCartIcon style={{ color: "#343538" }} />
                <div style={{ color: "black" }}>{cart?.length}</div>
              </div>
            </div>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {listCart(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </MenuItem>
    </Menu>
  );
  //
  //

  //
  //

  //

  //

  //

  //

  //

  //
  const [navMobile, setNavMobile] = useState(false);

  useEffect(() => {
    const changeNavbar = () => {
      if (window.scrollY > 150) {
        setNavMobile(true);
      } else {
        setNavMobile(false);
      }
    };
    window.addEventListener("scroll", changeNavbar);
    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  }, []);

  return (
    <>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12 }}
          style={Styles.gridContainer}
        >
          <Grid style={Styles.menuContainer} item md={0.4} xs={1} xl={2}>
            {["left"].map((anchor) => (
              <React.Fragment key={anchor}>
                {/* icon place  */}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <MenuIcon style={{ color: "black" }} />
                </div>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {listMainMenu(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Grid>

          <Grid style={Styles.imgContainer} item md={1.4} xs={2.5} xl={2}>
            <img
              onClick={() => {
                navigation("/");
              }}
              alt="basimart"
              style={{ margin: -30, height: 90 }}
              src={require("../assets/basi.png")}
            />
          </Grid>
          <Grid item md={0.7} xs={2.5} xl={2}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => navigation("/")}
            >
              <ArrowBackIosIcon
                style={{
                  fontSize: 12,
                  paddingTop: 1,
                  color: "rgb(114, 118, 126)",
                  cursor: "pointer",
                }}
              />
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "600",
                  cursor: "pointer",
                  color: "rgb(114, 118, 126)",
                }}
              >
                Ver tiendas{" "}
              </div>
            </div>
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F1F1F1",
              borderRadius: 10,
            }}
            item
            md={6}
            xs={4}
            xl={4}
          >
            <input
              className="input"
              type="text"
              id="inputID"
              onChange={handleSearch}
              value={textValue}
              style={Styles.inputText2}
              placeholder="Encuentra todos los productos del super"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  onSubmit();
                }
              }}
            />
            <SearchIcon style={{ paddingRight: 5 }} />
          </Grid>
          <Grid style={Styles.addressContainer} item md={2.5} xs={3} xl={2}>
            <div
              style={{ display: "flex", cursor: "pointer", paddingLeft: 20 }}
              onClick={openModal}
            >
              <div>
                <LocationOnIcon style={Styles.webcolorWhite} />
              </div>
              <div style={{ color: "black", fontWeight: "600" }}>
                {userLocation?.loc?.area}
              </div>
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              center
              styles={{
                modal: Styles.customModalSignIn,
              }}
            >
              <SignInModal setOpen={setOpen} />
            </Modal>
          </Grid>
          <Grid style={{ display: "flex" }} md={0.5} xs={1} item>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {!user && (
                <div onClick={() => setOpen(true)} style={Styles.webLoginText}>
                  Entrar
                </div>
              )}
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* icon place  */}
                  <div onClick={toggleDrawer(anchor, true)}>
                    <div
                      style={{
                        backgroundColor:
                          cart?.length > 0 ? "lightgreen" : "#F6F7F8",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: 24,
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 20,
                        paddingRight: 20,
                        cursor: "pointer",
                      }}
                    >
                      <ShoppingCartIcon style={{ color: "black" }} />
                      <div style={{ color: "black" }}>{cart?.length}</div>
                    </div>
                  </div>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {listCart(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <div
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </div>
            </Box>
            {renderMobileMenu}
            {renderMenu}
          </Grid>
        </Grid>
      </Box>
      {navMobile && (
        <Box sx={{ display: { xs: "flex", md: "none" }, marginBottom: 20 }}>
          <Grid
            style={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: 0,
              backgroundColor: "white",
              zIndex: 1,
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 5,
              paddingBottom: 5,
            }}
            container
            xs={12}
          >
            <Grid style={Styles.menuContainer} item xs={1}>
              {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* icon place  */}
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <MenuIcon style={{ color: "black" }} />
                  </div>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {listMainMenu(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </Grid>
            <Grid item xs={8}>
              <div
                style={{
                  backgroundColor: "#F1F1F1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 10,
                }}
              >
                <input
                  className="input"
                  type="text"
                  id="inputID"
                  onChange={handleSearch}
                  value={textValue}
                  style={Styles.inputText2}
                  placeholder="Encuentra todos los productos del super"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      onSubmit();
                    }
                  }}
                />
                <SearchIcon style={{}} />
              </div>
            </Grid>

            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              item
              xs={2}
            >
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  {/* icon place  */}
                  <div onClick={toggleDrawer(anchor, true)}>
                    <div
                      style={{
                        backgroundColor:
                          cart?.length > 0 ? "lightgreen" : "#EFF0F1",
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: 24,
                        paddingTop: 6,
                        paddingBottom: 6,
                        paddingLeft: 15,
                        paddingRight: 15,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ShoppingCartIcon
                        style={{
                          color: "#343538",
                          fontSize: 16,
                        }}
                      />
                      <div style={{ color: "black", fontSize: 13 }}>
                        {cart?.length}
                      </div>
                    </div>
                  </div>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {listCart(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Box>
      )}
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Modal
          open={modalIsOpen}
          onClose={closeModal}
          center
          styles={{
            overlay: Styles.customOverlay,
            modal: Styles.customModal,
          }}
        >
          <div handleTab={(e) => handleLocation(e)}></div>
        </Modal>
        <Modal
          open={err}
          onClose={() => {
            dispatch({
              type: "ERROR_FALSE",
            });
          }}
          center
          styles={{
            overlay: Styles.customOverlay,
            modal: Styles.customModal,
          }}
        >
          <div style={{ padding: 0, margin: 0, justifyContent: "center" }}>
            <div
              style={{
                width: "350px",
                color: Colors.buttonGreen,
                textAlign: "center",
                fontSize: 20,
                borderBottom: `1px solid ${Colors.buttonGreen}`,
              }}
            >
              Oops…
            </div>
            <div
              style={{
                width: "350px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Typography
                variant="h5"
                style={{ textAlign: "center", padding: 10 }}
              >
                Completa el pedido de los artículos ya presentes en tu carrito
                antes de realizar un pedido en otra Tienda
              </Typography>
            </div>
          </div>
        </Modal>
        <Grid
          style={{ display: "flex", justifyContent: "space-between" }}
          container
          xs={12}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={4}
          >
            <IconButton
              style={{}}
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={2}
          >
            <img
              style={{ width: 50, height: 50, borderRadius: "50%" }}
              src={storeData?.item?.image}
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 15,
            }}
            item
            xs={4}
          >
            {!user && (
              <div onClick={() => setOpen(true)} style={Styles.mobileLoginText}>
                Entrar
              </div>
            )}
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                {/* icon place  */}
                <div onClick={toggleDrawer(anchor, true)}>
                  <div
                    style={{
                      backgroundColor:
                        cart?.length > 0 ? "lightgreen" : "#EFF0F1",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: 24,
                      paddingTop: 6,
                      paddingBottom: 6,
                      paddingLeft: 15,
                      paddingRight: 15,
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: 10,
                    }}
                  >
                    <ShoppingCartIcon
                      style={{
                        color: "#343538",
                        fontSize: 16,
                      }}
                    />
                    <div style={{ color: "black", fontSize: 13 }}>
                      {cart?.length}
                    </div>
                  </div>
                </div>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {listCart(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Grid>

          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={12}
          >
            <div style={{ fontSize: 13 }} onClick={handleOpenM}>
              Tiempos de entrega{" "}
            </div>
          </Grid>

          <Modal
            open={openM}
            onClose={handleCloseM}
            center
            styles={{
              overlay: Styles.customOverlay,
              modal: Styles.customModal,
            }}
          >
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 10,
                }}
              >
                <div
                  style={{
                    fontSize: 20,
                    fontWeight: "600",
                    color: "#53B02C",
                    paddingBottom: 10,
                  }}
                >
                  Tiempos de entrega
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={storeData?.item?.image}
                  style={{ width: 80, height: 80, borderRadius: "50%" }}
                />
                <div>{storeData?.item?.storeName}</div>
              </div>
              <div style={{ width: 310 }}>
                <UserDeliveryTab data={storeData?.item} widthMax={true} />
              </div>
            </div>
          </Modal>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={12}
          >
            <spam
              onClick={modalOpen}
              style={{
                fontWeight: "700",
                color: "blue",
                fontSize: 13,
                cursor: "pointer",
                marginBottom: 10,
              }}
            >
              100% garantía de satisfacción{" "}
              <ArrowForwardIosIcon style={{ fontSize: 12 }} />
            </spam>
          </Grid>
          <Modal
            open={openGuar}
            onClose={modalClose}
            center
            styles={{
              overlay: Styles.customOverlay,
              modal: Styles.customModal,
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    width: 80,
                    height: 80,
                    padding: 10,
                  }}
                  src={require("../assets/guaranteeIcon.png")}
                />
                <div
                  style={{
                    fontWeight: "700",
                    fontSize: 18,
                  }}
                >
                  100% garantía de satisfacción{" "}
                </div>
              </div>
              <div style={{ padding: 10, color: "#343538" }}>
                Haz tu pedido con tranquilidad. Si experimentas alguno de los
                siguientes problemas, eres elegible para un reembolso o crédito:
              </div>
            </div>
            <div style={{ padding: 20 }}>
              <ol style={{ listStyleType: "inherit" }}>
                <li style={{ marginLeft: 20, color: "#343538" }}>
                  Daño o producto faltante
                </li>
                <li style={{ marginLeft: 20, color: "#343538" }}>
                  Reemplazos deficientes de artículos
                </li>
                <li style={{ marginLeft: 20, color: "#343538" }}>
                  Llegada tardía
                </li>
                <li style={{ marginLeft: 20, color: "#343538" }}>
                  Servicio no profesional
                </li>
              </ol>
            </div>
            <div
              onClick={modalClose}
              style={{
                backgroundColor: "#63CE46",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 15,
                margin: 10,
                borderRadius: 10,
                cursor: "pointer",
              }}
            >
              Entendido{" "}
            </div>
          </Modal>
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "#F1F1F1",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <input
                className="input"
                type="text"
                id="inputID"
                onChange={handleSearch}
                value={textValue}
                style={Styles.inputText2}
                placeholder="Encuentra todos los productos del super"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onSubmit();
                  }
                }}
              />
              <SearchIcon style={{}} />
            </div>
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 10,
            }}
            item
            xs={12}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 10,
                marginTop: 15,
              }}
              onClick={openModal}
            >
              <div>
                <LocationOnIcon style={Styles.colorWhite} />
              </div>
              <div style={{ color: "black", fontSize: 14 }}>
                {userLocation?.loc?.area}
              </div>
              <ArrowForwardIosIcon
                style={{ fontSize: 12, color: "gray", marginTop: 2 }}
              />
            </div>
            <Modal
              // isOpen={modalIsOpen}
              // onRequestClose={closeModal}
              open={modalIsOpen}
              onClose={closeModal}
              center
              styles={{
                overlay: Styles.customOverlay,
                modal: Styles.customModal,
              }}
            >
              {dialogue && (
                <div style={{ paddingTop: 20, marginBottom: -20 }}>
                  <p>{`${storeData.item.storeName} is not available near this address`}</p>
                </div>
              )}
              <div style={{ paddingBottom: 40 }}>
                <Location
                  handleTab={(e) => checkVendor(e)}
                  getLocation={getLocation}
                />
              </div>
            </Modal>
          </Grid>
          <Grid
            xs={12}
            style={{
              width: "100",
              backgroundColor: "lightgray",
              height: 0.5,
            }}
          />
        </Grid>
      </Box>
    </>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

const Styles = {
  mobileLoginText: {
    display: "flex",
    backgroundColor: "#52AE11",
    borderRadius: 24,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 13,
    cursor: "pointer",
    color: "white",
    fontWeight: "600",
  },
  mobileCartContainer: {
    backgroundColor: Colors.lightgray,
    display: "flex",
    justifyContent: "center",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },
  gridContainer: {
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "white",
    top: 0,
    position: "fixed",
    zIndex: 100,
    borderBottomWidth: 1,
  },
  menuContainer: {
    display: "flex",
    justifyContent: "center",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
  },
  inputText: {
    width: "100%",
    height: 45,
    borderRadius: 5,
    fontSize: 13,
    fontWeight: "600",
    paddingInlineStart: 10,
  },
  inputText2: {
    height: 45,
    borderRadius: 10,
    backgroundColor: "#F1F1F1",
    fontSize: 13,
    fontWeight: "600",
    paddingInlineStart: 10,
    width: "100%",
  },
  addressContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  colorWhite: { color: "black", fontSize: 17, marginTop: -2 },
  webcolorWhite: { color: "black", fontSize: 20, marginTop: -5 },

  webCartContainer: {
    backgroundColor: Colors.lightgray,
    display: "flex",
    justifyContent: "center",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
  },

  webLoginText: {
    display: "flex",
    backgroundColor: "#52AE11",
    borderRadius: 24,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 10,
    cursor: "pointer",
    color: "white",
    marginLeft: -100,
    position: "absolute",
  },
  emptyPersonalCartBody: {
    backgroundColor: "#F6F7F8",
    height: "78vh",
    display: "flex",
    justifyContent: "center",
  },
  personalTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  emptyPersonalCartHeader: {
    height: "11vh",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  personalText1: { color: "#72767E", fontSize: 18, fontWeight: "600" },
  personalText2: {
    color: "#58B42F",
    fontSize: 18,
    fontWeight: "600",
    cursor: "pointer",
  },
  emptyCheckOut: {
    height: "9vh",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F6F7F8",
    margin: 5,
    alignItems: "center",
    borderRadius: 10,
  },
  emptyPrice: {
    backgroundColor: "lightgray ",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
  },

  personalCartHeader: {
    height: "10vh",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    width: "100%",
    position: "sticky",
    borderBottomWidth: 1,
  },
  personalCartBody: {
    backgroundColor: "#F6F7F8",
    height: "77vh",
    overflowY: "scroll",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
  },
  personalCartFooter: {
    height: "9vh",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#52AE11",
    margin: 5,
    alignItems: "center",
    borderRadius: 10,
    cursor: "pointer",
    position: "absolute",
    width: "96%",
    // bottom: 0,
  },
  storeName: {
    fontSize: 15,
    fontWeight: "600",
    color: "black",
    marginLeft: -35,
  },
  storeAddress: {
    fontSize: 12,
    fontWeight: "600",
    color: "gray",
    marginLeft: -35,
  },
  shopNowContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 50,
  },
  cartTotalPrice: {
    backgroundColor: "#418B24",
    color: "white",
    fontWeight: "600",
    borderRadius: 10,
    padding: 8,
    marginRight: 5,
  },
  storeInfoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 10,
    backgroundColor: "white",
    padding: 5,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    marginBottom: 10,
  },
  storeImg: { width: 70, height: 70, borderRadius: 50 },
  storeTitle: { fontWeight: "600", color: "black", fontSize: 14 },
  storeDeliveryDate: {
    color: "rgb(10, 173, 10)",
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 2,
  },
  storeGuarantee: {
    fontWeight: "600",
    color: "rgb(43, 120, 198)",
    fontSize: 12,

    cursor: "pointer",
    marginLeft: 2,
  },
  firstDeliveryContainer: {
    display: "flex",
    position: "relative",
    backgroundColor: "rgb(250, 241, 229)",
    marginTop: 10,
  },
  firstDeliveryText: {
    fontSize: 15,
    fontWeight: "600",
    color: " rgb(117, 0, 70)",
    padding: 8,
  },
  product: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    padding: 5,
  },
  productImg: { width: 80, height: 55 },
  productTitle: {
    color: "rgb(52, 53, 56)",
    width: 230,
    whiteSpace: "pre-line",
    fontSize: 14,
  },
  productDeliverDate: {
    color: "rgb(10, 173, 10)",
    fontSize: 12,
    fontWeight: "600",
    marginLeft: 2,
  },
  productRemove: {
    cursor: "pointer",
    marginLeft: 2,
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  customOverlay: {
    // background: "rgba(36, 123, 160, 0.7)"
    borderRadius: 10,
  },
  customModal: {
    borderRadius: 10,
    width: 340,
  },
  customModalSignIn: {
    borderRadius: 10,
    minWidth: "30%",
  },
};
