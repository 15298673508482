import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, IconButton, Typography } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { ArrowBackTwoTone } from "@material-ui/icons";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import TableRow from "@mui/material/TableRow";
import toast, { Toaster } from "react-hot-toast";

import { useNavigate } from "react-router-dom";

import axios from "../axios";
import moment from "moment/moment";
import io from "socket.io-client";

const UserOrderDetails = () => {
  const location = useLocation();
  const params = location?.state?.row;
  console.log(params, "dattatatat");
  const [order, setOrder] = useState();

  const [img, setImg] = useState(null);
  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        setImg(event.target.files[0]);
      };
    }
  };

  const [file, setFile] = useState(null);
  console.log(file, "filefile");

  const notify = (e) => toast(e);

  const handleUpdate = async () => {
    await axios
      .put(
        `/api/v1/orders/paymentProof/${params?._id}`,
        {
          pop: img,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )

      .then((res) => console.log(res.data.paymentImg, "paymentImg"));
    notify("Image uploaded successfully");
  };
  useEffect(() => {
    // socket.on("connect", () => {
    //   const transport = socket.io.engine.transport.name; // in most cases, "polling"

    //   socket.io.engine.on("upgrade", () => {
    //     const upgradedTransport = socket.io.engine.transport.name; // in most cases, "websocket"
    //   });
    // });
    async function getOrder() {
      axios
        .get(`/api/v1/allOrdersDetail/${params._id}`)
        .then((res) => {
          setOrder(res.data.allOrdersDetail);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const socket = io(
      "https://orca-app-2hy8c.ondigitalocean.app/api/socket",
      {
        transports: ["websocket"],
      }
    ).connect();
    socket.on("newThought", (thought) => {
      console.log(thought, "hello");
      getOrder();
    });

    getOrder();
  }, []);
  Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
  };
  const navigation = useNavigate();
  function commafy(num) {
    var str = num.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 4) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
  }
  return (
    <>
      <Toaster />
      <Grid
        container
        lg={12}
        md={12}
        xs={12}
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 40,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <h1 style={{ fontWeight: "bold", fontSize: 20 }}>
            Detalles del pedido
          </h1>{" "}
        </Grid>
        <Grid>
          <ArrowBackTwoTone
            style={{
              height: 30,
              width: 30,
              color: "black",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            item
            onClick={() => navigation("/UserAllOrders")}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          lg={10}
          md={12}
          sm={12}
          sx={12}
          style={{
            width: "100%",
            padding: 20,
          }}
        >
          {order && (
            <Grid container>
              <Grid style={{ padding: "15px" }} lg={6} md={6} sm={12} sx={12}>
                <Grid container style={styles.card}>
                  <Grid lg={6} md={6} sm={6} sx={12}>
                    <h4 style={{ margin: 0, padding: "10px" }}>
                      Order summary
                    </h4>
                    <Grid container>
                      <Grid container>
                        <Grid lg={4} md={4} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Ship by:</p>
                        </Grid>
                        <Grid lg={8} md={8} sm={12} sx={12}>
                          <p style={styles.hightLightTxt}>
                            {moment(
                              new Date(order.purchaseDate).addHours(
                                order?.deliveryTime?.deliveryTime
                              )
                            ).format("DD-MM-YYYY, HH:mm:ss A")}
                          </p>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid lg={4} md={4} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Fecha de compra:</p>
                        </Grid>
                        <Grid lg={8} md={8} sm={12} sx={12}>
                          <p style={styles.valueTxt}>
                            {moment(order.purchaseDate).format(
                              "DD-MM-YYYY, HH:mm:ss A"
                            )}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid lg={6} md={6} sm={6} sx={12}>
                    <Grid container>
                      <Grid container>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Depósito:</p>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.valueTxt}>Basimart</p>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Canal de ventas:</p>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.valueTxt}>Basitmart.com</p>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Estado del pedido :</p>
                        </Grid>
                        <Grid lg={6} md={6} sm={12} sx={12}>
                          <p style={styles.valueTxt}>{order?.status}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                style={{ padding: "15px", width: "100%" }}
                lg={6}
                md={6}
                sm={12}
                sx={12}
              >
                <Grid container style={styles.card}>
                  <Grid lg={12} md={12} sm={12} sx={12}>
                    <h4 style={{ margin: 0, padding: "10px" }}>Ship to</h4>
                    <Grid container>
                      <Grid container>
                        <Grid lg={12} md={12} sm={12} sx={12}>
                          <p style={styles.titleTxt}>{order?.shipTo}</p>
                        </Grid>
                        <Grid lg={4} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Nombre del Usuario :</p>
                        </Grid>
                        <Grid lg={4} md={6} sm={12} sx={12}>
                          <p style={styles.buyerName}>{order?.buyerName}</p>
                        </Grid>
                        <Grid lg={4} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Dirección:</p>
                        </Grid>
                        <Grid lg={4} md={6} sm={12} sx={12}>
                          <p style={styles.buyerName}>
                            {order?.deliveryAddress?.address}
                          </p>
                        </Grid>
                        <Grid lg={4} md={6} sm={12} sx={12}>
                          <p style={styles.titleTxt}>Dirección Opcional:</p>
                        </Grid>
                        <Grid lg={4} md={6} sm={12} sx={12}>
                          <p style={styles.buyerName}>
                            {order?.deliveryAddress?.addressOptional}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <div>
            <>
              <>
                {order?.pop ? (
                  <h2
                    style={{
                      fontSize: "24px",
                      fontWeight: 700,
                      color: "#000",
                      padding: "10px",
                      letterSpacing: "1px",
                    }}
                  >
                    Detalles de verificación de pago
                  </h2>
                ) : (
                  <h2
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      color: "#000",
                      padding: "10px",
                      letterSpacing: "1px",
                    }}
                  >
                    Subir comprobante de pago
                  </h2>
                )}
              </>
              <Grid
                container
                md={8}
                sm={8}
                xs={8}
                xl={8}
                lg={8}
                justifyContent={"space-between"}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid #ccc",
                  borderRadius: "6px",
                  padding: 15,
                }}
              >
                {order?.paymentType?.type !== "Cash on Delivery" && (
                  <Grid item md={4} sm={4} xs={12} xl={4} lg={4}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: 23,
                      }}
                    >
                      {order?.pop && (
                        <img
                          src={order?.pop}
                          style={{
                            display: "flex",
                            width: 100,
                            height: 100,
                            alignSelf: "center",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            justifySelf: "center",
                            border: "1px solid #ccc",
                          }}
                        />
                      )}

                      <input
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleChangeImage}
                        id="contained-button-file"
                      />
                      <label
                        style={{ alignSelf: "center" }}
                        htmlFor="contained-button-file"
                      >
                        <IconButton
                          style={{ backgroundColor: "lightgray" }}
                          component="span"
                        >
                          <AddPhotoAlternateIcon />
                        </IconButton>
                      </label>
                    </div>
                    <button
                      onClick={handleUpdate}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#2D634C",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 10,
                        padding: 10,
                        color: "white",
                        borderWidth: 0,
                        marginTop: 30,
                      }}
                    >
                      Enviar
                    </button>
                  </Grid>
                )}
                <Grid item md={4} sm={4} xs={12} xl={4} lg={4}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "1px solid #ccc",
                      borderRadius: "6px",
                      padding: 15,
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={{ fontFamily: "sans-serif", fontWeight: "bold" }}
                    >
                      {" "}
                      Cómo transferir el pago!
                    </Typography>
                    {order?.paymentType?.type === "Sinpe Movil" && (
                      <p style={{ fontSize: 14 }}>
                        Envía el monto de la compra al número ####-#### y luego
                        adjunta una imagen con el comprobante de pago en el
                        campo abajo
                      </p>
                    )}
                    {order?.paymentType?.type === "Bank Transfer" && (
                      <p style={{ fontSize: 14 }}>
                        Envía una transferencia del monto de la compra y luego
                        adjunta el comprobante en el campo abajo. Cuentas de
                        Banco: Cuenta de Banco A XXXXXXXXXXXXX Cuenta de Banco B
                        XXXXXXXXXXXXX Cuenta de Banco C XXXXXXXXXXXXX
                      </p>
                    )}
                    {order?.paymentType?.type === "Cash on Delivery" && (
                      <p style={{ fontSize: 14 }}>
                        Podrás realizar el pago al momento de la entrega,
                        recuerda que puedes hacer el pago con efectivo, Sinpe
                        móvil o transferencia bancaria. Muy pronto lo podrás
                        realizar con tarjeta de crédito/débito.
                      </p>
                    )}
                  </div>
                </Grid>
              </Grid>
            </>

            <div style={{ width: "95%", marginLeft: 10 }}>
              <h2
                style={{
                  fontSize: "24px",
                  fontWeight: 700,
                  color: "#000",
                  padding: "10px",
                  letterSpacing: "1px",
                }}
              >
                productos
              </h2>
              <TableContainer
                style={{ background: "#F6F9FC", padding: "15px" }}
              >
                <Table
                  style={{
                    borderSpacing: "0 15px",
                    borderCollapse: "separate",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={Styles.tableHeading}>
                        {"Imagen"}
                      </TableCell>
                      <TableCell style={Styles.tableHeading}>
                        {"Nombre del producto"}
                      </TableCell>
                      <TableCell style={Styles.tableHeading}>
                        {"Descripción"}
                      </TableCell>
                      <TableCell style={Styles.tableHeading}>
                        {"Cantidad"}
                      </TableCell>

                      <TableCell style={Styles.tableHeading}>
                        {"Tamaño"}
                      </TableCell>
                      <TableCell style={Styles.tableHeading}>
                        {"Precio unitario"}
                      </TableCell>
                      <TableCell style={Styles.tableHeading}>
                        {"Precio total"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {params?.productDetails?.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          style={{
                            background: "#fff",
                            margin: "15px 0",
                            cursor: "pointer",
                          }}
                        >
                          <TableCell style={{ borderRadius: "10px 0 0 10px" }}>
                            <div>
                              <img
                                style={{ width: 30, height: 30 }}
                                src={row.images}
                              />
                            </div>
                          </TableCell>
                          <TableCell style={{ color: "#444", width: "400px" }}>
                            {row.name}
                          </TableCell>
                          <TableCell style={{ color: "#444", width: "300px" }}>
                            {row.description}
                          </TableCell>
                          <TableCell>
                            <div style={{ color: "#444" }}>{row.qty}</div>
                          </TableCell>
                          <TableCell>
                            <div style={{ color: "#444" }}>{row.size}</div>
                          </TableCell>
                          <TableCell>
                            <div style={{ color: "#444" }}>
                              ₡{commafy(row.basePrice)}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div style={{ color: "#444" }}>
                              ₡{commafy(row.price)}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Grid>

        {/* Right sidebar */}
      </Grid>
    </>
  );
};

export default UserOrderDetails;
const styles = {
  card: {
    border: "1px solid #ccc",
    borderRadius: "6px",
    padding: 15,
  },
  titleTxt: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#888",
    padding: "10px",
    margin: 0,
  },
  valueTxt: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#222",
    margin: 0,
    padding: "10px",
  },
  hightLightTxt: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#AD6D49",
    padding: "10px",
    margin: 0,
  },
  buyerName: {
    color: "#307682",
    fontSize: 12,
    fontWeight: "bold",
    padding: "10px",
    margin: 0,
  },
};

const Styles = {
  tableHeading: { color: "#7D879C", fontSize: "16px", fontWeight: 600 },
};
