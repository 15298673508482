import { React, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useStateValue } from "../ContextApi/StateProvider";
import RemoveIcon from "@mui/icons-material/Remove";
import Colors from "./Colors";
export default function Counter({
  id,
  title,
  img,
  date,
  price,
  qty,
  stock,
  basePrice,
  counterStyle,
  mobileProductUi,
}) {
  const [counter, setCounter] = useState(1);
  console.log(qty, "qtyqty");
  const [{ cart }, dispatch] = useStateValue();
  const removeFromBasket = (id) => {
    //remove
    dispatch({
      type: "REMOVE_FROM_CART",
      id: id,
    });
  };
  const IncreaseQty = (id) => {
    // setCounter((count) => count + 1);
    if (qty < stock) {
      dispatch({
        type: "INCREMENT_QTY",
        payload: id,
        basePrice: basePrice,
      });
    }
  };
  const DecreaseQty = (id) => {
    console.log(id, "myDecreasId");
    // setCounter((count) => count + 1);
    if (qty >= 2) {
      dispatch({
        type: "DECREMENT_QTY",
        payload: id,
        basePrice: basePrice,
      });
    } else {
      removeFromBasket(id);
    }
  };

  const handleChange = (id) => {
    if (counter >= 2) {
      setCounter((count) => count - 1);
    } else {
      removeFromBasket(id);
    }
  };

  return (
    <div style={counterStyle}>
      <div
        style={{ color: mobileProductUi ? "black" : "white" }}
        onClick={() => DecreaseQty(id)}
      >
        <RemoveIcon />
      </div>
      <span style={{ color: mobileProductUi ? "black" : "white" }}>
        {Number(qty)}
      </span>
      <div
        style={{ color: mobileProductUi ? "black" : "white" }}
        onClick={() => IncreaseQty(id)}
      >
        <AddIcon />
      </div>
    </div>
  );
}
