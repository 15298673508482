import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import BoltIcon from "@mui/icons-material/Bolt";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

const UserDeliveryTab = (props) => {
  // console.log(props?.data?.adminDeliveryType?.deliveryPricePriority);
  const priorityPrice = Number(
    props?.data?.adminDeliveryType?.deliveryPricePriority
  );
  const standardPrice = Number(
    props?.data?.adminDeliveryType?.deliveryPriceStandard
  );
  const fasterPrice = Number(
    props?.data?.adminDeliveryType?.deliveryPriceFaster
  );
  return (
    <div>
      {props?.data?.adminDeliveryType?.priority && (
        <HeaderButton
          text="Prioritario"
          btnColor="black"
          textColor="white"
          price={priorityPrice === 0 ? "Gratis" : `+₡${priorityPrice}`}
          deliverTime={`En ${props?.data?.adminDeliveryType?.deliveryTimePriority} hora`}
          widthMax={props.widthMax}
          MobileUi={props.MobileUi}
          activeTab={props.activeTab}
          setActiveTabs={props.setActiveTabs}
          setDeliverDisable={props.setDeliverDisable}
          setActiveTabData={() =>
            props.setActiveTabData({
              deliverTime: props?.data?.adminDeliveryType?.deliveryTimePriority,
              price: props?.data?.adminDeliveryType?.deliveryPricePriority,
            })
          }
          icon={<BoltIcon />}
        />
      )}
      {props?.data?.adminDeliveryType?.standard && (
        <HeaderButton
          text="Estándar"
          btnColor="white"
          MobileUi={props.MobileUi}
          textColor="black"
          price={standardPrice === 0 ? "Gratis" : `+₡${standardPrice}`}
          deliverTime={`En ${props?.data?.adminDeliveryType?.deliveryTimeStandard} hora`}
          activeTab={props.activeTab}
          widthMax={props.widthMax}
          setActiveTabs={props.setActiveTabs}
          setDeliverDisable={props.setDeliverDisable}
          setActiveTabData={(e) =>
            props.setActiveTabData({
              deliverTime: props?.data?.adminDeliveryType?.deliveryTimeStandard,
              price: props?.data?.adminDeliveryType?.deliveryPriceStandard,
            })
          }
          icon={<DirectionsCarIcon />}
        />
      )}
      {props?.data?.adminDeliveryType?.faster && (
        <HeaderButton
          text="Rápido"
          btnColor="white"
          MobileUi={props.MobileUi}
          textColor="black"
          price={fasterPrice === 0 ? "Gratis" : `+₡${fasterPrice}`}
          deliverTime={`En ${props?.data?.adminDeliveryType?.deliveryTimeFaster} hora`}
          widthMax={props.widthMax}
          activeTab={props.activeTab}
          setActiveTabs={props.setActiveTabs}
          setDeliverDisable={props.setDeliverDisable}
          setActiveTabData={(e) =>
            props.setActiveTabData({
              deliverTime: props?.data?.adminDeliveryType?.deliveryTimeFaster,
              price: props?.data?.adminDeliveryType?.deliveryPriceFaster,
            })
          }
          icon={<BoltIcon />}
        />
      )}
    </div>
  );
};

export default UserDeliveryTab;

const HeaderButton = (props) => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState();
  const handleChange = (props) => {
    // console.log(props, "propsprops");
    props.setActiveTabs(props.text);
    props.setActiveTabData(props);
    props.setDeliverDisable(false);
  };
  return (
    <>
      <div
        onClick={() => handleChange(props)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: props.widthMax || props.MobileUi ? "100%" : "82.5%",
          backgroundColor:
            props.activeTab === props.text ? "lightgray" : "white",
          alignItems: "center",
          padding: 8,
          borderRadius: 5,
          borderWidth: props.widthMax ? 0 : 1,
          cursor: "pointer",
          marginBottom: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {props.icon}
          <div>
            <div
              style={{
                color: "#469617",
                fontWeight: "600",
                marginBottom: -5,
              }}
            >
              {props.deliverTime}
            </div>
            <div style={{ fontSize: 14 }}>{props.text}</div>
          </div>
        </div>
        <div>{props.price}</div>
      </div>
    </>
  );
};
