import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import React, { useEffect, useRef, useState } from "react";
import StoreFrontNavbar from "../../components/StoreFrontNavbar";
import Avatar from "@mui/material/Avatar";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Products from "../../components/Products";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Modal from "@mui/material/Modal";
import { useLocation, useParams } from "react-router-dom";
import List from "@mui/material/List";
import "./mini.css";
import SubCategoriesComponent from "../../components/SubCategoriesComponent";
import { makeStyles, useTheme } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import useCategoty from "../../hooks/useCategory";
import UserDeliveryTab from "../../user/UserDeliveryTab";

import { Suspense } from "react";
import { store } from "../../features/slices/storeSlice";
import { storeProduct } from "../../features/slices/VendorProducts";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import AllProducts from "../../components/AllProducts";
import loadingIcon from "../../assets/Basimart_Loader.gif";
import AllCategoryComponent from "../../components/AllCategoryComponent";
const drawerWidth = 240;

const useStyles = makeStyles(() => {
  return {
    drawerMargin: {
      marginTop: (theme) => theme.spacing(10),
    },
  };
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  borderRadius: 2,
};
function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  return isMounted;
}
const MiniStoreFront = (props) => {
  const params = useLocation();
  let { storeName } = useParams();
  console.log(storeName, "storeNamesssd");

  const theme = useTheme();
  const classes = useStyles(theme);
  const { window } = props;
  console.log(window);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigation = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [selectedAisle, setSelectedAisle] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  console.log(selectedAisle, "selectedAisle");
  // const [product, setProduct] = useState([]);
  // const [activeTab, setActiveTabs] = useState("");
  const [openM, setOpenM] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpenM(true);
  const handleClose = () => setOpenM(false);

  const modalOpen = () => setOpen(true);
  const modalClose = () => setOpen(false);
  const [openList, setOpenList] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const ref = useRef()
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const { data, isLoading, isSuccess } = useSelector(
    (state) => state.store.data
  );
  const isMountedRef = useIsMounted();
  const [aisle1, setAisle1] = useState("");
  const [category, setCategory] = useState("");
  const [searchProducts, setSearchProducts] = useState();
  const [searchLoading, setSearchLoading] = useState(false);
  const [subCategory, setSubCategory] = useState("");
  const [categoryForPro, setCategoryForPro] = useState();
  const [selectSub, setSelectSub] = useState();

  const { data: pdrct } = useSelector((state) => state.storeProduct.data);
  const { isLoading: lo } = useSelector((state) => state.storeProduct);
  console.log(lo, pdrct, isLoading, "now its is something");
  const cete = data;
  console.log(cete, isSuccess, isLoading, "ministore data");
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    // console.log(event, "event");
    setMobileMoreAnchorEl(event.currentTarget);
  };
  // const [cat, setCat] = useState();
  const [loading, setLoading] = useState(false);
  const [storeData, setStoreData] = useState();
  const [textValue, setTextValue] = React.useState();

  console.log(storeData, "storeData");

  const handleSearch = async (text) => {
    console.log(text.target.value);
    setTextValue(text.target.value);
  };

  const onSubmit = async () => {
    console.log(textValue, "value");
    setSearchLoading(true);
    axios
      .post("/api/v1/vendor/checkStoreName", { storeName: storeName })
      .then(async (res) => {
        console.log(res);

        axios
          .post("/api/v1/product/storeSearch", {
            vendor: res.data.store,
            text: textValue,
          })
          .then((res) => {
            console.log(res.data);
            setSearchProducts(res.data.product);
            setSearchLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };
  const aisle = useCategoty();
  let { catgry } = aisle;

  const ReduxData = async () => {
    await dispatch(store({ data }));
  };
  useEffect(() => {
    if (isMountedRef.current) {
      axios
        .post("/api/v1/vendor/checkStoreName", { storeName: storeName })
        .then((res) => {
          setStoreData({ item: res.data.store });
        });
    }
  }, [isMountedRef]);
  useEffect(() => {
    if (isMountedRef.current) {
      ReduxData();
    }
  }, [isMountedRef]);
  const ReduxProductData = async () => {
    await dispatch(
      storeProduct({
        data: {
          vendorProductId: storeName,
          aisle: aisle1,
          category: categoryForPro?.label ? categoryForPro.label : "",
          subCategory: subCategory,
        },
      })
    );

    // await dispatch(store(vendorproductparamsId));
  };
  const ReduxProductData1 = async () => {
    setAisle1("");
    setCategory("");
    setSelectedCategory("");
    setSelectedAisle("");
    setCategoryForPro("");
    setTextValue("");
    setSearchProducts();

    await dispatch(
      storeProduct({
        data: {
          vendorProductId: storeName,
          aisle: "",
          category: "",
          subCategory: "",
        },
      })
    );

    // await dispatch(store(vendorproductparamsId));
  };
  let skip = 0;
  const fetchMore = async () => {
    const response = await axios.post("/api/v1/products/getPaginatedProducts", {
      data: {
        vendorProductId: storeName,
        aisle: aisle1,
        category: categoryForPro?.label ? categoryForPro.label : "",
        subCategory: subCategory,
        skip: skip + 5,
      },
    });
    let newOne = [].concat(pdrct.newProduct, response.data.newProduct);
    console.log(newOne, "ye hain");
    pdrct.newProduct = newOne;
  };

  useEffect(() => {
    console.log("aisle in useEffect");
    ReduxProductData();
  }, [aisle1, categoryForPro, subCategory]);

  const handleClick = () => {
    setOpenList(!openList);
  };

  const drawer = (catgry) => (
    <div>
      <div>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: 10,
              paddingBottom: 10,
              marginLeft: 10,
            }}
            onClick={() => navigation("/")}
          >
            <ArrowBackIosIcon
              style={{
                fontSize: 16,
                paddingTop: 1,
                cursor: "pointer",
              }}
            />
            <div
              style={{
                fontWeight: "600",
                cursor: "pointer",
              }}
            >
              Ver Tiendas
            </div>
          </div>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <div
            style={{
              width: "100%",
              height: 1,
              backgroundColor: "lightgray",
            }}
          />
        </Box>

        {storeData && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 20,
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {storeData?.item?.image ? (
                  <img
                    style={{
                      width: 85,
                      height: 85,
                      marginTop: 10,
                      borderRadius: "50%",
                      borderWidth: 1,
                      borderColor: "lightgray",
                    }}
                    src={storeData?.item?.image}
                  />
                ) : (
                  <Avatar
                    style={{
                      width: 70,
                      height: 70,
                      marginTop: 10,
                    }}
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <spam
                  style={{ fontWeight: "600", fontSize: 16, marginBottom: 5 }}
                >
                  {storeData?.item?.storeName}
                </spam>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <spam
                  onClick={handleOpen}
                  style={{
                    fontWeight: "600",
                    color: "gray",
                    fontSize: 12,
                    cursor: "pointer",
                    marginBottom: 5,
                  }}
                >
                  Tiempos de entrega
                  <ArrowForwardIosIcon style={{ fontSize: 12 }} />
                </spam>
                <Modal
                  open={openM}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div style={{}}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 10,
                        }}
                      >
                        <div
                          style={{
                            fontSize: 20,
                            fontWeight: "600",
                            color: "#53B02C",
                            paddingBottom: 10,
                          }}
                        >
                          Tiempos de entrega
                        </div>

                        <div
                          onClick={handleClose}
                          style={{ cursor: "pointer" }}
                        >
                          <CloseIcon />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={storeData?.item?.image}
                          style={{ width: 80, height: 80, borderRadius: "50%" }}
                        />
                        <div>{storeData?.item?.storeName}</div>
                      </div>
                      <div style={{}}>
                        <UserDeliveryTab
                          data={storeData?.item}
                          widthMax={true}
                        />
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <spam
                  onClick={modalOpen}
                  style={{
                    fontWeight: "700",
                    color: "rgb(43, 120, 198)",
                    fontSize: 12,
                    cursor: "pointer",
                  }}
                >
                  100% garantía de satisfacción
                  <ArrowForwardIosIcon style={{ fontSize: 12 }} />
                </spam>
              </div>
              <Modal
                open={open}
                onClose={modalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div>
                    <div onClick={modalClose} style={{ cursor: "pointer" }}>
                      <CloseIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        style={{ width: 80, height: 80, padding: 10 }}
                        src={require("../../assets/guaranteeIcon.png")}
                      />
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: 18,
                        }}
                      >
                        100% garantía de satisfacción
                      </div>
                    </div>
                    <div style={{ padding: 10, color: "#343538" }}>
                      Haz tu pedido con tranquilidad. Si experimentas alguno de
                      los siguientes problemas, eres elegible para un reembolso
                      o crédito:
                    </div>
                  </div>
                  <div style={{ padding: 20 }}>
                    <ol style={{ listStyleType: "inherit" }}>
                      <li style={{ marginLeft: 20, color: "#343538" }}>
                        Daño o producto faltante
                      </li>
                      <li style={{ marginLeft: 20, color: "#343538" }}>
                        Reemplazos deficientes de artículos
                      </li>
                      <li style={{ marginLeft: 20, color: "#343538" }}>
                        Llegada tardía
                      </li>
                      <li style={{ marginLeft: 20, color: "#343538" }}>
                        Servicio no profesional
                      </li>
                    </ol>
                  </div>
                  <div
                    onClick={modalClose}
                    style={{
                      backgroundColor: "#63CE46",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 15,
                      margin: 10,
                      borderRadius: 10,
                      cursor: "pointer",
                    }}
                  >
                    Entendido{" "}
                  </div>
                </Box>
              </Modal>
            </div>
            <div
              style={{
                width: "100%",
                height: 1,
                backgroundColor: "lightgray",
              }}
            />
          </div>
        )}
        <div
          style={{
            fontWeight: "700",
            backgroundColor:
              selectedAisle || selectedCategory ? "white" : "#343538",
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 10,
            margin: 5,
            borderRadius: 8,
            cursor: "pointer",
            marginBottom: 10,
            marginTop: -10,
          }}
          onClick={ReduxProductData1}
        >
          <div
            style={{
              color: selectedAisle || selectedCategory ? "#343538" : "white",
            }}
          >
            <ShoppingBagIcon style={{ fontSize: 22, marginTop: -5 }} /> Tienda
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: 1,
          backgroundColor: "lightgray",
        }}
      />
      <div
        className="scrollbar-hide"
        id="slider"
        style={{
          width: "100%",
          display: "flex",
          overflowY: "scroll",
          scrollBehavior: "smooth",
          whiteSpace: "nowrap",
          flexDirection: "column",
          height: "55vh",
          paddingBottom: 40,
        }}
      >
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Suspense fallback={<h1>Loading profile...</h1>}>
            {cete?.d.map((item) => (
              <SubCategoriesComponent
                getAisle={(val) => {
                  setAisle1(val);
                  setCategory("");
                  setSearchProducts(null);
                }}
                getCategory={(val) => {
                  setCategory(val);
                  setAisle1("");
                  setCategoryForPro(val);
                  setSearchProducts(null);
                }}
                setSelectedAisle={setSelectedAisle}
                selectedAisle={selectedAisle}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                item={item}
                handleClick={handleClick}
                openList={openList}
              />
            ))}
          </Suspense>
        </List>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div style={{ backgroundColor: "white" }}>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <StoreFrontNavbar
          textValue={textValue}
          handleSearch={handleSearch}
          onSubmit={onSubmit}
          cat={catgry}
          storeData={storeData}
          anchorEl={anchorEl}
          mobileMoreAnchorEl={mobileMoreAnchorEl}
          handleMenuClose={handleMenuClose}
          handleMobileMenuOpen={handleMobileMenuOpen}
          handleMobileMenuClose={handleMobileMenuClose}
          handleDrawerToggle={handleDrawerToggle}
          product={pdrct}
        />
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <StoreFrontNavbar
          textValue={textValue}
          handleSearch={handleSearch}
          onSubmit={onSubmit}
          cat={catgry}
          storeData={storeData}
          anchorEl={anchorEl}
          mobileMoreAnchorEl={mobileMoreAnchorEl}
          handleMenuClose={handleMenuClose}
          handleMobileMenuOpen={handleMobileMenuOpen}
          handleMobileMenuClose={handleMobileMenuClose}
          handleDrawerToggle={handleDrawerToggle}
          product={pdrct}
          onMobileUi
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          component="nav"
          // sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          {cete && storeData && (
            <Drawer
              BackdropProps={{ invisible: true }}
              PaperProps={{
                sx: { height: "100%" },
              }}
              classes={classes.drawerMargin}
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                marginTop: 90,
                position: "absolute",
                top: 90,
                display: { xs: "block", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer(cete)}
            </Drawer>
          )}
          {cete && storeData && (
            <Drawer
              BackdropProps={{ invisible: true }}
              variant={"persistent"}
              PaperProps={{
                sx: { height: "100%", marginTop: 10 },
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              classes={classes.drawerMargin}
              className="mainDrawer"
              // variant="permanent"
              sx={{
                position: "fixed",
                // top: 100,
                marginTop: 90,
                overflowY: "hidden",
                width: 240,
                display: { xs: "none", sm: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              {drawer(catgry)}
            </Drawer>
          )}
        </Box>

        {lo ? (
          <>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  width: "100vw",
                  height: "100vh",
                  alignContent: "center",
                  justifyItems: "center",
                  marginLeft: 240,
                  paddingTop: 20,
                  justifySelf: "center",
                }}
              >
                <img src={loadingIcon} size={10} height={500} width={350} />
              </div>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  width: "100vw",
                  height: "50vh",
                  alignContent: "center",
                  justifyItems: "center",
                  justifySelf: "center",
                }}
              >
                <img src={loadingIcon} size={10} height={500} width={350} />
              </div>
            </Box>
          </>
        ) : (
          <Grid container spacing={0} style={{ marginTop: 50 }}>
            <Grid
              style={{ marginLeft: "auto" }}
              item
              md={10}
              lg={10}
              sm={12}
              xs={12}
            >
              <Box sx={{ display: { xs: "none", md: "flex" }, marginTop: 5 }}>
                {!searchProducts && (
                  <Suspense fallback={<h1>Loading profile...</h1>}>
                    {pdrct?.newProduct && pdrct.newProduct.length != 0 ? (
                      aisle1 || (category && aisle1 != "") || category != "" ? (
                        <Grid style={{}}>
                          {pdrct.newProduct.length !== 0 && (
                            <AllCategoryComponent
                              fetchMore={fetchMore}
                              productData1={pdrct?.newProduct}
                              storeData={storeData}
                              selectedAisle={selectedAisle}
                              setSelectSub={setSelectSub}
                              selectSub={selectSub}
                              selectedCategory={category}
                              handleSubCategory={(e) => {
                                setSubCategory(e);
                                setCategoryForPro("");
                                setAisle1("");
                                setSearchProducts(null);
                              }}
                            />
                          )}
                        </Grid>
                      ) : (
                        <Grid style={{ height: "100%" }}>
                          {pdrct.newProduct.map((doc) => (
                            <Products data={doc} storeData={storeData} />
                          ))}
                        </Grid>
                      )
                    ) : (
                      !lo &&
                      pdrct?.newProduct.length === 0 && (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              color: "#63CE46",
                              fontSize: 25,
                              fontWeight: "600",
                            }}
                          >
                            Oops! No hay productos disponible para el
                            Pasillo/Categoría seleccionado
                          </div>
                        </div>
                      )
                    )}
                  </Suspense>
                )}
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                {!searchProducts && (
                  <Suspense fallback={<h1>Loading profile...</h1>}>
                    {pdrct?.newProduct && pdrct.newProduct.length != 0 ? (
                      aisle1 || (category && aisle1 != "") || category != "" ? (
                        <Grid style={{}}>
                          {pdrct.newProduct.length !== 0 && (
                            <AllCategoryComponent
                              fetchMore={fetchMore}
                              productData1={pdrct?.newProduct}
                              storeData={storeData}
                              selectedAisle={selectedAisle}
                              selectedCategory={category}
                              setSelectSub={setSelectSub}
                              selectSub={selectSub}
                              handleSubCategory={(e) => {
                                setSubCategory(e);
                                setCategoryForPro("");
                                setAisle1("");
                              }}
                            />
                          )}
                        </Grid>
                      ) : (
                        <Grid style={{ height: "100%" }}>
                          {pdrct.newProduct.map((doc) => (
                            <Products
                              MobileUi
                              data={doc}
                              storeData={storeData}
                            />
                          ))}
                        </Grid>
                      )
                    ) : (
                      !lo &&
                      pdrct?.newProduct.length === 0 && (
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              color: "#63CE46",
                              fontSize: 25,
                              fontWeight: "600",
                            }}
                          >
                            Ops! No product available for the selected
                            Asile/Cetegory
                          </div>
                        </div>
                      )
                    )}
                  </Suspense>
                )}
              </Box>
              {searchProducts && (
                <Grid>
                  <AllProducts
                    storeData={storeData}
                    productData={searchProducts}
                    productSerach={textValue}
                    setSelectSub={setSelectSub}
                    selectSub={selectSub}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
};

export default MiniStoreFront;
