import React, { useState, useEffect } from "react";

import NavbarComponent from "../components/NavbarComponent";
import { Grid } from "@mui/material";
import axios from "../axios";

import UserOrderTable from "./UserOrderTable";
import AuthContext from "../components/context";
import io from "socket.io-client";
import { useContext } from "react";
function UserAllOrders() {
  const [activeTab, setActiveTabs] = useState("Yours Orders");
  const [open, setOpen] = React.useState(false);
  const [buyer, setBuyer] = React.useState([]);
  const [loading, setLoading] = React.useState();
  console.log(buyer, "buyer");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const auth = useContext(AuthContext);
  console.log(auth.user, "buyerId");
  const id = auth?.user?._id;
  const getBuyerOrders = async () => {
    console.log("into that function");
    setLoading(true);
    console.log();
    axios
      .post("/api/v1/allOrdersDetail/buyer", {
        buyerId: id,
      })
      .then((res) => {
        setBuyer(res.data);
        console.log(res.data);
      });
    setLoading(false);
  };
  useEffect(() => {
    console.log("into that function");
    const socket = io(
      "https://orca-app-2hy8c.ondigitalocean.app/api/socket",
      {
        transports: ["websocket"],
      }
    ).connect();
    socket.on("newThought", (thought) => {
      console.log(thought, "hello");
      return getBuyerOrders();
    });
    getBuyerOrders();
  }, []);

  return (
    <div>
      <NavbarComponent />
      <Grid
        style={{
          marginTop: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        container
        spacing={0}
        marginTop={2}
        // style={{ paddingRight: 10, paddingLeft: 10 }}
      >
        {loading ? (
          <div>loading</div>
        ) : (
          <Grid item md={10} lg={10} sm={12} xs={12}>
            <UserOrderTable buyer={buyer} />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default UserAllOrders;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
