import { Grid } from "@mui/material";
import React from "react";
import Image from "./ImageComponent";
import "./Navbar.css";
import ProductDetailModal from "./ProductDetailModal";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useState } from "react";

const AllProducts = ({
  productData,
  storeData,
  selectedAisle,
  selectedCategory,
  handleSubCategory,
  productSerach,
  setSelectSub,
  selectSub,
}) => {
  console.log(selectedCategory, "selectedAisle1");
  const [modalItem, setModalItem] = React.useState();
  const [open, setOpen] = React.useState(false);
  const setModalOpen = (value) => {
    // console.log(value)
    setModalItem(value);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleClick = (i) => {
    setSelectSub(i);
    handleSubCategory(i);
    console.log(i, "hellllll");
  };
  return (
    <>
      {selectedAisle && (
        <div
          style={{
            marginLeft: 30,
            fontSize: 20,
            fontWeight: "600",
            marginTop: 40,
            marginBottom: 10,
          }}
        >
          {selectedAisle}
        </div>
      )}
      {selectedCategory && (
        <div
          style={{
            marginLeft: 30,
            fontSize: 20,
            fontWeight: "600",
            marginTop: 40,
            marginBottom: 10,
          }}
        >
          {selectedCategory.label}
        </div>
      )}
      {productSerach && (
        <div
          style={{
            marginLeft: 30,
            fontSize: 20,
            fontWeight: "600",
            marginTop: 40,
            marginBottom: 10,
          }}
        >
          Results for"{productSerach}"
        </div>
      )}
      {selectedCategory?.hasOwnProperty("sub") && (
        <div
          style={{
            marginLeft: 20,
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          {selectedCategory.sub.map((lab) => (
            <div
              onClick={() => handleClick(lab.label)}
              style={{
                marginLeft: 10,
                backgroundColor: "#EFF0F1",
                fontWeight: "600",
                borderColor: selectSub == lab?.label ? "black" : "white",
                borderWidth: selectSub == lab?.label ? 2 : 0,
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 15,
                paddingRight: 15,
                borderRadius: 15,
                color: "#434343",
                cursor: "pointer",
              }}
            >
              {lab.label}
            </div>
          ))}
        </div>
      )}

      <Grid
        style={{
          display: "flex",
        }}
        container
      >
        {productData &&
          productData?.map((product, index) => {
            console.log(product, "productDataItem");

            return (
              <Grid style={{ marginLeft: 30 }} md={2} xs={4.5} item>
                <Image
                  currentItem={product}
                  storeId={storeData?.item?._id}
                  handleOpen={() => setModalOpen(product)}
                />
                {product?.price && (
                  <div style={{ fontWeight: "600" }}>₡{product.price}</div>
                )}
                <div style={Styles.title}>{product.name}</div>
                <div style={Styles.size}>{product.size}</div>
              </Grid>
            );
          })}
        <div>
          {setModalItem && (
            <ProductDetailModal
              // key={currentItem._id}
              storeId={storeData?.item?._id}
              currentItem={modalItem}
              open={open}
              onClose={handleClose}
            />
          )}
        </div>
      </Grid>
    </>
  );
};

export default AllProducts;
const Styles = {
  GridContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  freshProduct: {
    marginLeft: 30,
    fontSize: 20,
    fontWeight: "600",
    marginTop: 40,
  },
  paginationContainer: { display: "flex", alignItems: "center" },
  title: {
    width: 190,
    fontSize: 15,
    color: " rgb(52, 53, 56",
  },
  size: {
    width: 190,
    fontSize: 14,
    color: "#606163",
  },
};
