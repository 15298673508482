import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CreateAccount from "../screens/Authentication/SignUp/Index";
import Login from "../screens/Authentication/SignIn/Index";
import HomeScreen from "../screens/MainScreens/HomeScreen";
import MiniStoreFront from "../screens/MainScreens/MiniStoreFront";
import SearchScreen from "../screens/MainScreens/SearchScreen";
import StoreCategoriesProduct from "./StoreCategoriesProduct";
import UserAllOrders from "../user/UserAllOrders";
import UserCheckOut from "../user/UserCheckOut";
import UserAccountSetting from "../user/UserAccountSetting";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";
import ForgetPassword from "./ForgetPassword";
import UserOrderDetails from "../user/UserOrderDetails";
import LocContext from "./Locationcontext";
import Terms from "./Terms";

export default function AllRoute() {
  const [user, setUser] = React.useState({});
  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });
  const [loc, setLoc] = useState();

  function getUser() {
    const u = localStorage.getItem("location");
    if (u == null || u == "") {
      console.log("no user");
      return;
    }
    const us = JSON.parse(u);
    setLoc(us);
  }
  useEffect(() => {
    getUser();
  }, []);
  // console.log(user, "myUser");
  return (
    <LocContext.Provider value={{ loc, setLoc }}>
      <Router>
        <Routes>
          <Route exact path="/" element={<HomeScreen />} />
          <Route path="/UserAllOrders" element={<UserAllOrders />} />
          <Route path="/Terms" element={<Terms />} />

          <Route path="/UserOrderDetails" element={<UserOrderDetails />} />
          <Route path="/UserAccountSetting" element={<UserAccountSetting />} />

          <Route path="/UserCheckOut" element={<UserCheckOut />} />
          <Route path="/tiendas/:storeName" element={<MiniStoreFront />} />
          <Route path="/SearchScreen" element={<SearchScreen />} />

          <Route
            path="/StoreCategoriesProduct"
            element={<StoreCategoriesProduct />}
          />
          <Route path="/Login" exact element={<Login />} />
          <Route path="/CreateAccount" element={<CreateAccount />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
        </Routes>
      </Router>
    </LocContext.Provider>
  );
}
